<template>
  <div>
    <div class="wallet-header total-2-card">
      <div class="card">
        <div class="heading">{{ pendingLabel }}</div>
        <div class="title">{{ pending_amount }}</div>
      </div>
      <div class="card">
        <div class="heading">{{ successLabel }}</div>
        <div class="title">{{ success_amount }}</div>
      </div>
    </div>
    <div class="card">
      <div class="body table-responsive">
        <table class="table active">
          <thead>
            <tr>
              <th>{{ storeLabel }}</th>
              <th>{{ emailLabel }}</th>
              <th>{{ dateLabel }}</th>
              <th>{{ typeLabel }}</th>
              <th>{{ amountLabel }}</th>
              <th>{{ rateLabel }}</th>
              <th>{{ statusLabel }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(transaction, tIndex) in convertTransactions"
              :key="'convert' + tIndex"
            >
              <td>{{ transaction.store.name }}</td>
              <td>{{ transaction.store.email }}</td>
              <td>
                <span v-if="locale == 'cn'">
                  {{ new Date(transaction.created_at).getFullYear() }}年{{
                    new Date(transaction.created_at).getMonth() + 1
                  }}月{{ new Date(transaction.created_at).getDate() }}日
                  {{ new Date(transaction.created_at).getHours() }}:{{
                    new Date(transaction.created_at).getMinutes() < 10
                      ? `0${new Date(transaction.created_at).getMinutes()}`
                      : new Date(transaction.created_at).getMinutes()
                  }}
                </span>
                <span v-else>
                  {{ new Date(transaction.created_at).getDate() }}
                  {{ months[new Date(transaction.created_at).getMonth()] }},
                  {{ new Date(transaction.created_at).getFullYear() }}
                  {{ new Date(transaction.created_at).getHours() }}:{{
                    new Date(transaction.created_at).getMinutes() < 10
                      ? `0${new Date(transaction.created_at).getMinutes()}`
                      : new Date(transaction.created_at).getMinutes()
                  }}
                </span>
              </td>
              <td>{{ locale == "cn" ? "兑换" : transaction.type }}</td>
              <td>
                {{ Number(transaction.amount_satoshi / 100000000).toFixed(8) }}
                {{ transaction.amount_currency }}
                <span class="convert-label"> >>> </span>
                {{
                  Number(
                    transaction.converted_amount_satoshi / 100000000
                  ).toFixed(9)
                }}
                {{ transaction.converted_amount_currency }}
              </td>
              <td>
                {{ transaction.conversion_rate }}
              </td>
              <td>
                <span v-if="locale == 'en'">{{ transaction.status }}</span>
                <span v-else-if="transaction.status == 'pending'">待批准</span>
                <span v-else-if="transaction.status == 'recevied'">已收到</span>
                <span v-else-if="transaction.status == 'failed'">失败</span>
                <span v-else-if="transaction.status == 'converted'">成功</span>
              </td>
            </tr>
            <tr v-show="convertTransactions.length == 0">
              <td colspan="7">{{ zeroRequestMessage }}</td>
            </tr>
          </tbody>
        </table>
        <nav
          v-if="convertTransactions.length > 0 && convertPagination"
          aria-label="pager"
          class="pagy-bootstrap-nav"
        >
          <ul class="pagination">
            <li v-if="convertPagination.prev" class="page-item prev">
              <a
                @click="convertPage = convertPagination.prev"
                aria-label="previous"
                class="page-link"
              >
                &lt;
              </a>
            </li>
            <li
              v-for="index in convert_pagination_menu"
              :key="index"
              v-show="index != '...'"
              :class="{ active: index == convertPage }"
              class="page-item"
            >
              <a @click="convertPage = index" class="page-link">{{ index }}</a>
            </li>
            <li v-if="convertPagination.next" class="page-item next">
              <a
                @click="convertPage = convertPagination.next"
                rel="next"
                aria-label="next"
                class="page-link"
              >
                &gt;
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "storeLabel",
    "emailLabel",
    "typeLabel",
    "amountLabel",
    "rateLabel",
    "dateLabel",
    "statusLabel",
    "zeroRequestMessage",
    "pendingLabel",
    "successLabel",
    "locale",
  ],
  data() {
    return {
      convertTransactions: [],
      convertPage: 1,
      convertPagination: {},
      convert_pagination_menu: [],
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      pending_amount: 0,
      success_amount: 0,
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.getConversationRequest();
    this.getConversionSummary();
  },
  methods: {
    generatePagination(c, m) {
      var current = c,
        last = m,
        delta = 1,
        left = current - delta,
        right = current + delta + 1,
        range = [],
        rangeWithDots = [],
        l;

      for (let i = 1; i <= last; i++) {
        if (i == 1 || i == last || (i >= left && i < right)) {
          range.push(i);
        }
      }

      for (let i of range) {
        if (l) {
          if (i - l === 2) {
            rangeWithDots.push(l + 1);
          } else if (i - l !== 1) {
            rangeWithDots.push("...");
          }
        }
        rangeWithDots.push(i);
        l = i;
      }

      return rangeWithDots;
    },
    getConversationRequest() {
      let vm = this;
      let api = "/api/v1/admin/conversion_requests?";
      api += "page=" + this.convertPage;
      this.$http
        .get(api)
        .then((response) => {
          vm.convertTransactions = response.data.data;
          console.log(vm.convertTransactions);

          vm.convertPagination = response.data.pagy;
          vm.convert_pagination_menu = vm.generatePagination(
            vm.convertPagination.page,
            vm.convertPagination.pages
          );
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getConversionSummary() {
      let vm = this;
      let api = "/api/v1/admin/conversion_requests/report";
      this.$http
        .get(api)
        .then((response) => {
          vm.pending_amount = response.data.pending_amount
          vm.success_amount = response.data.converted_amount
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
};
</script>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
}
.table {
  display: none;
}
.table.active {
  display: table;
  box-shadow: none;
  width: calc(100% + 2rem);
  margin-left: -1rem;
  border-top: 1px solid #e5e5e5;
}
.convert-label {
  background: #d3d3d3;
  color: #000;
  padding: 0 0.25rem;
  border-radius: 5px;
  display: inline-block;
  margin: 0 10px;
}
</style>