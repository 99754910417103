<template>
  <div class="card admin-table overflow-hidden">
    <div class="overflow md-scrollbar">
      <table>
        <thead>
          <tr>
            <th>{{ nameLabel }}</th>
            <th>{{ rateLabel }}</th>
            <th v-if="type == 'withdraw'">{{ limitLabel }}</th>
            <th>{{ actionLabel }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(rate, index) in rate_list" :key="index">
            <td>
              <div v-if="rate.edit_mode">
                <input
                  type="text"
                  v-model="rate.name"
                />
              </div>
              <div v-else> {{ rate.name }} </div>
            </td>
            <td v-if="type == 'withdraw'">
              <div v-if="rate.edit_mode">
                <input
                  type="number"
                  min="1"
                  max="100"
                  v-model="rate.rate_in_percentage"
                />
              </div>
              <div v-else> {{ rate.rate_in_percentage }} %</div>
            </td>
            <td v-else>
              <div v-if="rate.edit_mode">
                <input
                  type="number"
                  min="1"
                  max="100"
                  step="0.01"
                  v-model="rate.rate"
                />
              </div>
              <div v-else> {{ rate.rate }} </div>
            </td>
            <td v-if="type == 'withdraw'">
              <div v-if="rate.edit_mode">
                <input
                  type="number"
                  min="0"
                  max="100"
                  v-model="rate.daily_limit"
                />
              </div>
              <div v-else> {{ rate.daily_limit }} </div>
            </td>
            <td>
              <a class="btn-hover no-word-break" v-if="!rate.edit_mode" @click="rate.edit_mode = true">{{
                editLabel
              }}</a>
              <a 
                v-if="rate.edit_mode"
                class="btn btn-primary no-word-break"
                @click="updateRate(index)"
              >
                {{ saveLabel }}
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "token",
    "type",
    "nameLabel",
    "rateLabel",
    "limitLabel",
    "actionLabel",
    "editLabel",
    "saveLabel",
    "updatedMessage",
    "somethingWrongMessaeg",
    "errorMessage",
    "locale",
  ],
  data() {
    return {
      rate_list: [],
    };
  },
  mounted() {
    this.getRates();
  },
  methods: {
    getRates() {
      let vm = this;
      let api = "/api/v1/admin/settings";
      this.$http
        .get(api)
        .then((response) => {
          if(vm.type == 'withdraw') {
            vm.rate_list.push(response.data.setting.ppx_to_platform_wallet)
            vm.rate_list[0].key = 'ppx_to_platform_wallet'
            vm.rate_list.push(response.data.setting.amyr_to_platform_wallet)
            vm.rate_list[1].key = 'amyr_to_platform_wallet'
            vm.rate_list.push(response.data.setting.amyr_monthly_withdrawal)
            vm.rate_list[2].key = 'amyr_monthly_withdrawal'
            vm.rate_list.push(response.data.setting.amyr_weekly_withdrawal)
            vm.rate_list[3].key = 'amyr_weekly_withdrawal'
            vm.rate_list.push(response.data.setting.amyr_one_time_withdrawal)
            vm.rate_list[4].key = 'amyr_one_time_withdrawal'
          }
          else {
            vm.rate_list.push(response.data.setting.ppx_to_amyr_conversion_rate)
            vm.rate_list[0].key = 'ppx_to_amyr_conversion_rate'
            vm.rate_list.push(response.data.setting.amyr_to_myr_conversion_rate)
            vm.rate_list[1].key = 'amyr_to_myr_conversion_rate'
          }
          vm.rate_list = vm.rate_list.map((rate)=>{
            return {
              ...rate,
              ...{
                edit_mode: false,
              }
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateRate(index){
      let vm = this
      var rate = this.rate_list[index]
      if (this.type == 'withdraw') {
        rate.daily_limit = rate.daily_limit == "" ? 0 : parseFloat(rate.daily_limit)
      }
      var setting = {}
      if(rate.rate_in_percentage) {
        rate.rate_in_percentage = parseFloat(rate.rate_in_percentage)
      }

      if(rate.rate) {
        rate.rate = parseFloat(rate.rate)
      }
      setting[rate.key] = rate
      if(rate.name != "" && rate.rate_in_percentage != "") {
        this.$http
        .put("/api/v1/admin/settings/", {
          authenticity_token: this.token,
          setting
        })
        .then((response) => {
          console.log(response)
          vm.$toast.open({
            message: vm.updatedMessage,
            type: "success",
            position: "top-right",
          });
        })
        .catch((error) => {
          vm.$toast.open({
            message: vm.somethingWrongMessaeg,
            type: "error",
            position: "top-right",
          });
          console.log(error);
        })
        .finally(()=>{
          this.rate_list[index].edit_mode = false;
        });
      }
      else {
        vm.$toast.open({
          message: vm.locale == 'cn' ? "请填写所有资料以执行更新" : "Please fill in all the field to perform update",
          type: "error",
          position: "top-right",
        });
        console.log(error);
      }
    }
  },
};
</script>