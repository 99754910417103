<template>
  <div class="btn btn-primary btn-large btn-full" @click="approve">{{ approveLabel }}</div>
</template>

<script>
export default {
  props: [
    "token",
    "storeId",
    "approveLabel",
    "approvedSuccessful",
    "somethingWrongMessaeg"
  ],
  methods: {
    approve() {
      let vm = this;
      this.$http
        .put(`/api/v1/admin/stores/${this.storeId}/approve`, {
          authenticity_token: this.token,
        })
        .then(() => {
          vm.$toast.open({
            message: vm.approvedSuccessful,
            type: "success",
            position: "top-right",
          });
          setTimeout(function(){ window.location.reload(); }, 1000);
        })
        .catch((error) => {
          vm.$toast.open({
            message: vm.somethingWrongMessaeg,
            type: "error",
            position: "top-right",
          });
          console.log(error);
        });
    },
  },
};
</script>