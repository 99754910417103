<template>
  <div class="card admin-table overflow-hidden">
    <div
      id="modal-reject"
      :class="`modalDialog small ${showModal ? 'active' : ''}`"
    >
      <div>
        <a class="modal-toggle close" @click="showModal = false">X</a>
        <div style="padding-top: 1rem">
          <h2 class="primary-title" style="color: #000">
            {{ rejectRequestLabel }}
          </h2>
          <p>{{ rejectPromptLabel }}</p>
          <textarea v-model="reject_message" class="form-control"></textarea>
          <p class="text-danger" v-if="rejectMessageRequired" style="margin: 0">
            {{ requiredError }}
          </p>
          <div class="modal-control" style="margin-top: 2rem">
            <a
              class="btn btn-primary-outline"
              @click="
                showModal = false;
                rejectMessageRequired = false;
                reject_message = '';
              "
              >{{ discardLabel }}</a
            >
            <a class="modal-toggle btn btn-primary" @click="reject">{{
              yesLabel
            }}</a>
          </div>
        </div>
      </div>
    </div>
    <form @submit.prevent="search" class="table-header">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="25pt"
        version="1.1"
        viewBox="-1 0 136 136.21852"
        width="25pt"
      >
        <g id="surface1">
          <path
            d="M 93.148438 80.832031 C 109.5 57.742188 104.03125 25.769531 80.941406 9.421875 C 57.851562 -6.925781 25.878906 -1.460938 9.53125 21.632812 C -6.816406 44.722656 -1.351562 76.691406 21.742188 93.039062 C 38.222656 104.707031 60.011719 105.605469 77.394531 95.339844 L 115.164062 132.882812 C 119.242188 137.175781 126.027344 137.347656 130.320312 133.269531 C 134.613281 129.195312 134.785156 122.410156 130.710938 118.117188 C 130.582031 117.980469 130.457031 117.855469 130.320312 117.726562 Z M 51.308594 84.332031 C 33.0625 84.335938 18.269531 69.554688 18.257812 51.308594 C 18.253906 33.0625 33.035156 18.269531 51.285156 18.261719 C 69.507812 18.253906 84.292969 33.011719 84.328125 51.234375 C 84.359375 69.484375 69.585938 84.300781 51.332031 84.332031 C 51.324219 84.332031 51.320312 84.332031 51.308594 84.332031 Z M 51.308594 84.332031 "
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
          />
        </g>
      </svg>
      <input
        type="text"
        :placeholder="searchStoreLabel"
        name="query"
        v-model="query"
      />
      <input type="submit" :value="searchLabel" class="btn btn-primary" />
    </form>
    <div class="overflow md-scrollbar">
      <table>
        <thead>
          <tr>
            <th>{{ storeLabel }}</th>
            <th>{{ emailLabel }}</th>
            <th>{{ statusLabel }}</th>
            <th>{{ requestedAtLabel }}</th>
            <th>{{ detailOfRequestLabel }}</th>
            <th>{{ actionLabel }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(store, index) in store_list" :key="index">
            <td>
              <a :href="'/' + locale + '/admin/stores/' + store.id">
                {{ store.name }}
              </a>
            </td>
            <td>
              {{ getBossEmail(store) }}
            </td>
            <td>
              {{ pendingApproveLabel }}
            </td>
            <td>
              <span v-if="locale == 'cn'">
                {{ new Date(store.updated_at).getFullYear() }}年{{
                  new Date(store.updated_at).getMonth() + 1
                }}月{{ new Date(store.updated_at).getDate() }}日
                {{ new Date(store.updated_at).getHours() }}:{{
                  new Date(store.updated_at).getMinutes()
                }}
              </span>
              <span v-else>
                {{ new Date(store.updated_at).getDate() }}
                {{ months[new Date(store.updated_at).getMonth()] }},
                {{ new Date(store.updated_at).getFullYear() }}
                {{ new Date(store.updated_at).getHours() }}:{{
                  new Date(store.updated_at).getMinutes()
                }}
              </span>
            </td>
            <td>
              <a
                :href="`/${locale}/admin/stores/${store.id}?page=verification-document`"
                class="btn-hover no-word-break"
                >{{ viewLabel }}</a
              >
            </td>
            <td>
              <div
                v-if="store.store_info_status == 'rejected'"
                class="text-danger"
              >
                {{ rejectedLabel }}
              </div>
              <div
                v-else-if="store.store_info_status == 'approved'"
                class="text-success"
              >
                {{ approvedLabel }}
              </div>
              <div v-else>
                <a
                  class="btn btn-primary no-word-break"
                  @click="approve(index)"
                  >{{ approveLabel }}</a
                >
                <a
                  @click="rejectPopup(index)"
                  class="btn-hover no-word-break modal-toggle"
                >
                  {{ rejectLabel }}
                </a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <nav
      v-if="store_list.length > 0 && pagination"
      aria-label="pager"
      class="pagy-bootstrap-nav"
    >
      <ul class="pagination">
        <li v-if="pagination.prev" class="page-item prev">
          <a
            @click="page = pagination.prev"
            aria-label="previous"
            class="page-link"
          >
            &lt;
          </a>
        </li>
        <li
          v-for="index in pagination_menu"
          :key="index"
          v-show="index != '...'"
          :class="{ active: index == page }"
          class="page-item"
        >
          <a @click="page = index" class="page-link">{{ index }}</a>
        </li>
        <li v-if="pagination.next" class="page-item next">
          <a
            @click="page = pagination.next"
            rel="next"
            aria-label="next"
            class="page-link"
          >
            &gt;
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  props: [
    "token",
    "searchStoreLabel",
    "searchLabel",
    "storeLabel",
    "emailLabel",
    "statusLabel",
    "requestedAtLabel",
    "detailOfRequestLabel",
    "actionLabel",
    "pendingApproveLabel",
    "viewLabel",
    "approveLabel",
    "rejectLabel",
    "storeUpdatedMessage",
    "somethingWrongMessaeg",
    "rejectRequestLabel",
    "rejectPromptLabel",
    "rejectedSuccessful",
    "approvedSuccessful",
    "discardLabel",
    "yesLabel",
    "rejectedLabel",
    "approvedLabel",
    "locale",
    "requiredError",
  ],
  data() {
    return {
      store_list: [],
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      query: null,
      pagination: {},
      pagination_menu: [],
      page: 1,
      selectedStore: 0,
      showModal: false,
      reject_message: "",
      rejectMessageRequired: false,
    };
  },
  computed: {},
  watch: {
    page: function (p) {
      this.getStore();
    },
  },
  mounted() {
    this.getStore();
  },
  methods: {
    search() {
      this.getStore();
    },
    getStore() {
      let vm = this;
      let api = "/api/v1/admin/stores?store_info_status=submitted";
      api += this.query ? "&query=" + this.query : "";
      api += this.page ? "&page=" + this.page : "";
      this.$http
        .get(api)
        .then((response) => {
          vm.store_list = [];
          let store_list = response.data.data.map((store) => {
            return {
              ...store,
              ...{
                edit_mode: false,
              },
            };
          });
          vm.pagination = response.data.pagy;
          vm.pagination_menu = vm.generatePagination(
            vm.pagination?.page,
            vm.pagination.pages
          );
          store_list.forEach(function (store) {
            if (!store.commission_rate) {
              store.commission_rate = 0;
            } else {
              store.commission_rate = store.commission_rate * 100;
            }

            if (!store.branch_limit) {
              store.branch_limit = 0;
            } else {
              store.branch_limit = store.branch_limit;
            }

            vm.store_list.push(store);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    generatePagination(c, m) {
      var current = c,
        last = m,
        delta = 1,
        left = current - delta,
        right = current + delta + 1,
        range = [],
        rangeWithDots = [],
        l;

      for (let i = 1; i <= last; i++) {
        if (i == 1 || i == last || (i >= left && i < right)) {
          range.push(i);
        }
      }

      for (let i of range) {
        if (l) {
          if (i - l === 2) {
            rangeWithDots.push(l + 1);
          } else if (i - l !== 1) {
            rangeWithDots.push("...");
          }
        }
        rangeWithDots.push(i);
        l = i;
      }

      return rangeWithDots;
    },
    getBossEmail(store) {
      let email = "-";
      if (store.merchants) {
        store.merchants.forEach(function (merchant) {
          if (merchant.role == "BOSS") {
            email = merchant.email;
          }
        });
      }
      return email;
    },
    rejectPopup(index) {
      this.selectedStore = index;
      this, (this.showModal = true);
    },
    reject() {
      let id = this.store_list[this.selectedStore].id;
      let vm = this;
      if (this.reject_message != "") {
        this.rejectMessageRequired = false;
        this.$http
          .put(`/api/v1/admin/stores/${id}/reject`, {
            authenticity_token: this.token,
            store: {
              reject_message: vm.reject_message,
            },
          })
          .then(() => {
            vm.store_list[this.selectedStore].store_info_status = "rejected";
            vm.showModal = false;
            vm.reject_message = "";
            vm.rejectMessageRequired = false
            vm.$toast.open({
              message: vm.rejectedSuccessful,
              type: "success",
              position: "top-right",
            });
          })
          .catch((error) => {
            vm.$toast.open({
              message: vm.somethingWrongMessaeg,
              type: "error",
              position: "top-right",
            });
            console.log(error);
          });
      } else {
        this.rejectMessageRequired = true;
      }
    },
    approve(index) {
      let id = this.store_list[index].id;
      let vm = this;
      this.$http
        .put(`/api/v1/admin/stores/${id}/approve`, {
          authenticity_token: this.token,
        })
        .then(() => {
          vm.store_list[index].store_info_status = "approved";
          vm.showModal = false;
          vm.$toast.open({
            message: vm.approvedSuccessful,
            type: "success",
            position: "top-right",
          });
        })
        .catch((error) => {
          vm.$toast.open({
            message: vm.somethingWrongMessaeg,
            type: "error",
            position: "top-right",
          });
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped >
td {
  min-width: 115px;
}
.no-word-break {
  word-break: keep-all;
}
.btn-hover {
  background: unset !important;
  text-decoration: none !important;
  padding: 0.5rem;
  border-radius: 5px;
  &:hover {
    background: rgba(254, 198, 24, 0.2) !important;
  }
}
.modal-control {
  display: flex;
  justify-content: space-between;
}
.modal-control a {
  width: calc(50% - 0.5rem);
}
</style>