<template>
  <form @submit.prevent="onSubmit">
    <div class="form-group">
      <div class="title">{{ currencyLabel }}</div>
      <div class="inline-input">
        <input
          type="text"
          class="form-control"
          v-model="currency"
          @keydown.enter.prevent="addCurrency"
          @input="currency=$event.target.value.toUpperCase()"
        />
        <span class="plus" @click="addCurrency">+</span>
      </div>
      <div class="option-inner">
        <span
          v-for="(added_currency, index) in currencies"
          :key="added_currency"
        >
          {{ added_currency }}
          <span @click="deleteCurrency(index)" class="delete">x</span>
        </span>
      </div>
      <div v-if="currency_error != ''" class="error">
        {{ currency_error }}
      </div>
    </div>
    <input
      type="submit"
      class="btn btn-large btn-block btn-primary"
      :value="saveLabel"
    />
  </form>
</template>

<script>
export default {
  props: [
    "token",
    "settingValue",
    "currencyLabel",
    "saveLabel",
    "saveSuccessMessage",
    "saveFailMessage",
    "locale",
  ],
  data() {
    return {
      currency: '',
      currency_error: '',
      currencies: [],
      isLaoding: false,
    };
  },
  mounted() {
    if (typeof this.settingValue != "undefined") {
      this.currencies = this.settingValue;
    }
  },
  methods: {
    addCurrency() {
      if (this.currency.trim() != "") {
        if (!this.currencies.includes(this.currency)) {
          this.currencies.push(this.currency);
          this.currency = "";
          this.currency_error = "";
        } else {
          this.currency_error =
            this.currency + (this.locale == "en" ? " is repeated" : " 重复");
        }
      }
    },
    deleteCurrency(index) {
      this.currencies.splice(index, 1);
    },
    onSubmit() {
      if (!this.isLaoding) {
        this.isLaoding = true;
        const vm = this;
        const setting = {
          platform_fiat_currencies: this.currencies
        };
        this.$http
          .put("/api/v1/admin/settings", {
            authenticity_token: this.token,
            setting,
          })
          .then(() => {
            vm.$toast.open({
              message: vm.saveSuccessMessage,
              type: "success",
              position: "top-right",
            });
            vm.isLaoding = false;
          })
          .catch((error) => {
            vm.$toast.open({
              message: vm.saveFailMessage,
              type: "error",
              position: "top-right",
            });
            console.log(error);
            vm.isLaoding = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.inline-input {
  display: flex;
  align-items: center;
}
.inline-input .plus {
  font-size: 30px;
  width: 10px;
  margin-left: 10px;
}
.inline-input .plus:hover {
  cursor: pointer;
  color: #f9b73e;
}

.option-inner {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;

  span {
    background-color: #f9b73e;
    padding: 5px 30px 7px 15px;
    border-radius: 15px;
    color: #fff;
    margin-right: 8px;
    font-size: 14px;
    line-height: 1;
    position: relative;
    margin-bottom: 5px;
  }

  .delete {
    padding: 0;
    position: absolute;
    text-transform: lowercase;
    right: 10px;
    color: red;
    margin: 0;
    cursor: pointer;
    transition: 0.5s opacity;
  }
  .delete:hover {
    opacity: 0.5;
  }
}

.error {
  margin-top: 5px;
  font-size: 14px;
  color: red;
}
</style>