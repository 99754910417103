<template>
  <div>
    <div class="product-wrapper">
      <div
        v-for="(product, index) in product_list"
        :key="index"
        class="product-grid"
      >
        <div class="card">
          <div
            class="image"
            :style="
              'background-image: url(' +
              (product.featured_photo.url
                ? product.featured_photo.url
                : placeholderImage) +
              ');'
            "
          ></div>
          <div class="body">
            <div class="title">
              <a
                v-if="currentMerchant.role == 'BOSS' || currentMerchant.permissions.product.read"
                :title="locale == 'en' ? product.name : product.chinese_name"
                :href="'/' + locale + '/products/' + product.id"
              >
                {{
                  locale == "en"
                    ? product.name
                      ? product.name
                      : product.chinese_name
                    : product.chinese_name
                    ? product.chinese_name
                    : product.name
                }}
              </a>
              <span v-else>
                {{
                  locale == "en"
                    ? product.name
                      ? product.name
                      : product.chinese_name
                    : product.chinese_name
                    ? product.chinese_name
                    : product.name
                }}
              </span>
            </div>
            <div class="category">
              {{
                product.category_id
                  ? locale == "en"
                    ? product.category.name
                      ? product.category.name
                      : product.category.chinese_name
                    : product.category.chinese_name
                    ? product.category.chinese_name
                    : product.category.name
                  : unlistedLabel
              }}
            </div>
            <div v-if="showControl == true" class="price">
              <p v-if="product.product_variants.length > 0">
                {{ product.price_currency }}
                <span v-if="product.min_price == product.max_price">
                  {{
                    Number(
                      product.max_price /
                        (product.price_currency == "USD" ||
                        product.price_currency == "MYR" ||
                        product.price_currency == "RMB"
                          ? 100
                          : 100000000)
                    ).toFixed(
                      product.price_currency == "USD" ||
                        product.price_currency == "MYR" ||
                        product.price_currency == "RMB"
                        ? 2
                        : 8
                    )
                  }}
                </span>
                <span v-else>
                  {{
                    Number(
                      product.min_price /
                        (product.price_currency == "USD" ||
                        product.price_currency == "MYR" ||
                        product.price_currency == "RMB"
                          ? 100
                          : 100000000)
                    ).toFixed(
                      product.price_currency == "USD" ||
                        product.price_currency == "MYR" ||
                        product.price_currency == "RMB"
                        ? 2
                        : 8
                    )
                  }}
                  -
                  {{
                    Number(
                      product.max_price /
                        (product.price_currency == "USD" ||
                        product.price_currency == "MYR" ||
                        product.price_currency == "RMB"
                          ? 100
                          : 100000000)
                    ).toFixed(
                      product.price_currency == "USD" ||
                        product.price_currency == "MYR" ||
                        product.price_currency == "RMB"
                        ? 2
                        : 8
                    )
                  }}
                </span>
              </p>
              <p v-else>
                <span
                  v-if="
                    product.price_currency == 'USD' ||
                    product.price_currency == 'MYR' ||
                    product.price_currency == 'RMB'
                  "
                >
                  {{ product.price_currency }}
                  <span v-if="product.discounted_price_satoshi > 0">
                    <del>{{
                      Number(product.price_satoshi / 100).toFixed(2)
                    }}</del>
                    {{
                      Number(product.discounted_price_satoshi / 100).toFixed(2)
                    }}
                  </span>
                  <span v-else>
                    {{ Number(product.price_satoshi / 100).toFixed(2) }}
                  </span>
                </span>
                <span v-else>
                  {{ product.price_currency }}
                  <span v-if="product.discounted_price_satoshi > 0">
                    <del>{{
                      Number(product.price_satoshi / 100000000).toFixed(8)
                    }}</del>
                    {{
                      Number(
                        product.discounted_price_satoshi / 100000000
                      ).toFixed(8)
                    }}
                  </span>
                  <span v-else>
                    {{ Number(product.price_satoshi / 100000000).toFixed(8) }}
                  </span>
                </span>
              </p>
            </div>
          </div>
        </div>
        <div v-if="showControl == true" class="product-control">
          <a
            v-show="currentMerchant.role == 'BOSS' || currentMerchant.permissions.product.update"
            :href="'/' + locale + '/products/' + product.id + '/edit'"
            class="btn btn-block btn-primary"
            >{{ editLabel }}</a
          >
          <form
            v-show="currentMerchant.role == 'BOSS' || currentMerchant.permissions.product.destroy"
            :id="'form' + index"
            :action="'/' + locale + '/products/' + product.id"
            method="post"
          >
            <input type="hidden" name="_method" value="delete" />
            <input type="hidden" name="authenticity_token" :value="token" />
            <input
              type="submit"
              class="btn btn-primary-outline"
              :value="deleteLabel"
              @click="
                deleteProduct('form' + product.id, confirmDeleteMessage, $event)
              "
            />
          </form>
        </div>
      </div>
    </div>
    <nav
      v-if="product_list.length > 0 && pagination"
      aria-label="pager"
      class="pagy-bootstrap-nav"
    >
      <ul class="pagination">
        <li v-if="pagination.prev" class="page-item prev">
          <a
            @click="page = pagination.prev"
            aria-label="previous"
            class="page-link"
          >
            &lt;
          </a>
        </li>
        <li
          v-for="index in pagination_menu"
          :key="index"
          v-show="index != '...'"
          :class="{ active: index == page }"
          class="page-item"
        >
          <a @click="page = index" class="page-link">{{ index }}</a>
        </li>
        <li v-if="pagination.next" class="page-item next">
          <a
            @click="page = pagination.next"
            rel="next"
            aria-label="next"
            class="page-link"
          >
            &gt;
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  props: [
    "currentMerchant",
    "placeholderImage",
    "showControl",
    "token",
    "editLabel",
    "deleteLabel",
    "unlistedLabel",
    "confirmDeleteMessage",
    "locale",
  ],
  data() {
    return {
      product_list: [],
      pagination: {},
      pagination_menu: [],
      page: 1,
    };
  },
  watch: {
    page: function (p) {
      this.getProduct();
    },
    product_list: function (pl) {
      let vm = this;
      this.product_list.forEach(function (p, index) {
        vm.product_list[index].max_price = Math.max.apply(
          Math,
          p.product_variants.map(function (o) {
            return o.price_satoshi;
          })
        );
        var minPrice = Math.min.apply(
          Math,
          p.product_variants.map(function (o) {
            return o.discounted_price_satoshi;
          })
        );

        if (minPrice <= 0) {
          minPrice = Math.min.apply(
            Math,
            p.product_variants.map(function (o) {
              return o.price_satoshi;
            })
          );
        }

        vm.product_list[index].min_price = minPrice;
      });
    },
  },
  mounted() {
    this.getProduct();
  },
  methods: {
    getProduct() {
      let vm = this;
      let api = "/api/v1/products?items=10";
      api += this.page ? "&page=" + this.page : "";
      // console.log(api);
      this.$http
        .get(api)
        .then((response) => {
          vm.product_list = response.data.data;
          vm.pagination = response.data.pagy;
          // console.log(response, vm.token)
          vm.pagination_menu = vm.generatePagination(
            vm.pagination.page,
            vm.pagination.pages
          );
        })
        .catch((error) => {
          console.log(error);
        });
    },
    generatePagination(c, m) {
      var current = c,
        last = m,
        delta = 1,
        left = current - delta,
        right = current + delta + 1,
        range = [],
        rangeWithDots = [],
        l;

      for (let i = 1; i <= last; i++) {
        if (i == 1 || i == last || (i >= left && i < right)) {
          range.push(i);
        }
      }

      for (let i of range) {
        if (l) {
          if (i - l === 2) {
            rangeWithDots.push(l + 1);
          } else if (i - l !== 1) {
            rangeWithDots.push("...");
          }
        }
        rangeWithDots.push(i);
        l = i;
      }

      return rangeWithDots;
    },
    deleteProduct(form, message, e) {
      var r = confirm(message);
      if (r == true) {
        console.log(form);
        $("#" + form).submit();
      } else {
        e.preventDefault();
      }
    },
  },
};
</script>
