<template>
  <div class="switch-wrapper">
    <md-switch @change="updateSetting" v-model="value">{{
      value ? yesLabel : noLabel
    }}</md-switch>
  </div>
</template>

<script>
export default {
  props: ["storeId", "yesLabel", "noLabel", "inputValue", "token", "updateSuccessful", "somethingWrongMessaeg"],
  data() {
    return {
      value: "",
      isLoading: false,
    };
  },
  mounted() {
    if (typeof this.inputValue != "undefined" && this.inputValue != "") {
      this.value = this.inputValue;
    }
  },
  methods: {
    updateSetting() {
      console.log(this.value)
      if (!this.isLoading) {
        this.isLoading = true;
        const vm = this;
        this.$http
          .put(`/api/v1/stores/${this.storeId}`, {
            authenticity_token: this.token,
            store: {
              stock_overrideable: this.value,
            },
          })
          .then(() => {
            vm.isLoading = false;
            vm.$toast.open({
              message: vm.updateSuccessful,
              type: "success",
              position: "top-right",
            });
          })
          .catch((error) => {
            vm.isLoading = false;
            vm.$toast.open({
              message: vm.somethingWrongMessaeg,
              type: "error",
              position: "top-right",
            });
          });
      }
    },
  },
};
</script>
