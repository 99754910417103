<template>
  <div>
    <div v-show="canUpdateCart" class="form-group">
      <input type="email" name="cart[customer_email]" class="form-control" :placeholder="checkoutEmailField" v-model="customer_email" :data-error-message="emailError">
    </div>
    <div v-if="allow_currency.length > 0">
      <div v-show="canUpdateCart" class="form-group">
        <select name="cart[checkout_currency]" class="form-control" @change="updateCart" v-model="selected_currency" required>
          <option v-for="(currency, index) in allow_currency" :value="currency" :key="index">{{currency}}</option>
        </select>
      </div>
      <div v-show="canUpdateCart" class="form-group">
        <select name="cart[discount_code]" class="form-control" @change="updateCart" v-model="selected_discount_code">
          <option disabled value="">{{selectDiscountLabel}}</option>
          <option v-for="(code, index) in available_discounts" :value="code.code" :key="index">{{getDiscountCodeInfo(code)}}</option>
        </select>
      </div>
      <div v-show="canCreateOrder" class="control btn-wrapper">
        <input type="submit" class="btn btn-block btn-large btn-primary" :value="checkoutBtn">
      </div>  
    </div>
    <div v-else>
      <p class="error">{{zeroWalletMessage}}</p>
    </div>
  </div>
</template>
    

<script>
  export default {
    props: [
      'canUpdateCart',
      'canCreateOrder',
      'orderCurrency', 
      'customerEmail', 
      'discountCode',
      'cartDiscountCode',
      'token',
      'checkoutBtn',
      'checkoutEmailField',
      'zeroWalletMessage',
      'selectDiscountLabel',
      'emailError',
      'locale'
    ],
    data() {
      return {
        selected_currency: null,
        customer_email: null,
        allow_currency: [],
        available_discounts: [],
        selected_discount_code: "",
      }
    },
    computed: {},
    watch: {},
    mounted() {
      if(typeof this.orderCurrency != 'undefined') {
        this.selected_currency = this.orderCurrency;
        this.getAvailableDiscountCode()
      }

      if(typeof this.customerEmail != 'undefined') {
        this.customer_email = this.customerEmail;
      }

      if(typeof this.cartDiscountCode != 'undefined' && this.cartDiscountCode != '') {
        this.selected_discount_code = this.cartDiscountCode
      }

      let vm = this;
      this.$http.get('/api/v1/wallets/allowed_currencies')
        .then(response => {
          vm.allow_currency = response.data.data;
        })
        .catch(error => {
          console.log(error);
        }, this);
    },
    methods: {
      updateCart() {
        this.getAvailableDiscountCode()

        if(this.selected_discount_code != '') {
          var formData = {
            authenticity_token: this.token,
            locale: this.locale,
            cart: {
              checkout_currency: this.selected_currency,
              discount_code: this.selected_discount_code
            }
          };
        }
        else {
          var formData = {
            authenticity_token: this.token,
            cart: {
              checkout_currency: this.selected_currency
            }
          };
        }

        let vm = this;
        this.$http.put('/api/v1/cart', formData)
        .then(response => {
          const cart = response.data.data;
          const currency = cart.checkout_currency;
          const subtotal = (Number((cart.subtotal_price_satoshi) / 100000000).toFixed(8));
          const tax_amount = (Number((cart.tax_amount_satoshi) / 100000000).toFixed(8));
          const total_price = (Number((cart.total_price_satoshi) / 100000000).toFixed(8));
          const discount_price = (Number((cart.discount_amount_satoshi) / 100000000).toFixed(8));
          $('.subtotal').text(currency + subtotal);
          $('.tax_amount').text(currency + tax_amount);
          $('.total_price').text(currency + total_price);
          $('.discount_price').text(currency + discount_price);
          if(response.data.data.error_messages.length > 0) {
              vm.$toast.open({
                message: response.data.data.error_messages[0],
                type: 'error',
                position: 'top-right'
              });
            }
        })
        .catch(error => {
          console.log(error);
        }, this);
      },
      getAvailableDiscountCode() {
        let vm = this
        let isSelectedDiscountCodeFound = false
        this.available_discounts = []
        this.discountCode.forEach(function(code) {
          if (code.currency == vm.selected_currency) {
            vm.available_discounts.push(code)

            if(vm.selected_discount_code != '' && code.code == vm.selected_discount_code) {
              isSelectedDiscountCodeFound = true
            }
          }
        });

        if(!isSelectedDiscountCodeFound) {
          vm.selected_discount_code = ""
        }
      },
      getDiscountCodeInfo(discount) {
        const type = discount.discount_by == 'PERCENT' ? `${discount.discount_percentage}%` : `${discount.discount_amount_satoshi}`
        return `${discount.code} (-${type} ${discount.currency})`
      }
    }
  }
</script>

<style scoped>
  .error {
    color: red;
  }
</style>