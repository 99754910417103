<template>
  <form @submit.prevent="onSubmit">
    <div class="form-group">
      <div class="title">{{ walletAddressLabel }}</div>
      <input class="form-control" :placeholder="walletAddressLabel" v-model="address" required>
    </div>
    <input
      type="submit"
      class="btn btn-large btn-block btn-primary"
      :value="saveLabel"
    />
  </form>
</template>

<script>
export default {
  props: [
    "token",
    "walletAddress",
    "walletAddressLabel",
    "saveLabel",
    "saveSuccessMessage",
    "saveFailMessage",
    "locale",
  ],
  data() {
    return {
      address: '',
      isLaoding: false,
    };
  },
  mounted() {
    if (typeof this.walletAddress != "undefined") {
      this.address = this.walletAddress;
    }
  },
  methods: {
    onSubmit() {
      if (!this.isLaoding) {
        this.isLaoding = true;
        const vm = this;
        this.$http
          .put("/api/v1/admin/settings", {
            authenticity_token: this.token,
            setting: {
              amyr_withdrawal_wallet_address: this.address
            }
          })
          .then(() => {
            vm.$toast.open({
              message: vm.saveSuccessMessage,
              type: "success",
              position: "top-right",
            });
            vm.isLaoding = false;
          })
          .catch((error) => {
            vm.$toast.open({
              message: vm.saveFailMessage,
              type: "error",
              position: "top-right",
            });
            console.log(error);
            vm.isLaoding = false;
          });
      }
    },
  },
};
</script>
