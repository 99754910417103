<template>
<div>
  <div class="flex-row report-menu-row">
    <div v-if="type == 'admin'" class="menu">
      <a :href="'/'+locale+'/admin'" class="active menu-link">{{summaryLabel}}</a>
      <a :href="'/'+locale+'/admin/transactions'" class="menu-link">{{transactionLabel}}</a>
      <a :href="'/'+locale+'/admin/direct_payments'" class="menu-link">{{directpayReportLabel}}</a>
    </div>
    <div v-if="type == 'merchant'" class="menu">
      <a v-show="currentMerchant.role == 'BOSS' || currentMerchant.permissions.order.report" :href="'/'+locale+'/reports'" class="active menu-link">{{reportLabel}}</a>
      <a v-show="currentMerchant.role == 'BOSS' || currentMerchant.permissions.order.read" :href="'/'+locale+'/orders'" class="menu-link">{{orderLabel}}</a>
      <a v-show="currentMerchant.role == 'BOSS' || currentMerchant.permissions.direct_payment.report" :href="'/'+locale+'/direct_pay_report'" class="menu-link">{{directpayReportLabel}}</a>
    </div>
    <!-- <div class="export-wrapper">
      <a @click="exportCsv"><strong>{{exportLabel}}</strong></a>
      <div class="datepicker-wrapper">
        <label>{{fromLabel}}</label>
        <datepicker v-model="from_date" :language="locale == 'cn' ? zh : en" :placeholder="datepickerPlaceholder" calendar-class="right"></datepicker>
      </div>
      <div class="datepicker-wrapper">
        <label>{{toLabel}}</label>
        <datepicker v-model="to_date" :language="locale == 'cn' ? zh : en" :placeholder="datepickerPlaceholder" calendar-class="right"></datepicker>
      </div>
      <a v-if="from_date != null || to_date != null" @click="clearDate" class="btn btn-primary clear-btn">
        {{clearLabel}}
      </a>
    </div> -->
  </div>

  <div class="flex-row">
    <div class="stats-wrapper">
      <div class="card">
        <div class="icon">
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" style="transform: translateY(8px)">
          <path d="M14.4995 21C14.4995 21.5515 14.9475 22 15.4995 22V20C14.948 20 14.4995 20.448 14.4995 21Z" fill="white"/>
          <path d="M1.49896 16.4995V28.4995H30.4995V16.4995H1.49896ZM8.99946 25.5H7.99996V24.5H8.99946V25.5ZM8.99946 20.5H7.99996V19.5H8.99946V20.5ZM3.49996 18.5V26.5H8.99946V27.5H2.99996C2.72346 27.5 2.49996 27.276 2.49996 27V18C2.49996 17.7235 2.72346 17.5 2.99946 17.5H8.99946L8.99996 18.5H3.49996ZM5.99996 20.5V19.5H6.99946V20.5H5.99996ZM6.99946 24.5V25.5H5.99996V24.5H6.99946ZM15.9995 27.9995C12.967 27.9995 10.5 25.5325 10.5 22.5C10.5 19.4675 12.967 17 15.9995 17C19.0315 17 21.499 19.4675 21.499 22.5C21.499 25.5325 19.0315 27.9995 15.9995 27.9995ZM29.499 27C29.499 27.276 29.2755 27.5 28.999 27.5H22.9995V26.5H28.499V18.5H22.999L22.9995 17.5H28.9995C29.2755 17.5 29.499 17.7235 29.499 18V27ZM23.999 24.5V25.5H22.9995V24.5H23.999ZM22.9995 20.5V19.5H23.999V20.5H22.9995ZM25.999 24.5V25.5H24.9995V24.5H25.999ZM24.9995 20.5V19.5H25.999V20.5H24.9995Z" fill="white"/>
          <path d="M15.9995 18C13.518 18 11.5 20.0185 11.5 22.5C11.5 24.981 13.518 26.9995 15.9995 26.9995C18.4805 26.9995 20.499 24.981 20.499 22.5C20.499 20.0185 18.4805 18 15.9995 18ZM16.5 22C17.602 22 18.499 22.897 18.499 24C18.499 25.103 17.602 26 16.5 26H16.499V26.5H15.4995V26C14.3965 26 13.4995 25.103 13.4995 24.0005L14.4995 24C14.4995 24.5515 14.9475 25 15.4995 25V23C14.3965 23 13.4995 22.103 13.4995 21.0005C13.4995 19.8975 14.3965 19.0005 15.4995 19.0005V18.5005H16.499V19.0005H16.5C17.602 19.0005 18.499 19.8975 18.499 21.0005H17.499C17.499 20.449 17.051 20.0005 16.5 20.0005H16.499V22.0005L16.5 22Z" fill="white"/>
          <path d="M16.4989 24.9995H16.4999C17.0509 24.9995 17.4989 24.551 17.4989 24C17.4989 23.448 17.0509 22.9995 16.4999 22.9995H16.4989V24.9995Z" fill="white"/>
          <path d="M13.613 15.4996H14.7915L13.878 14.5861C13.6365 14.8276 13.5455 15.1751 13.613 15.4996Z" fill="white"/>
          <path d="M12.1109 12.1105C14.2549 9.96653 17.7439 9.96653 19.8889 12.1105C20.8089 13.0305 21.3604 14.219 21.4749 15.4995H25.2924L30.2924 10.4995L21.4994 1.70703L7.70691 15.4995H10.5239C10.6384 14.2195 11.1904 13.0305 12.1109 12.1105ZM19.1599 10.0105L18.4534 9.30303L19.1609 8.59603L19.8674 9.30353L19.1599 10.0105ZM20.5749 8.59603L19.8674 7.88853L20.5744 7.18153L21.2819 7.88903L20.5749 8.59603ZM21.9889 12.839L22.6959 12.1315L23.4034 12.8385L22.6964 13.546L21.9889 12.839ZM21.2814 3.64603C21.4769 3.45103 21.7934 3.45053 21.9889 3.64603L28.3534 10.01C28.4474 10.104 28.4994 10.231 28.4994 10.3635C28.4994 10.4965 28.4474 10.6235 28.3534 10.7175L24.1109 14.96L23.4034 14.2525L27.2924 10.3635L21.6354 4.70703L17.7464 8.59603L17.0389 7.88853L21.2814 3.64603ZM23.4034 11.4245L24.1104 10.7175L24.8179 11.425L24.1109 12.132L23.4034 11.4245Z" fill="white"/>
          <path d="M16 15.2925C16.755 14.537 18.073 14.537 18.8275 15.2925C18.8925 15.3575 18.9515 15.427 19.0065 15.4995H20.4695C20.358 14.4865 19.912 13.5485 19.1815 12.818C17.4265 11.0635 14.5715 11.063 12.8175 12.818C12.086 13.549 11.64 14.487 11.529 15.4995H12.599C12.5365 14.9135 12.731 14.318 13.1705 13.8785L12.8165 13.5245L13.524 12.817L13.8775 13.1705C14.658 12.391 15.9265 12.3915 16.7055 13.1705L16 13.8785C15.6095 13.489 14.976 13.489 14.5855 13.878L16 15.2925Z" fill="white"/>
          </svg>
        </div>
        <div class="data">
          <div class="heading">{{totalSalesLabel}}</div>
          <div class="title">{{currency}} {{completed_sales}}</div>
        </div>
      </div>
      <div class="card">
        <div class="icon" style="background: #ffa46e;">
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" style="transform: translateY(8px)">
          <path d="M14.4995 21C14.4995 21.5515 14.9475 22 15.4995 22V20C14.948 20 14.4995 20.448 14.4995 21Z" fill="white"/>
          <path d="M1.49896 16.4995V28.4995H30.4995V16.4995H1.49896ZM8.99946 25.5H7.99996V24.5H8.99946V25.5ZM8.99946 20.5H7.99996V19.5H8.99946V20.5ZM3.49996 18.5V26.5H8.99946V27.5H2.99996C2.72346 27.5 2.49996 27.276 2.49996 27V18C2.49996 17.7235 2.72346 17.5 2.99946 17.5H8.99946L8.99996 18.5H3.49996ZM5.99996 20.5V19.5H6.99946V20.5H5.99996ZM6.99946 24.5V25.5H5.99996V24.5H6.99946ZM15.9995 27.9995C12.967 27.9995 10.5 25.5325 10.5 22.5C10.5 19.4675 12.967 17 15.9995 17C19.0315 17 21.499 19.4675 21.499 22.5C21.499 25.5325 19.0315 27.9995 15.9995 27.9995ZM29.499 27C29.499 27.276 29.2755 27.5 28.999 27.5H22.9995V26.5H28.499V18.5H22.999L22.9995 17.5H28.9995C29.2755 17.5 29.499 17.7235 29.499 18V27ZM23.999 24.5V25.5H22.9995V24.5H23.999ZM22.9995 20.5V19.5H23.999V20.5H22.9995ZM25.999 24.5V25.5H24.9995V24.5H25.999ZM24.9995 20.5V19.5H25.999V20.5H24.9995Z" fill="white"/>
          <path d="M15.9995 18C13.518 18 11.5 20.0185 11.5 22.5C11.5 24.981 13.518 26.9995 15.9995 26.9995C18.4805 26.9995 20.499 24.981 20.499 22.5C20.499 20.0185 18.4805 18 15.9995 18ZM16.5 22C17.602 22 18.499 22.897 18.499 24C18.499 25.103 17.602 26 16.5 26H16.499V26.5H15.4995V26C14.3965 26 13.4995 25.103 13.4995 24.0005L14.4995 24C14.4995 24.5515 14.9475 25 15.4995 25V23C14.3965 23 13.4995 22.103 13.4995 21.0005C13.4995 19.8975 14.3965 19.0005 15.4995 19.0005V18.5005H16.499V19.0005H16.5C17.602 19.0005 18.499 19.8975 18.499 21.0005H17.499C17.499 20.449 17.051 20.0005 16.5 20.0005H16.499V22.0005L16.5 22Z" fill="white"/>
          <path d="M16.4989 24.9995H16.4999C17.0509 24.9995 17.4989 24.551 17.4989 24C17.4989 23.448 17.0509 22.9995 16.4999 22.9995H16.4989V24.9995Z" fill="white"/>
          <path d="M13.613 15.4996H14.7915L13.878 14.5861C13.6365 14.8276 13.5455 15.1751 13.613 15.4996Z" fill="white"/>
          <path d="M12.1109 12.1105C14.2549 9.96653 17.7439 9.96653 19.8889 12.1105C20.8089 13.0305 21.3604 14.219 21.4749 15.4995H25.2924L30.2924 10.4995L21.4994 1.70703L7.70691 15.4995H10.5239C10.6384 14.2195 11.1904 13.0305 12.1109 12.1105ZM19.1599 10.0105L18.4534 9.30303L19.1609 8.59603L19.8674 9.30353L19.1599 10.0105ZM20.5749 8.59603L19.8674 7.88853L20.5744 7.18153L21.2819 7.88903L20.5749 8.59603ZM21.9889 12.839L22.6959 12.1315L23.4034 12.8385L22.6964 13.546L21.9889 12.839ZM21.2814 3.64603C21.4769 3.45103 21.7934 3.45053 21.9889 3.64603L28.3534 10.01C28.4474 10.104 28.4994 10.231 28.4994 10.3635C28.4994 10.4965 28.4474 10.6235 28.3534 10.7175L24.1109 14.96L23.4034 14.2525L27.2924 10.3635L21.6354 4.70703L17.7464 8.59603L17.0389 7.88853L21.2814 3.64603ZM23.4034 11.4245L24.1104 10.7175L24.8179 11.425L24.1109 12.132L23.4034 11.4245Z" fill="white"/>
          <path d="M16 15.2925C16.755 14.537 18.073 14.537 18.8275 15.2925C18.8925 15.3575 18.9515 15.427 19.0065 15.4995H20.4695C20.358 14.4865 19.912 13.5485 19.1815 12.818C17.4265 11.0635 14.5715 11.063 12.8175 12.818C12.086 13.549 11.64 14.487 11.529 15.4995H12.599C12.5365 14.9135 12.731 14.318 13.1705 13.8785L12.8165 13.5245L13.524 12.817L13.8775 13.1705C14.658 12.391 15.9265 12.3915 16.7055 13.1705L16 13.8785C15.6095 13.489 14.976 13.489 14.5855 13.878L16 15.2925Z" fill="white"/>
          </svg>
        </div>
        <div class="data">
          <div class="heading">{{totalRevenueLabel}}</div>
          <div class="title">{{currency}} {{revenue}}</div>
        </div>
      </div>
      <div v-if="type == 'admin'" class="card">
        <div class="icon" style="background: #f24a11;">
          <svg width="28" height="27" viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg" style="transform: translateY(10px)">
            <path d="M18.3449 24.1071H19.7932V27H18.3449V24.1071Z" fill="white"/>
            <path d="M15.9311 24.1071H17.3794V27H15.9311V24.1071Z" fill="white"/>
            <path d="M9.17243 1.92857C8.40858 1.92857 7.66189 2.15479 7.02677 2.57862C6.39165 3.00245 5.89664 3.60485 5.60433 4.30965C5.31202 5.01445 5.23554 5.78999 5.38455 6.53821C5.53357 7.28642 5.9014 7.9737 6.44152 8.51313C6.98165 9.05256 7.6698 9.41992 8.41897 9.56874C9.16814 9.71757 9.94468 9.64119 10.6504 9.34925C11.3561 9.05731 11.9593 8.56293 12.3836 7.92863C12.808 7.29432 13.0345 6.54858 13.0345 5.78571C13.0331 4.76317 12.6258 3.7829 11.9018 3.05985C11.1778 2.3368 10.1963 1.92997 9.17243 1.92857ZM9.17243 5.30357C9.51207 5.3062 9.83998 5.42797 10.0988 5.64759C10.3577 5.86721 10.531 6.1707 10.5886 6.50502C10.6461 6.83934 10.5841 7.18321 10.4135 7.47653C10.2429 7.76984 9.97447 7.99394 9.65519 8.10964V8.67857H8.68967V8.10964C8.40834 8.00957 8.16472 7.82535 7.99196 7.58207C7.8192 7.33879 7.72569 7.04825 7.72415 6.75H8.68967C8.68967 6.84536 8.71798 6.93858 8.77103 7.01786C8.82407 7.09715 8.89947 7.15895 8.98768 7.19544C9.0759 7.23193 9.17296 7.24148 9.26661 7.22288C9.36026 7.20427 9.44628 7.15836 9.51379 7.09093C9.58131 7.0235 9.62729 6.93759 9.64591 6.84406C9.66454 6.75054 9.65498 6.65359 9.61844 6.56549C9.5819 6.47739 9.52003 6.40209 9.44064 6.34911C9.36125 6.29613 9.26791 6.26786 9.17243 6.26786C8.83279 6.26522 8.50487 6.14346 8.24601 5.92384C7.98715 5.70422 7.81382 5.40073 7.7563 5.06641C7.69878 4.73209 7.76075 4.38822 7.93136 4.0949C8.10198 3.80159 8.37038 3.57749 8.68967 3.46179V2.89286H9.65519V3.46179C9.93651 3.56186 10.1801 3.74607 10.3529 3.98936C10.5257 4.23264 10.6192 4.52318 10.6207 4.82143H9.65519C9.65519 4.72607 9.62688 4.63285 9.57383 4.55356C9.52078 4.47428 9.44539 4.41248 9.35717 4.37599C9.26896 4.33949 9.17189 4.32995 9.07825 4.34855C8.9846 4.36715 8.89858 4.41307 8.83106 4.4805C8.76355 4.54793 8.71757 4.63384 8.69894 4.72737C8.68032 4.82089 8.68988 4.91784 8.72642 5.00594C8.76295 5.09404 8.82483 5.16934 8.90422 5.22232C8.98361 5.27529 9.07695 5.30357 9.17243 5.30357Z" fill="white"/>
            <path d="M1.93104 20.7321V14.4643C1.93104 14.3364 1.9819 14.2138 2.07244 14.1234C2.16297 14.0329 2.28577 13.9821 2.4138 13.9821H4.8276C5.21171 13.9821 5.58009 13.8298 5.85169 13.5585C6.1233 13.2872 6.27588 12.9193 6.27588 12.5357C6.27588 12.1521 6.1233 11.7842 5.85169 11.5129C5.58009 11.2417 5.21171 11.0893 4.8276 11.0893H1.44828C1.06453 11.0904 0.696815 11.2432 0.425458 11.5142C0.1541 11.7852 0.00114612 12.1524 0 12.5357V14.4643L0.922072 24.1071H10.8525L12.2042 22.1786H3.37932C2.99557 22.1774 2.62786 22.0247 2.3565 21.7537C2.08514 21.4826 1.93219 21.1154 1.93104 20.7321Z" fill="white"/>
            <path d="M0.965521 25.0714H10.6207V27H0.965521V25.0714Z" fill="white"/>
            <path d="M12.5518 16.3736V12.0488C12.553 11.5389 12.7564 11.0504 13.1174 10.6899C13.4783 10.3294 13.9675 10.1263 14.478 10.125C14.8199 10.126 15.1552 10.2193 15.4483 10.395V0.482143C15.4483 0.354271 15.3975 0.231636 15.3069 0.141216C15.2164 0.0507971 15.0936 0 14.9656 0H3.37932C3.25129 0 3.12849 0.0507971 3.03796 0.141216C2.94742 0.231636 2.89656 0.354271 2.89656 0.482143V10.125H4.8276C5.46778 10.125 6.08174 10.379 6.53442 10.8311C6.98709 11.2832 7.24141 11.8964 7.24141 12.5357C7.24141 13.1751 6.98709 13.7882 6.53442 14.2403C6.08174 14.6924 5.46778 14.9464 4.8276 14.9464H2.89656V18.3214H11.5862V19.2857H2.89656V20.7321C2.89656 20.86 2.94742 20.9827 3.03796 21.0731C3.12849 21.1635 3.25129 21.2143 3.37932 21.2143H14.0725L12.7497 17.5114C12.6197 17.1461 12.5527 16.7613 12.5518 16.3736ZM9.17245 10.6071C8.21764 10.6071 7.28427 10.3244 6.49037 9.79459C5.69648 9.2648 5.07771 8.5118 4.71232 7.6308C4.34693 6.74979 4.25133 5.78037 4.4376 4.8451C4.62388 3.90983 5.08366 3.05074 5.75882 2.37645C6.43397 1.70216 7.29416 1.24296 8.23063 1.05693C9.16709 0.870892 10.1378 0.966373 11.0199 1.3313C11.902 1.69622 12.656 2.31419 13.1865 3.10707C13.7169 3.89995 14.0001 4.83213 14.0001 5.78571C13.998 7.06381 13.4887 8.28897 12.5838 9.19273C11.6789 10.0965 10.4522 10.6051 9.17245 10.6071Z" fill="white"/>
            <path d="M20.7587 24.1071H22.207V27H20.7587V24.1071Z" fill="white"/>
            <path d="M26.0691 13.5C25.8132 13.5008 25.5681 13.6026 25.3872 13.7833C25.2063 13.9639 25.1043 14.2088 25.1035 14.4643V16.3929H24.138V13.9821C24.1373 13.7266 24.0353 13.4818 23.8544 13.3011C23.6735 13.1205 23.4283 13.0186 23.1725 13.0179C23.0672 13.0174 22.9626 13.0353 22.8635 13.0709C22.7219 13.1151 22.5939 13.1947 22.4918 13.3023C22.4017 13.3912 22.3301 13.497 22.2812 13.6137C22.2323 13.7304 22.2071 13.8556 22.207 13.9821V15.9107H21.2415V13.5C21.2407 13.2445 21.1387 12.9997 20.9578 12.819C20.7769 12.6383 20.5318 12.5365 20.2759 12.5357C20.0204 12.5376 19.776 12.6398 19.5953 12.8202C19.4146 13.0007 19.3123 13.2448 19.3104 13.5V15.9107H18.3449V3.85714C18.3441 3.60163 18.2422 3.35681 18.0613 3.17613C17.8804 2.99546 17.6352 2.89362 17.3794 2.89286C17.1239 2.89475 16.8794 2.99695 16.6987 3.17738C16.5181 3.35781 16.4157 3.60198 16.4139 3.85714V15.9107H15.4483V12.0536C15.4482 11.9271 15.423 11.8018 15.3741 11.6852C15.3252 11.5685 15.2536 11.4626 15.1635 11.3737C15.0293 11.2386 14.858 11.1462 14.6712 11.1084C14.4844 11.0706 14.2906 11.089 14.1143 11.1613C13.938 11.2336 13.7872 11.3565 13.6809 11.5145C13.5746 11.6725 13.5177 11.8584 13.5173 12.0488V16.3736C13.5183 16.6511 13.5656 16.9264 13.6573 17.1884L15.7863 23.1429H24.7656L26.8946 17.1836C26.9867 16.9234 27.034 16.6495 27.0346 16.3736V14.4643C27.0327 14.2091 26.9304 13.965 26.7497 13.7845C26.569 13.6041 26.3245 13.5019 26.0691 13.5Z" fill="white"/>
            <path d="M23.1725 24.1071H24.6208V27H23.1725V24.1071Z" fill="white"/>
            <path d="M27.8587 3.03413L25.9276 1.10556L25.245 1.78731L26.352 2.89286H20.7587V3.85715H26.352L25.245 4.9627L25.9276 5.64445L27.8587 3.71588C27.9492 3.62547 28 3.50285 28 3.37501C28 3.24716 27.9492 3.12455 27.8587 3.03413Z" fill="white"/>
            <path d="M23.031 5.64445L22.3484 4.9627L20.4174 6.89127C20.3269 6.98169 20.276 7.1043 20.276 7.23215C20.276 7.36 20.3269 7.48261 20.4174 7.57302L22.3484 9.5016L23.031 8.81985L21.9241 7.71429H27.5173V6.75001H21.9241L23.031 5.64445Z" fill="white"/>
          </svg>
        </div>
        <div class="data">
          <div class="heading">{{totalCommissionLabel}}</div>
          <div class="title">{{currency}} {{commission}}</div>
        </div>
      </div>
    </div>
    <div class="currency-picker-wrapper card">
      <div class="heading">{{currencyLabel}}</div>
      <select v-model="currency">
        <option v-for="(currency, index) in allow_currency" :value="currency" :key="index">{{currency}}</option>
      </select>
    </div>
  </div>

  <div class="card chart-wrapper">
    <!-- <line-chart></line-chart> -->
    <line-chart v-if="chart_data" :chart-data="chart_data" :options="chart_option"></line-chart>
  </div>
</div>
</template>


<script>
  import LineChart from './LineChart.vue'
  import {en, zh} from 'vuejs-datepicker/dist/locale'
  import Datepicker from 'vuejs-datepicker';
  export default {
    props: [
      'type',
      'currentMerchant',
      'isPing',
      'reportLabel',
      'orderLabel',
      'directpayReportLabel',
      'exportLabel',
      'fromLabel',
      'toLabel',
      'clearLabel',
      'currencyLabel',
      'totalRevenueLabel',
      'totalSalesLabel',
      'totalCommissionLabel',
      'revenueLabel',
      'commissionLabel',
      'summaryLabel',
      'transactionLabel',
      'exportError',
      'locale',
      'datepickerPlaceholder',
      'platformCurrency',
      'crytoCurrency',
      'completedLabel'
    ],
    components: {
      LineChart, Datepicker
    },
    data() {
      return {
        allow_currency: [],
        currency: 'XIF',
        from_date: null,
        to_date: null,
        revenue: null,
        completed_sales: null,
        commission: null,
        chart_option: {
          responsive: true, 
          maintainAspectRatio: false
        },
        chart_data: null,
        en: en,
        zh: zh, 
      }
    },
    watch: {
      currency: function(val){
        this.getReportDetail();
        this.getReportChart();
      },
      from_date: function() {
        this.getReportDetail();
      },
      to_date: function() {
        this.getReportDetail();
      }
    },
    mounted() {
      if(this.type == 'merchant') {
        let vm = this
        this.api = '/api/v1/reports';
        this.$http.get('/api/v1/wallets/allowed_currencies')
        .then(response => {
          vm.allow_currency = response.data.data;
          vm.allow_currency = vm.allow_currency.concat(vm.platformCurrency)
          vm.currency = vm.allow_currency[0]
        })
        .catch(error => {
          console.log(error);
        });
      }
      else {
        console.log(this.isPing)
        if (this.isPing == 'true') {
          this.currency = 'PPX'
          this.allow_currency = ['PPX']
        }
        else {
          this.allow_currency = this.allow_currency.concat(this.crytoCurrency)
          this.allow_currency = this.allow_currency.concat(this.platformCurrency)
        }
        this.api = '/api/v1/admin/reports'
      }
      this.getReportDetail();
      this.getReportChart();
    },
    methods: {
      getReportDetail() {
        let vm = this;
        let api = this.api+'?currency='+this.currency;
        api += (this.from_date ? '&from_date='+this.getDateString(this.from_date) : '');
        api += (this.to_date ? '&to_date='+this.getDateString(this.to_date) : '');
        console.log(api);
        this.$http.get(api)
        .then(response => {
          var data = response.data.data;
          vm.revenue = data.total_revenue;
          vm.completed_sales = data.total_completed
          vm.commission = data.total_commission;
        })
        .catch(error => {
          console.log(error);
        });
      },
      getReportChart() {
        let vm = this;
        this.$http.get(this.api+'/chart?currency='+this.currency+'&locale='+this.locale)
        .then(response => {
          var data = response.data.data;
          var datasets = [];
          datasets.push({
            label: vm.completedLabel,
            borderColor: '#f9b73e',
            data: data.total_completed.data
          });
          datasets.push({
            label: vm.revenueLabel,
            borderColor: '#ffa46f',
            data: data.total_revenue.data
          });
          if(vm.type == 'admin') {
            datasets.push({
              label: vm.commissionLabel,
              borderColor: '#f24a10',
              data: data.total_commission.data
            });
          }
          vm.chart_data = {
            labels: data.total_commission.label,
            datasets: datasets
          }
        })
        .catch(error => {
          console.log(error);
        });
      },
      filter() {
        this.getReportDetail();
        // this.$toast.open({
        //   message: 'Total revenue & Commission is updated according to the date',
        //   type: 'success',
        //   position: 'top-right'
        // });
      },
      exportCsv() {
        if(this.from_date && this.to_date) {
          let vm = this;
          if(this.type == 'merchant') {
            this.api = '/api/v1/orders';
          }
          else {
            this.api = '/api/v1/admin/orders';
          }
          let api = this.api+'/export?';
          api += (this.currency ? 'currency='+this.currency : '');
          api += (this.query ? '&query='+this.query : '');
          api += (this.from_date ? '&from_date='+this.getDateString(this.from_date) : '');
          api += (this.to_date ? '&to_date='+this.getDateString(this.to_date) : '');
          api += (this.sort_by ? '&sort_by='+this.sort_by : '');
          window.open(api);
        }
        else {
          alert(this.exportError);
        }
      },
      getDateString(date) {
        var dd = String(date.getDate()).padStart(2, '0');
        var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = date.getFullYear();
        return yyyy+'-'+mm+'-'+dd;
      },
      clearDate() {
        this.from_date = null; 
        this.to_date = null; 
        this.filter();
      }
    },
  }
</script>