<template>
  <div>
    <div
      id="modal-reject"
      :class="`modalDialog small ${showModal ? 'active' : ''}`"
    >
      <div>
        <a class="modal-toggle close" @click="showModal = false">X</a>
        <div style="padding-top: 1rem">
          <h2 class="primary-title" style="color: #000">
            {{ rejectRequestLabel }}
          </h2>
          <p>{{ rejectPromptLabel }}</p>
          <textarea v-model="reject_message" class="form-control"></textarea>
          <p class="text-danger" v-if="rejectMessageRequired" style="margin: 0">
            {{ requiredError }}
          </p>
          <div class="modal-control" style="margin-top: 2rem">
            <a
              class="btn btn-primary-outline"
              @click="
                showModal = false;
                rejectMessageRequired = false;
                reject_message = '';
              "
              >{{ discardLabel }}</a
            >
            <a
              class="modal-toggle btn btn-primary"
              @click="reject(rejectTransaction)"
              >{{ yesLabel }}</a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="wallet-header total-2-card">
      <div class="card">
        <div class="heading">{{ pendingLabel }}</div>
        <div class="title">{{ pending_amount }}</div>
      </div>
      <div class="card">
        <div class="heading">{{ successLabel }}</div>
        <div class="title">{{ success_amount }}</div>
      </div>
    </div>
    <div class="card">
      <div class="body table-responsive">
        <div v-if="withdrawBulkIds.length > 0" class="bulk-update-box">
          {{ withdrawBulkIds.length }}
          {{ locale == "en" ? "items selected." : "记录选中" }}
          <span class="text-primary">{{
            locale == "en" ? "Bulk update status to" : "批量更新状态至"
          }}</span>
          <select v-model="bulkStatus">
            <option value="" disabled>
              {{ locale == "en" ? "Select status" : "选择状态" }}
            </option>
            <option value="bulk_pay">
              {{ locale == "en" ? "Paid" : "付款" }}
            </option>
            <option value="bulk_reject">
              {{ locale == "en" ? "Reject" : "拒绝" }}
            </option>
          </select>
        </div>
        <table class="table active">
          <thead>
            <tr>
              <th>
                <input type="checkbox" v-model="isWithdrawCheckAll" />
              </th>
              <th>{{ storeLabel }}</th>
              <th>{{ bankLabel }}</th>
              <th>{{ dateLabel }}</th>
              <th>{{ typeLabel }}</th>
              <th>{{ amountLabel }}</th>
              <th>{{ feeLabel }}</th>
              <th>{{ statusLabel }}</th>
              <th>{{ actionLabel }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(transaction, tIndex) in withdrawTransactions"
              :key="'convert' + tIndex"
            >
              <td>
                <div style="position: relative">
                  <input
                    v-if="withdrawBulkIds.includes(transaction.id)"
                    type="checkbox"
                    checked
                  />
                  <input v-else type="checkbox" />
                  <div
                    style="
                      position: absolute;
                      top: 0;
                      left: 0;
                      right: 0;
                      bottom: 0;
                      z-index: 999;
                    "
                    @click="
                      updateWithdawBulkId(
                        transaction.id,
                        withdrawBulkIds.includes(transaction.id)
                      )
                    "
                  ></div>
                </div>
              </td>
              <td>
                <a
                  :href="`/${locale}/admin/stores/${transaction.store.id}?page=verification-document`"
                  target="_blank"
                  >{{ transaction.store.name }}</a
                >
              </td>
              <td v-if="transaction.status == 'paid'">
                {{ transaction.bank_name }}
                <br />
                {{ transaction.bank_account_number }}
                <br />
                {{ transaction.bank_account_holder }}
              </td>
              <td v-else>
                {{ transaction.store.bank_name }}
                <br />
                {{ transaction.store.bank_account_number }}
                <br />
                {{ transaction.store.bank_account_holder }}
              </td>
              <td>
                <span v-if="locale == 'cn'">
                  {{ new Date(transaction.created_at).getFullYear() }}年{{
                    new Date(transaction.created_at).getMonth() + 1
                  }}月{{ new Date(transaction.created_at).getDate() }}日
                  {{ new Date(transaction.created_at).getHours() }}:{{
                    new Date(transaction.created_at).getMinutes() < 10
                      ? `0${new Date(transaction.created_at).getMinutes()}`
                      : new Date(transaction.created_at).getMinutes()
                  }}
                </span>
                <span v-else>
                  {{ new Date(transaction.created_at).getDate() }}
                  {{ months[new Date(transaction.created_at).getMonth()] }},
                  {{ new Date(transaction.created_at).getFullYear() }}
                  {{ new Date(transaction.created_at).getHours() }}:{{
                    new Date(transaction.created_at).getMinutes() < 10
                      ? `0${new Date(transaction.created_at).getMinutes()}`
                      : new Date(transaction.created_at).getMinutes()
                  }}
                </span>
              </td>
              <td>
                <span v-if="locale == 'en'"
                  >{{ transaction.withdrawal_type }} withdraw</span
                >
                <span v-else-if="transaction.withdrawal_type == 'ONE-TIME'"
                  >一次性提款</span
                >
                <span v-else-if="transaction.withdrawal_type == 'WEEKLY'"
                  >每星期自动提款</span
                >
                <span v-else-if="transaction.withdrawal_type == 'MONTHLY'"
                  >每月自动提款</span
                >
                {{ transaction.type }}
              </td>
              <td>
                {{
                  Number(transaction.crypto_amount_satoshi / 100000000).toFixed(
                    8
                  )
                }}
                {{ transaction.crypto_amount_currency }}
                <span class="convert-label"> >>> </span>
                {{ Number(transaction.fiat_amount_satoshi / 100).toFixed(2) }}
                {{ transaction.fiat_amount_currency }}
              </td>
              <td>
                {{ Number(transaction.fee_satoshi / 100000000).toFixed(8) }}
                {{ transaction.fee_currency }}
              </td>
              <td>
                <span
                  v-if="locale == 'en'"
                  :class="
                    transaction.status == 'paid'
                      ? 'text-success'
                      : transaction.status == 'rejected'
                      ? 'text-danger'
                      : ''
                  "
                  >{{ transaction.status }}</span
                >
                <span v-else-if="transaction.status == 'pending'">处理中</span>
                <span v-else-if="transaction.status == 'failed'">失败</span>
                <span v-else-if="transaction.status == 'transferred'"
                  >成功转帐</span
                >
                <span
                  v-else-if="transaction.status == 'rejected'"
                  class="text-danger"
                  >已拒绝</span
                >
                <span
                  v-else-if="transaction.status == 'paid'"
                  class="text-success"
                  >已付款</span
                >
              </td>
              <td style="text-align: center; min-width: 150px">
                <div
                  v-if="
                    transaction.status == 'transferred' ||
                    transaction.status == 'rejected'
                  "
                  @click="pay(transaction)"
                  class="btn btn-primary no-word-break mb-2"
                >
                  {{ locale == "en" ? "Pay" : "付款" }}
                </div>
                <!-- @click="reject(transaction)" -->
                <div
                  v-if="transaction.status == 'transferred'"
                  @click="rejectPopup(transaction)"
                  class="btn-hover no-word-break"
                >
                  {{ rejectLabel }}
                </div>
              </td>
            </tr>
            <tr v-show="withdrawTransactions.length == 0">
              <td colspan="9">{{ zeroRequestMessage }}</td>
            </tr>
          </tbody>
        </table>
        <nav
          v-if="withdrawTransactions.length > 0 && withdrawPagination"
          aria-label="pager"
          class="pagy-bootstrap-nav"
        >
          <ul class="pagination">
            <li v-if="withdrawPagination.prev" class="page-item prev">
              <a
                @click="withdrawPage = withdrawPagination.prev"
                aria-label="previous"
                class="page-link"
              >
                &lt;
              </a>
            </li>
            <li
              v-for="index in withdraw_pagination_menu"
              :key="index"
              v-show="index != '...'"
              :class="{ active: index == withdrawPage }"
              class="page-item"
            >
              <a @click="withdrawPage = index" class="page-link">{{ index }}</a>
            </li>
            <li v-if="withdrawPagination.next" class="page-item next">
              <a
                @click="withdrawPage = withdrawPagination.next"
                rel="next"
                aria-label="next"
                class="page-link"
              >
                &gt;
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "token",
    "storeLabel",
    "bankLabel",
    "actionLabel",
    "typeLabel",
    "amountLabel",
    "feeLabel",
    "dateLabel",
    "statusLabel",
    "rejectLabel",
    "zeroRequestMessage",
    "pendingLabel",
    "successLabel",
    "locale",
    "rejectRequestLabel",
    "rejectPromptLabel",
    "discardLabel",
    "yesLabel",
    "requiredError",
  ],
  data() {
    return {
      withdrawTransactions: [],
      withdrawPage: 1,
      withdrawPagination: {},
      withdraw_pagination_menu: [],
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      isWithdrawCheckAll: false,
      withdrawBulkIds: [],
      pending_amount: 0,
      success_amount: 0,
      bulkStatus: "",
      rejectTransaction: 0,
      showModal: false,
      reject_message: "",
      rejectMessageRequired: false,
    };
  },
  computed: {},
  watch: {
    isWithdrawCheckAll: function (val) {
      if (val) {
        let vm = this;
        this.withdrawBulkIds = [];
        this.withdrawTransactions.forEach(function (trans) {
          vm.withdrawBulkIds.push(trans.id);
        });
      } else {
        this.withdrawBulkIds = [];
      }
    },
    bulkStatus: function (val) {
      this.bulkUpdate();
    },
  },
  mounted() {
    this.getWithdrawalRequest();
    this.getWithdrawalSummary();
  },
  methods: {
    generatePagination(c, m) {
      var current = c,
        last = m,
        delta = 1,
        left = current - delta,
        right = current + delta + 1,
        range = [],
        rangeWithDots = [],
        l;

      for (let i = 1; i <= last; i++) {
        if (i == 1 || i == last || (i >= left && i < right)) {
          range.push(i);
        }
      }

      for (let i of range) {
        if (l) {
          if (i - l === 2) {
            rangeWithDots.push(l + 1);
          } else if (i - l !== 1) {
            rangeWithDots.push("...");
          }
        }
        rangeWithDots.push(i);
        l = i;
      }

      return rangeWithDots;
    },
    getWithdrawalRequest() {
      let vm = this;
      let api =
        "/api/v1/admin/withdrawal_requests?status[]=transferred&status[]=paid&status[]=rejected";
      api += "&page=" + this.withdrawPage;
      this.$http
        .get(api)
        .then((response) => {
          vm.withdrawTransactions = response.data.data;

          vm.withdrawPagination = response.data.pagy;
          vm.withdraw_pagination_menu = vm.generatePagination(
            vm.withdrawPagination.page,
            vm.withdrawPagination.pages
          );
        })
        .catch((error) => {
          console.log(error);
        });
    },
    pay(transaction) {
      let vm = this;
      let api = `/api/v1/admin/withdrawal_requests/${transaction.id}/pay`;
      this.$http
        .put(api, { authenticity_token: this.token })
        .then((response) => {
          vm.$toast.open({
            message: vm.locale == "en" ? "pay successfully" : "支付成功",
            type: "success",
            position: "top-right",
          });
          vm.getWithdrawalRequest();
          vm.getWithdrawalSummary();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    rejectPopup(transaction) {
      this.rejectTransaction = transaction;
      this.showModal = true;
    },
    reject(transaction) {
      let storeId = transaction.store.id;
      let vm = this;
      if (this.reject_message != "") {
        this.rejectMessageRequired = false;
        this.$http
          .put(`/api/v1/admin/stores/${storeId}/reject`, {
            authenticity_token: this.token,
            store: {
              reject_message: vm.reject_message,
            },
          })
          .then(() => {
            let api = `/api/v1/admin/withdrawal_requests/${transaction.id}/reject`;
            vm.$http
              .put(api, { authenticity_token: vm.token })
              .then((response) => {
                vm.$toast.open({
                  message:
                    vm.locale == "en" ? "Reject successfully" : "拒绝成功",
                  type: "success",
                  position: "top-right",
                });
                vm.showModal = false;
                vm.reject_message = "";
                vm.rejectMessageRequired = false
                vm.getWithdrawalRequest();
                vm.getWithdrawalSummary();
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((error) => {
            vm.$toast.open({
              message: vm.somethingWrongMessaeg,
              type: "error",
              position: "top-right",
            });
            console.log(error);
          });
      } else {
        this.rejectMessageRequired = true;
      }
    },
    updateWithdawBulkId(id, isExist) {
      if (isExist) {
        this.withdrawBulkIds = this.withdrawBulkIds.filter((n) => {
          return n != id;
        });
      } else {
        this.withdrawBulkIds.push(id);
      }
    },
    bulkUpdate() {
      let vm = this;
      let api = `/api/v1/admin/withdrawal_requests/${this.bulkStatus}`;
      if (vm.bulkStatus != "") {
        this.$http
          .put(api, {
            authenticity_token: this.token,
            withdrawal_request: {
              ids: this.withdrawBulkIds,
            },
          })
          .then(() => {
            vm.$toast.open({
              message:
                vm.locale == "en"
                  ? `${
                      vm.bulkStatus == "bulk_pay" ? "Bulk pay" : "Bulk reject"
                    } successfully`
                  : `${
                      vm.bulkStatus == "bulk_pay" ? "批量支付" : "批量拒绝"
                    }成功`,
              type: "success",
              position: "top-right",
            });
            vm.withdrawBulkIds = [];
            vm.isWithdrawCheckAll = false;
            vm.bulkStatus = "";
            vm.getWithdrawalRequest();
            vm.getWithdrawalSummary();
          })
          .catch((error) => {
            console.log(error);
            vm.bulkStatus = "";
          });
      }
    },
    getWithdrawalSummary() {
      let vm = this;
      let api = "/api/v1/admin/withdrawal_requests/report";
      this.$http
        .get(api)
        .then((response) => {
          vm.pending_amount = response.data.transferred_amount;
          vm.success_amount = response.data.paid_amount;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  watch: {
    withdrawPage: function() {
      this.getWithdrawalRequest()
    }
  }
};
</script>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
}
.table {
  display: none;
}
.table.active {
  display: table;
  box-shadow: none;
  width: calc(100% + 2rem);
  margin-left: -1rem;
  border-top: 1px solid #e5e5e5;
}
.convert-label {
  background: #d3d3d3;
  color: #000;
  padding: 0 0.25rem;
  border-radius: 5px;
  display: inline-block;
  margin: 0 10px;
}
.bulk-update-box {
  background: #f9b73e52;
  padding: 1rem;
  border-radius: 5px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: bold;
}
.bulk-update-box a {
  display: inline-block;
  margin-left: 10px;
}
</style>
