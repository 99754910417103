<template>
  <div style="width: 100%;">
    <div v-show="!transaction_found" class="qrcode">
      <qrcode v-if="allow_to_pay" :value="JSON.stringify(qrcode_value)"></qrcode>
      <div v-if="!allow_to_pay" class="error">{{zeroAmountMessage}}</div>
    </div>
    <div v-show="order.status != 'FAILED'">
      <div v-show="transaction_found" class="order-status"><b>{{paymentLabel}}</b>: {{successfulLabel}}</div>
      <a v-if="transaction_found" :href="'/'+locale+'/orders'" v-show="transaction_found" class="btn btn-primary">{{doneLabel}}</a>
      <div id="timer"></div>
    </div>
  </div>
</template>

<script>
  export default {
    props: [
      'payme',
      'currency', 
      'order', 
      'token',
      'zeroAmountMessage',
      'paymentLabel',
      'doneLabel',
      'successfulLabel',
      'locale',
      "walletApi",
    ],
    data() {
      return {
        transaction: {},
        qrcode_value: {},
        transaction_found: false,
        changes: 0,
        show_done_btn: true,
        allow_to_pay: true,
        expire_time: null,
      }
    },
    computed: {},
    watch: {
      expire_time: function(time){
        let expired_at = new Date(time * 1000);
        let vm = this;
        var x = setInterval(function() {
          var now = new Date().getTime();
          var distance = expired_at - now;

          // Time calculations for days, hours, minutes and seconds
          var days = Math.floor(distance / (1000 * 60 * 60 * 24));
          var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          var seconds = Math.floor((distance % (1000 * 60)) / 1000);

          // Display the result in the element with id="demo"
          $("#timer").html( (vm.locale == 'en' ? 'Expire at ' : '截止 ') + minutes + "m " + seconds + "s <br><small>"+(vm.locale == 'en' ? 'QR code will auto refresh every 10 minutes' : '付款码将每10分钟重新生成')+"</small>");

          // If the count down is finished, write some text
          if (distance < 0) {
            clearInterval(x);
            window.location.reload();
          }
        }, 1000);
      }
    },
    mounted() {
      let order_amount = Number(this.order.total_price_satoshi / 100000000).toFixed(8);
      if(order_amount == '0.00000000') {
        this.allow_to_pay = false;
      }
      else {
        this.qrcode_value = {
          payment: {
            address: this.payme,
            symbol: this.currency,
            notes: this.order.id,
            amount: Number(this.order.total_price_satoshi / 100000000).toFixed(8)
          }
        };
      }
      if(this.order.status != 'COMPLETED' && this.order.status != 'FAILED') {
        this.loop();
      }
      else {
        this.transaction_found = true;
      }
    },
    methods: {
      getPaymeDetail() {
        let vm = this;
        if(!this.transaction_found) {
          this.$http.get(this.walletApi+'/coin/payme/'+this.payme)
          .then(response => {
            if(!vm.expire_time) {
              vm.expire_time = response.data.expiry_timestamp;
            }

            if(response.data.complete) {
              $("#timer").remove();
              vm.transaction_found = true;
              vm.checkOrder();
            }
          })
          .catch(error => {
            console.log(error);
          });
        }
      },
      loop: function(){           
        this.intervalid1 = setInterval(function(){
          this.getPaymeDetail();
        }.bind(this), 1000);
      },
      updateOrder(transaction_id){
        var formData = {
          authenticity_token: this.token,
          order: {
            status: 'COMPLETED',
            transaction_id: transaction_id
          }
        };
        this.$http.put('/api/v1/orders/'+this.order.id, formData)
        .then(response => {})
        .catch(error => {
          console.log(error);
        });
      },
      checkOrder(){
        this.$http.get('/api/v1/orders/'+this.order.id+'/check')
        .then(response => {})
        .catch(error => {
          console.log(error);
        });
      }
    },
  }
</script>

<style scoped>
p {
  word-break: break-all;
}
.btn.btn-primary {
  display: block;
  padding: 1rem;
  margin-bottom: 1rem;
}
#timer {
  text-align: center;
  margin: 1rem auto;
  font-weight: bold;
}
.qrcode canvas {
  width: 100% !important;
  height: 100% !important;
}
</style>

