require("@rails/ujs").start()
// require("@rails/activestorage").start()
require("channels")

window.$ = require('jquery')
window.JQuery = require('jquery')
  
// import style
import '../stylesheets/merchant'
import Vue from 'vue/dist/vue.esm'
import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
Vue.use(VueMaterial)
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
Vue.use(VueToast);
import axios from 'axios'
Vue.prototype.$http = axios
import vSelect from 'vue-select'
Vue.component('v-select', vSelect)
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
Vue.use(VueLodash, {
    name: 'custom',
    lodash: lodash
})

import VCalendar from 'v-calendar';
Vue.use(VCalendar, {});

import RichTextEditor from '../components/RichTextEditor.vue'
Vue.component('rich-text-editor', RichTextEditor)
import ToggleInput from '../components/ToggleInput.vue'
Vue.component('switch-input', ToggleInput)
import ImageInput from '../components/ImageInput.vue'
Vue.component('image-input', ImageInput)
import AddCartControl from '../components/AddCartControl.vue'
Vue.component('add-cart-control', AddCartControl)
import AddCartVariant from '../components/AddCartVariant.vue'
Vue.component('add-cart-variant', AddCartVariant)
import StoreForm from '../components/StoreForm.vue'
Vue.component('store-form', StoreForm)
import AccountForm from '../components/AccountForm.vue'
Vue.component('account-form', AccountForm)
import VerifyDocumentForm from '../components/VerifyDocumentForm.vue'
Vue.component('verify-document-form', VerifyDocumentForm)
import WalletCard from '../components/WalletCard.vue'
Vue.component('wallet-card', WalletCard)
import WalletTransaction from '../components/WalletTransaction.vue'
Vue.component('wallet-transaction', WalletTransaction)
import VueQrcode from '@chenfengyuan/vue-qrcode';
Vue.component(VueQrcode.name, VueQrcode);
import OrderTransaction from '../components/OrderTransaction.vue'
Vue.component('order-transaction', OrderTransaction)
import CheckoutCurrencyPicker from '../components/CheckoutCurrencyPicker.vue'
Vue.component('checkout-currency-picker', CheckoutCurrencyPicker)
import StoreTable from '../components/StoreTable.vue'
Vue.component('store-table', StoreTable)
import PendingStoreTable from '../components/PendingStoreTable.vue'
Vue.component('pending-store-table', PendingStoreTable)
import WalletWithdraw from '../components/WalletWithdraw.vue'
Vue.component('wallet-withdraw', WalletWithdraw)
import AdminReport from '../components/AdminReport.vue'
Vue.component('admin-report', AdminReport)
import OrderTable from '../components/OrderTable.vue'
Vue.component('order-table', OrderTable)
import MerchantOrderTable from '../components/MerchantOrderTable.vue'
Vue.component('merchant-order-table', MerchantOrderTable)
import CurrencyPicker from '../components/CurrencyPicker.vue'
Vue.component('currency-picker', CurrencyPicker)
import ProductList from '../components/ProductList.vue'
Vue.component('product-list', ProductList)
import PasswordInput from '../components/PasswordInput.vue'
Vue.component('password-input', PasswordInput)
import LocalePicker from '../components/LocalePicker.vue'
Vue.component('locale-picker', LocalePicker)
import ProductVariation from '../components/ProductVariation.vue'
Vue.component('product-variety', ProductVariation)
import DirectPayPage from '../components/DirectPayPage.vue'
Vue.component('direct-pay-page', DirectPayPage)
import DirectPayReport from '../components/DirectPayReport.vue'
Vue.component('direct-pay-report', DirectPayReport)
import BranchMerchantPicker from '../components/BranchMerchantPicker.vue'
Vue.component('branch-merchant-picker', BranchMerchantPicker)
import InventoryTransactionInput from '../components/InventoryTransactionInput.vue'
Vue.component('inventory-transaction-input', InventoryTransactionInput)
import BranchInventory from '../components/BranchInventory.vue'
Vue.component('branch-inventory', BranchInventory)
import InventoryTransaction from '../components/InventoryTransaction.vue'
Vue.component('inventory-transaction', InventoryTransaction)
import BranchPicker from '../components/BranchPicker.vue'
Vue.component('branch-picker', BranchPicker)
import ProductBranchToggle from '../components/ProductBranchToggle.vue'
Vue.component('product-branch-toggle', ProductBranchToggle)
import ConnectInventoryForm from '../components/ConnectInventoryForm.vue'
Vue.component('connect-inventory-form', ConnectInventoryForm)
import ConnectBranchLocation from '../components/ConnectBranchLocation.vue'
Vue.component('connect-branch-location', ConnectBranchLocation)
import AdminStoreForm from '../components/AdminStoreForm.vue'
Vue.component('admin-store-form', AdminStoreForm)
import CartMenu from '../components/CartMenu.vue'
Vue.component('cart-menu', CartMenu)
import DashboardBanner from '../components/DashboardBanner.vue'
Vue.component('dashboard-banner', DashboardBanner)
import ConnectLoyaltyForm from '../components/ConnectLoyaltyForm.vue'
Vue.component('connect-loyalty-form', ConnectLoyaltyForm)
import ConnectLoyaltySource from '../components/ConnectLoyaltySource.vue'
Vue.component('connect-loyalty-source', ConnectLoyaltySource)
import MultipleImageInput from '../components/MultipleImageInput.vue'
Vue.component('multiple-image-input', MultipleImageInput)
import MultipleDocumentInput from '../components/MultipleDocumentInput.vue'
Vue.component('multiple-document-input', MultipleDocumentInput)
import ProductGallery from '../components/ProductGallery.vue'
Vue.component('product-gallery', ProductGallery)
import AutoWithdrawal from '../components/AutoWithdrawal.vue'
Vue.component('auto-withdrawal', AutoWithdrawal)
import FiatWithdraw from '../components/FiatWithdraw.vue'
Vue.component('fiat-withdrawal', FiatWithdraw)
import WithdrawRateTable from '../components/WithdrawRateTable.vue'
Vue.component('withdraw-rate-table', WithdrawRateTable)
import VerificationRejectBtn from '../components/VerificationRejectBtn.vue'
Vue.component('verification-reject-btn', VerificationRejectBtn)
import VerificationApproveBtn from '../components/VerificationApproveBtn.vue'
Vue.component('verification-approve-btn', VerificationApproveBtn)
import BusinessNatureData from '../components/BusinessNatureData.vue'
Vue.component('business-nature-data', BusinessNatureData)
import ConversionRequest from '../components/ConversionRequest.vue'
Vue.component('conversion-request', ConversionRequest)
import WithdrawalRequest from '../components/WithdrawalRequest.vue'
Vue.component('withdrawal-request', WithdrawalRequest)
import DiscountTypeDropdown from '../components/DiscountTypeDropdown.vue'
Vue.component('discount-type-dropdown', DiscountTypeDropdown)
import DiscountDateRange from '../components/DiscountDateRange.vue'
Vue.component('discount-date-range', DiscountDateRange)
import NullableInput from '../components/NullableInput.vue'
Vue.component('nullable-input', NullableInput)
import PlatformFiatCurrenciesForm from '../components/PlatformFiatCurrenciesForm.vue'
Vue.component('platform-fiat-currencies-form', PlatformFiatCurrenciesForm)
import AmyrWithdrawalWalletAddressForm from '../components/AmyrWithdrawalWalletAddressForm.vue'
Vue.component('amyr-withdrawal-wallet-address-form', AmyrWithdrawalWalletAddressForm)
import XpayPingAccountForm from '../components/XpayPingAccountForm.vue'
Vue.component('xpay-ping-account-form', XpayPingAccountForm)
import StockOverrideableSwitcher from '../components/StockOverrideableSwitcher.vue'
Vue.component('stock-overrideable-switcher', StockOverrideableSwitcher)
import TaxSettingForm from '../components/TaxSettingForm.vue'
Vue.component('tax-setting-form', TaxSettingForm)
import PermissionForm from '../components/PermissionForm.vue'
Vue.component('permission-form', PermissionForm)

window.addEventListener('load', function () {
  var app = new Vue().$mount('#app')

  // modal toggle
  $('.modal-toggle').on('click', function(e){
    var target = $(this).attr('data-target');
    $(target).toggleClass('active');
  });

  // tab toggle
  $('.tab-link').on('click', function(e){
    var tab = $(this).attr('data-tab');
    var target = $(this).attr('data-target');

    $(this).parent().find('.tab-link').removeClass('current');
    $(this).addClass('current');

    $(tab).find('.tab-content').removeClass('active');
    $(target).addClass('active');
  });

  // form need confirm only submit
  $(".btn-confirm").on('click', function(e) {
    e.preventDefault();
    var label = $(this).attr('data-label') ? $(this).attr('data-label') : 'Are you sure want to delete?';
    var r = confirm(label);
    if (r == true) {
      $(this).parent().submit();
    }
  });

  $('.menu-toggle').on('click', function(e){
    $('body').toggleClass('nav-open');
  });

  $('input[type=email]').on('invalid', function() {
    this.setCustomValidity($(this).data("error-message"));
  });

  $('input[required]').on('invalid', function(e) {
    if(e.target.value == '') {
      this.setCustomValidity($('#required-error').val());
    }
    else {
      this.setCustomValidity('');
    }
  });

  $('.active-btn').on('click', function() {
    let target = $(this).attr('data-target')
    $(target).toggleClass('active')
  })

  // $('input[name="product[price]"]').on('change', function(e){
  //   $('input[name="product[product_variants_attributes[][price]]"').val($(this).val());
  // });
});
