<template>
<div class="form-group">
  <div class="title">{{inputLabel}}</div>
  <input 
    class="form-control" 
    :type="inputType" 
    :placeholder="inputPlaceholder" 
    :name="(value != '' ? inputName : '')" 
    :step="inputType == 'number' ? inputStep : 1"
    :min="inputType == 'number' ? inputMin : 0"
    v-model="value">
</div>
</template>

<script>
  export default {
    props: ['inputLabel', 'inputType', 'inputPlaceholder', 'inputName', 'inputStep', 'inputMin', 'inputValue'],
    data() {
      return {
        value: "",
      }
    },
    mounted() {
      if(typeof this.inputValue != 'undefined' && this.inputValue != ''){
        this.value = this.inputValue;
      }
    }
  }
</script>
