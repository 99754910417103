<template>
  <div style="min-width: 100%">
    <div class="flex-row" style="align-items: flex-start">
      <div v-if="roleType == 'admin'" class="menu">
        <a :href="'/' + locale + '/admin/'" class="menu-link">{{
          summaryLabel
        }}</a>
        <a :href="'/' + locale + '/admin/transactions'" class="menu-link">{{
          orderLabel
        }}</a>
        <a
          :href="'/' + locale + '/admin/direct_payments'"
          class="menu-link active"
          >{{ directpayReportLabel }}</a
        >
      </div>
      <div v-else class="menu">
        <a
          v-show="currentMerchant.role == 'BOSS' || currentMerchant.permissions.order.report"
          :href="'/' + locale + '/reports'"
          class="menu-link"
          >{{ reportLabel }}</a
        >
        <a v-show="currentMerchant.role == 'BOSS' || currentMerchant.permissions.order.read" :href="'/' + locale + '/orders'" class="menu-link">{{
          orderLabel
        }}</a>
        <a
          v-show="currentMerchant.role == 'BOSS' || currentMerchant.permissions.direct_payment.report"
          :href="'/' + locale + '/direct_pay_report'"
          class="active menu-link"
          >{{ directpayReportLabel }}</a
        >
      </div>
    </div>

    <div class="flex-row">
      <div class="export-wrapper">
        <a
          v-if="from_date != null || to_date != null"
          @click="clearDate"
          class="btn btn-primary clear-btn"
        >
          {{ clearLabel }}
        </a>
        <div class="datepicker-wrapper">
          <label>{{ fromLabel }}</label>
          <v-date-picker
            v-model="from_date"
            :locale="locale == 'cn' ? 'zh' : 'en'"
            :masks="{
              input: locale == 'cn' ? 'DD日 MM月 YYYY年' : 'DD-MM-YYYY',
            }"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                class="v-datepicker"
                :placeholder="datepickerPlaceholder"
                :value="inputValue"
                v-on="inputEvents"
              />
            </template>
          </v-date-picker>
        </div>
        <div class="datepicker-wrapper">
          <label>{{ toLabel }}</label>
          <v-date-picker
            v-model="to_date"
            :locale="locale == 'cn' ? 'zh' : 'en'"
            :masks="{
              input: locale == 'cn' ? 'DD日 MM月 YYYY年' : 'DD-MM-YYYY',
            }"
            :min-date='from_date'
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                class="v-datepicker"
                :placeholder="datepickerPlaceholder"
                :value="inputValue"
                v-on="inputEvents"
              />
            </template>
          </v-date-picker>
        </div>
        <!-- <a @click="exportCsv"><strong>{{exportLabel}}</strong></a> -->
      </div>
      <div class="currency-picker-wrapper card">
        <div class="heading">{{ currencyLabel }}</div>
        <select v-model="currency">
          <option
            v-for="(currency, index) in currency_options"
            :value="currency"
            :key="index"
          >
            {{ currency }}
          </option>
        </select>
      </div>
    </div>

    <div class="card admin-table">
      <form @submit.prevent="search" class="table-header">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="25pt"
          version="1.1"
          viewBox="-1 0 136 136.21852"
          width="25pt"
        >
          <g id="surface1">
            <path
              d="M 93.148438 80.832031 C 109.5 57.742188 104.03125 25.769531 80.941406 9.421875 C 57.851562 -6.925781 25.878906 -1.460938 9.53125 21.632812 C -6.816406 44.722656 -1.351562 76.691406 21.742188 93.039062 C 38.222656 104.707031 60.011719 105.605469 77.394531 95.339844 L 115.164062 132.882812 C 119.242188 137.175781 126.027344 137.347656 130.320312 133.269531 C 134.613281 129.195312 134.785156 122.410156 130.710938 118.117188 C 130.582031 117.980469 130.457031 117.855469 130.320312 117.726562 Z M 51.308594 84.332031 C 33.0625 84.335938 18.269531 69.554688 18.257812 51.308594 C 18.253906 33.0625 33.035156 18.269531 51.285156 18.261719 C 69.507812 18.253906 84.292969 33.011719 84.328125 51.234375 C 84.359375 69.484375 69.585938 84.300781 51.332031 84.332031 C 51.324219 84.332031 51.320312 84.332031 51.308594 84.332031 Z M 51.308594 84.332031 "
              style="
                stroke: none;
                fill-rule: nonzero;
                fill: rgb(0%, 0%, 0%);
                fill-opacity: 1;
              "
            />
          </g>
        </svg>
        <input
          type="text"
          :placeholder="searchInputLabel"
          name="query"
          v-model="query"
        />
        <input
          type="number"
          :placeholder="searchInputAmountLabel"
          name="amount"
          v-model="amount"
          step="0.00000001"
        />
        <input type="submit" :value="searchLabel" class="btn btn-primary" />
      </form>
      <table>
        <thead>
          <tr>
            <th>{{ transactLabel }}</th>
            <th>{{ amountLabel }}</th>
            <th v-if="roleType == 'admin'">{{ commissionLabel }}</th>
            <th>{{ currencyLabel }}</th>
            <th>{{ createdbyLabel }}</th>
            <th>{{ remarkLabel }}</th>
            <th>{{ locale == 'cn' ? '付款交易#' : 'payme transaction #' }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="order_list.length == 0">
            <td colspan="6">{{ searchOrderFail }}</td>
          </tr>

          <tr v-for="(order, index) in order_list" :key="index">
            <td class="text-primary">
              <b v-if="locale == 'cn'">
                {{ new Date(order.created_at).getFullYear() }}年{{
                  new Date(order.created_at).getMonth() + 1
                }}月{{ new Date(order.created_at).getDate() }}日
                {{ new Date(order.created_at).getHours() }}:{{
                  new Date(order.created_at).getMinutes() < 10
                    ? `0${new Date(order.created_at).getMinutes()}`
                    : new Date(order.created_at).getMinutes()
                }}
              </b>
              <b v-else>
                {{ new Date(order.created_at).getDate() }}
                {{ months[new Date(order.created_at).getMonth()] }},
                {{ new Date(order.created_at).getFullYear() }}
                {{ new Date(order.created_at).getHours() }}:{{
                  new Date(order.created_at).getMinutes() < 10
                    ? `0${new Date(order.created_at).getMinutes()}`
                    : new Date(order.created_at).getMinutes()
                }}
              </b>
            </td>
            <td>{{ Number(order.amount_satoshi / 100000000).toFixed(8) }}</td>
            <td v-if="roleType == 'admin'">{{ Number(order.commission_amount_satoshi / 100000000).toFixed(8) }}</td>
            <td>{{ order.amount_currency }}</td>
            <td>
              {{ order.merchant ? order.merchant.first_name : "" }}
              {{ order.merchant ? order.merchant.last_name : "" }}
            </td>
            <td>{{ order.notes }}</td>
            <td>
              <a v-if="order.payme_transaction && order.payme_transaction.recipient_txn" :href="`${scannerUrl}${order.payme_transaction.recipient_txn}`" target="_blank">{{ locale == 'cn' ? '查看交易' : 'View transaction'}}</a>
              <span v-else>-</span>
            </td>
          </tr>
        </tbody>
      </table>
      <nav
        v-if="order_list.length > 0 && pagination"
        aria-label="pager"
        class="pagy-bootstrap-nav"
      >
        <ul class="pagination">
          <li v-if="pagination.prev" class="page-item prev">
            <a
              @click="page = pagination.prev"
              aria-label="previous"
              class="page-link"
            >
              &lt;
            </a>
          </li>
          <li
            v-for="index in pagination_menu"
            :key="index"
            v-show="index != '...'"
            :class="{ active: index == page }"
            class="page-item"
          >
            <a @click="page = index" class="page-link">{{ index }}</a>
          </li>
          <li v-if="pagination.next" class="page-item next">
            <a
              @click="page = pagination.next"
              rel="next"
              aria-label="next"
              class="page-link"
            >
              &gt;
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import { en, zh } from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";
export default {
  props: [
    "isPing",
    "crytoCurrency",
    "currentMerchant",
    "roleType",
    "summaryLabel",
    "reportLabel",
    "orderLabel",
    "directpayReportLabel",
    "exportLabel",
    "fromLabel",
    "toLabel",
    "clearLabel",
    "searchInputLabel",
    "searchInputAmountLabel",
    "searchLabel",
    "transactLabel",
    "currencyLabel",
    "amountLabel",
    "remarkLabel",
    "payNowLabel",
    "searchOrderFail",
    "exportError",
    "locale",
    "datepickerPlaceholder",
    "createdbyLabel",
    "commissionLabel",
    "scannerUrl"
  ],
  components: {
    Datepicker,
  },
  data() {
    return {
      currency: "",
      currency_options: [],
      sort_by: "",
      from_date: null,
      to_date: null,
      query: null,
      amount: null,
      order_list: [],
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      pagination: {},
      pagination_menu: [],
      page: 1,
      en: en,
      zh: zh,
    };
  },
  watch: {
    currency: function (val) {
      this.getPayment();
    },
    sort_by: function (val) {
      this.getPayment();
    },
    page: function (p) {
      this.getPayment();
    },
    from_date: function () {
      this.getPayment();
    },
    to_date: function () {
      this.getPayment();
    },
  },
  mounted() {
    if (this.isPing == "true") {
      this.currency = "PPX";
      this.currency_options = ["PPX"];
    } else if (typeof this.roleType == "undefined") {
      let vm = this;
      this.$http
        .get("/api/v1/wallets/allowed_currencies")
        .then((response) => {
          vm.currency_options = response.data.data;
          vm.currency = vm.currency_options[0];
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      if (typeof this.crytoCurrency !== "undefined") {
        this.currency_options = this.currency_options.concat(
          this.crytoCurrency
        );
        this.currency = this.crytoCurrency[0];
      }
    }
    this.getPayment();
  },
  methods: {
    getPayment() {
      let vm = this;
      let api =
        "/api/v1/" +
        (typeof this.roleType != "undefined" ? "admin/" : "") +
        "direct_payments?";
      api += this.currency ? "currency=" + this.currency : "";
      api += this.query ? "&query=" + this.query : "";
      api += this.amount ? "&amount=" + this.amount : "";
      api += this.from_date
        ? "&from_date=" + this.getDateString(this.from_date)
        : "";
      api += this.to_date ? "&to_date=" + this.getDateString(this.to_date) : "";
      api += this.sort_by ? "&sort_by=" + this.sort_by : "";
      api += this.page ? "&page=" + this.page : "";
      this.$http
        .get(api)
        .then((response) => {
          vm.order_list = response.data.data;

          vm.pagination = response.data.pagy;
          vm.pagination_menu = vm.generatePagination(
            vm.pagination.page,
            vm.pagination.pages
          );
        })
        .catch((error) => {
          console.log(error);
        });
    },
    search() {
      this.getPayment();
    },
    filter() {
      this.getPayment();
    },
    clearDate() {
      this.from_date = null;
      this.to_date = null;
      this.filter();
    },
    getDateString(date) {
      var dd = String(date.getDate()).padStart(2, "0");
      var mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = date.getFullYear();
      return yyyy + "-" + mm + "-" + dd;
    },
    generatePagination(c, m) {
      var current = c,
        last = m,
        delta = 1,
        left = current - delta,
        right = current + delta + 1,
        range = [],
        rangeWithDots = [],
        l;

      for (let i = 1; i <= last; i++) {
        if (i == 1 || i == last || (i >= left && i < right)) {
          range.push(i);
        }
      }

      for (let i of range) {
        if (l) {
          if (i - l === 2) {
            rangeWithDots.push(l + 1);
          } else if (i - l !== 1) {
            rangeWithDots.push("...");
          }
        }
        rangeWithDots.push(i);
        l = i;
      }

      return rangeWithDots;
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 769px) {
  .menu {
    margin-bottom: 2rem;
  }
}
.dropdown-wrapper {
  display: flex;
}
.dropdown-wrapper .currency-card {
  margin-right: 1rem;
}
.export-wrapper {
  padding: 2rem 1.5rem 1rem 1.5rem;
}
.pay-btn {
  margin-top: 10px;
  display: inline-block;
}
input[type="text"],
input[type="number"] {
  width: 50%;
  font-size: 16px;
  border: 0;
  padding: 1.25rem 2rem 1.25rem 5rem;
  font-weight: 500;
}
input[type="number"] {
  padding: 1.25rem 1rem;
}
</style>