<template>
  <section>
    <div class="card">
      <div class="card-header flex-header">
        <h2 class="primary-title">{{ current_store.name }}</h2>
      </div>
      <div class="card-body two-column-wrapper">

          <div class="form-group" >
            <div class="title">{{ ownerLabel }}</div>
            <div class="data">
              {{ getBoss(current_store, 'first_name') }} {{ getBoss(current_store, 'last_name') }}
            </div>
          </div>
          <div class="form-group">
            <div class="title">{{ ownerLabel }}{{ emailLabel }}</div>
            <div class="data">
              {{ getBoss(current_store, 'email') }}
            </div>
          </div>

          <!-- <div class="form-group" >
            <div class="title">{{ connectInventoryLabel }}</div>
            <div
              @click="updateCentralizedInventoryStatus()"
              :class="'toggle ' + (current_store.centralized_inventory ? 'checked' : '')"
            >
              <div class="dot"></div>
              <div class="label">
                {{ current_store.centralized_inventory ? activeStatus : noActiveStatus }}
              </div>
            </div>
          </div>
          <div class="form-group" >
            <div class="title">{{ loyaltyLabel }}</div>
            <div
              @click="updateLoyalty()"
              :class="'toggle ' + (current_store.loyalty ? 'checked' : '')"
            >
              <div class="dot"></div>
              <div class="label">
                {{ current_store.loyalty ? activeStatus : noActiveStatus }}
              </div>
            </div>
          </div> -->
          <div class="form-group">
            <div class="title">{{ storeLabel }}{{locale === 'en' ? ' ': ''}}{{ statusLabel }}</div>
            <div
              @click="updateStatus()"
              :class="'toggle ' + (current_store.active ? 'checked' : '')"
            >
              <div class="dot"></div>
              <div class="label">
                {{ current_store.active ? activeStatus : noActiveStatus }}
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="title">{{ ownerLabel }}{{ statusLabel }}</div>
            <div
              @click="updateBossStatus(current_store)"
              :class="'toggle ' + (getBoss(current_store, 'active') ? 'checked' : '')"
            >
              <div class="dot"></div>
              <div class="label">
                {{ getBoss(current_store, 'active') ? activeStatus : noActiveStatus }}
              </div>
            </div>
          </div>

          <div class="form-group" >
            <div class="title">{{ branchLimitLabel }}</div>
            <input
              class="form-control"
              type="number"
              min="1"
              max="100"
              v-model="current_store.branch_limit"
              @change="updateBranchLimit(index)"
            />
          </div>
          <div v-if="adminRole == 'SUPERADMIN' || adminRole == 'ADMIN'" class="form-group">
            <div class="title">{{ commissionLabel }}</div>
            <input
              class="form-control"
              type="number"
              min="1"
              max="100"
              v-model="current_store.commission_rate"
              @change="updateCommission(index)"
              style="width:90% !important;"
            /> %
          </div>

          <div class="overflow-auto md-scrollbar">
            <div class="title">{{ walletAssignmentLabel }}</div>
            <div class="d-flex">
              <div
                class="mb-2 mr-2"
                @click="updateWallet(index, wIndex)"
                :class="'toggle ' + (wallet.is_allowed ? 'checked' : '')"
                v-for="(wallet, wIndex) in current_store.merchant_wallets"
                :key="'w' + wIndex"
              >
                <div class="dot"></div>
                <div class="label">
                  {{ wallet.currency }}
                </div>
              </div>
            </div>
          </div>

      </div>
    </div>
    <div class="control" style="margin: 2rem auto;">
      <button @click="updateStore()" class="btn btn-large btn-primary">{{saveLabel}}</button>
    </div>
  </section>
</template>

<script>
export default {
  props: [
    "adminRole",
    "store",
    "token",
    "searchStoreLabel",
    "searchLabel",
    "storeLabel",
    "loyaltyLabel",
    "emailLabel",
    "connectInventoryLabel",
    "branchLimitLabel",
    "walletAssignmentLabel",
    "commissionLabel",
    "activeLabel",
    "joinSinceLabel",
    "updateLabel",
    "editLabel",
    "saveLabel",
    "ownerLabel",
    "emailLabel",
    "statusLabel",
    "updateConfirmMessage",
    "storeUpdatedMessage",
    "somethingWrongMessaeg",
    "commissionBiggerError",
    "commissionSmallerError",
    "activeStatus",
    "noActiveStatus",
    "branchLimitSmallerError",
    "locale",
  ],
  data() {
    return {
      current_store: this.store,
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      query: null,
    };
  },
  mounted() {
    this.getStore();
    // console.log(this.store)
  },
  methods: {
    getStore() {
      this.current_store.commission_rate = this.current_store.commission_rate * 100;
    },
    updateWallet(index, wIndex) {
      var r = confirm(this.updateConfirmMessage);
      if (r == true) {
        var vm = this;
        var wallet = this.current_store.merchant_wallets[wIndex];
        var store_id = this.current_store.id;
        var wallet_id = wallet.id;
        var is_allowed = !wallet.is_allowed;
        var formData = {
          authenticity_token: this.token,
          store: {
            merchant_wallets_attributes: {
              id: wallet_id,
              is_allowed: is_allowed,
            },
          },
        };
        this.$http
          .put("/api/v1/admin/stores/" + store_id, formData)
          .then((response) => {
            vm.$toast.open({
              message: vm.storeUpdatedMessage,
              type: "success",
              position: "top-right",
            });
            vm.current_store.merchant_wallets[
              wIndex
            ].is_allowed = is_allowed;
          })
          .catch((error) => {
            vm.$toast.open({
              message: vm.somethingWrongMessaeg,
              type: "error",
              position: "top-right",
            });
            console.log(error);
          });
      }
    },
    updateLoyalty(index) {
      this.current_store.loyalty = !this.current_store.loyalty;
    },
    updateCentralizedInventoryStatus(index) {
      this.current_store.centralized_inventory = !this.current_store.centralized_inventory;
    },
    updateStatus(index) {
      this.current_store.active = !this.current_store.active;
    },
    updateBossStatus(currentStore) {
      const vm = this;
      const bossId = this.getBoss(currentStore, 'id');
      const bossActive = this.getBoss(currentStore, 'active');
      currentStore.merchants.find(x => x.id === bossId).active = !currentStore.merchants.find(x => x.id === bossId).active
      let formData = {
        authenticity_token: vm.token,
        merchant:{
          active: !bossActive,
        }
      }
      this.$http
        .put(`/api/v1/admin/merchants/${bossId}`, formData)
        .then(res => {
          vm.$toast.open({
            message: vm.storeUpdatedMessage,
            type: "success",
            position: "top-right",
          });
          // window.location = `/${vm.locale}/admin/stores`
        })
        .catch((error) => {
          vm.$toast.open({
            message: vm.somethingWrongMessaeg,
            type: "error",
            position: "top-right",
          });
          console.log(error);
        })
    },
    getBoss(store,attr) {
      let boss = "-";
      if (store.merchants) {
        store.merchants.forEach(function(merchant) {
          if (merchant.role == "BOSS") {
            boss = merchant;
          }
        });
      }
      return boss?.[attr];
    },
    updateStore(storeIdx){
      const vm = this;
      const { current_store } = vm
      let store_id = current_store.id;

      if (current_store.commission_rate < 0) {
        this.$toast.open({
          message: vm.commissionSmallerError,
          type: "error",
          position: "top-right",
        });
      } else if (current_store.commission_rate > 100) {
        this.$toast.open({
          message: vm.commissionBiggerError,
          type: "error",
          position: "top-right",
        });
      } else if (current_store.branch_limit <= 0) {
        vm.$toast.open({
          message: vm.branchLimitSmallerError,
          type: "error",
          position: "top-right",
        });
      } else {
        let commission_rate = current_store.commission_rate / 100;

        let formData = {
          authenticity_token: vm.token,
          store: {
            centralized_inventory: current_store.centralized_inventory,
            active: current_store.active,
            commission_rate: commission_rate,
            branch_limit: current_store.branch_limit,
            loyalty: current_store.loyalty,
          },
        };
  
        this.$http
          .put("/api/v1/admin/stores/" + store_id, formData)
          .then((response) => {
            vm.$toast.open({
              message: vm.storeUpdatedMessage,
              type: "success",
              position: "top-right",
            });
            window.location = `/${vm.locale}/admin/stores`;
          })
          .catch((error) => {
            vm.$toast.open({
              message: vm.somethingWrongMessaeg,
              type: "error",
              position: "top-right",
            });
            console.log(error);
          })
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.d-flex{
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.3rem;
  .title{
    margin-bottom: 1rem;
  }
  .data{
    margin-top: -0.2rem;
  }
}
.title{
  // color: #6c6c6c;
  font-weight: 700;
  margin-bottom: .5rem;
}
.primary-title{
  margin-bottom: .6rem;
}
.toggle {
  padding: .25rem 1rem;
  border-radius: 25px;
  box-shadow: 0 0 10px -5px rgba(0, 0, 0, 0.65);
  text-align: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;
  .dot {
    width: 10px;
    height: 10px;
    display: inline-block;
    border: 2px solid #e5e5e5;
    margin-right: 10px;
    border-radius: 50%;
  }
}
.checked {
  .dot {
    background: rgb(249,183,62);
    border: 0;
  }
}
.w-50{
  width: 50%;
}
.mb-2{
  margin-bottom: .5rem;
}

@media screen and (max-width: 500px) {
  .form-group{
    width: 100%;
  }
}
.overflow-auto{
  overflow: auto;
}
</style>