<template>
  <div>
    <div class="d-flex">
      <div style="width: 100%">
        <div class="title">{{stafflistLabel}}</div>
        <select v-show="currentMerchant.role == 'BOSS' || (action == 'update' && currentMerchant.permissions.branch.update) || (action == 'create' && currentMerchant.permissions.branch.create)" class="form-control" v-model="selected_merchant">
          <option
            v-for="(merchant, index) in merchants"
            :key="index"
            :value="merchant.id"
            :disabled="merchant.branch_id"
          >
            {{ merchant.first_name }} {{ merchant.last_name }}
            <span>({{ getRole(merchant.role) }})</span>
          </option>
        </select>
      </div>
      <div v-show="currentMerchant.role == 'BOSS' || (action == 'update' && currentMerchant.permissions.branch.update) || (action == 'create' && currentMerchant.permissions.branch.create)" class="control">
        <button type="button" class="add" @click="addMerchant">+</button>
      </div>
    </div>
    <div v-if="error != ''" class="error">{{ error }}</div>
    <div
      v-for="(merchant, index) in branch_merchants"
      :key="index"
      class="branch-merchant-wrapper"
    >
      <div class="d-flex merchant-row">
        <div class="form-group">
          <div class="title">{{staffnameLabel}}</div>
          <input
            type="text"
            class="form-control"
            :value="merchant.first_name + ' ' + merchant.last_name"
            readonly
          />
        </div>
        <div class="form-group">
          <div class="title">{{staffroleLabel}}</div>
          <input
            type="text"
            class="form-control"
            :value="getRole(merchant.role)"
            readonly
          />
        </div>
        <div class="control">
          <button v-show="currentMerchant.role == 'BOSS' || (action == 'update' && currentMerchant.permissions.branch.update && merchant.role !== 'BOSS') || (action == 'create' && currentMerchant.permissions.branch.create)" type="button" class="btn" @click="removeMerchant(index)">
            {{ removeLabel }}
          </button>
          <button v-show="currentMerchant.role != 'BOSS' && merchant.role == 'BOSS'" style="opacity: 0"></button>
        </div>
        <input
          v-if="inputType == 'create'"
          type="hidden"
          name="branch[merchant_ids][]"
          :value="merchant.id"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['action', 'merchants', 'branchMerchants', 'currentMerchant', 'token', 'branchId', 'locale', 'addMerchantSuccess', 'removeMerchantSuccess', 'staffnameLabel', 'stafflistLabel', 'staffroleLabel', 'removeLabel', 'branchMerchants', 'inputType'],
  data() {
    return {
      selected_merchant: '',
      branch_merchants: [],
      error: ''
    }
  },
  mounted() {
    if(typeof this.branchMerchants != 'undefined') {
      if(this.branchMerchants.length > 0) {
        this.branch_merchants = this.branchMerchants
      }
    }
  },
  methods: {
    addMerchant(){
      const merchant = this.getMerchant(this.selected_merchant);
      if(this.selected_merchant == '') {
        this.error = this.locale == 'en' ? 'Please select a staff in order to add' : '请选择员工先';
      }
      else if(this.branch_merchants.includes(merchant)) {
        this.error = this.locale  == 'en' ? 'Staff had already under this branch' : '此员工已是分行员工';
      }
      else {
        this.error = "";
        if(this.inputType == 'update') {
          this.apiAddMerchant(this.selected_merchant, merchant);
        }
        else {
          this.branch_merchants.push(merchant);
        }
      }
    },
    removeMerchant(index) {
      if(this.inputType == 'create') {
        this.branch_merchants.splice(index, 1);
      }
      else {
        this.apiRemoveMerchant(this.branch_merchants[index].id, index);
      }
    },
    apiAddMerchant(id, merchant) {
      let vm = this;
      this.$http.put('/api/v1/merchants/'+id, {
        authenticity_token: this.token,
        merchant: {
          branch_id: this.branchId
        }
      })
        .then(response => {
          vm.branch_merchants.push(merchant);
          vm.$toast.open({
            message: vm.addMerchantSuccess,
            type: 'success',
            position: 'top-right'
          });
        })
        .catch(error => {
          console.log(error);
        });
    },
    apiRemoveMerchant(id, index) {
      let vm = this;
      this.$http.put('/api/v1/merchants/'+id, {
        authenticity_token: this.token,
        merchant: {
          branch_id: ''
        }
      })
        .then(response => {
          vm.branch_merchants.splice(index, 1);
          vm.$toast.open({
            message: vm.removeMerchantSuccess,
            type: 'success',
            position: 'top-right'
          });
        })
        .catch(error => {
          console.log(error);
        });
    },
    getMerchant(id){
      let merchant = null
      this.merchants.forEach(function(m){
        if(m.id == id) {
          merchant = m
        }
      });
      return merchant;
    },
    getRole(role) {
      let role_name = '';
      if(this.locale == 'en') {
        if(role == 'BOSS') {
          role_name = 'OWNER'
        }
        else if(role == 'MANAGER'){
          role_name = 'MANAGER';
        }
        else {
          role_name = 'STAFF'
        }
      }
      else {
        if(role == 'BOSS') {
          role_name = '老板'
        }
        else if(role == 'MANAGER'){
          role_name = ' 经理';
        }
        else {
          role_name = '员工'
        }
      }
      return role_name;
    }
  },
}
</script>

<style scoped>
.d-flex {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.add {
  font-size: 30px;
  background: none;
  border: 0;
  line-height: 1.5;
  transition: all 0.3s;
  width: 40px;
}
.add:hover {
  cursor: pointer;
  color: #f9b73e;
}
.branch-merchant-wrapper {
  margin: 2rem 0;
}
.merchant-row .form-group {
  width: calc(50% - 75px) !important;
  margin-bottom: 0;
}
.merchant-row .form-control {
  background: #e9e9e9;
}
.merchant-row button {
  background: rgba(249, 183, 62, 0.1);
  color: #f9b73e;
  border: 0;
  width: 100px;
  padding: 1rem;
  transition: all 0.3s;
  border-radius: 6px;
}
.merchant-row button:hover {
  box-shadow: 0 0.5em 0.5em -0.4em grey;
  transform: translateY(-0.25em);
}
.w-100-px{
  width: 100px !important;
}
</style>
