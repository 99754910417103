<template>
  <div>
    <div class="form-group">
        <div class="title">{{ discountByLabel }}</div>
        <select name="discount_code[discount_by]" class="form-control" v-model="discount_by" required>
            <option value="PERCENT">{{ locale == 'cn' ? '百分比' : 'Percentage' }}</option>
            <option value="AMOUNT">{{ locale == 'cn' ? '固定金额' : 'Fix Amount' }}</option>
        </select>
    </div>

    <div v-if="discount_by == 'AMOUNT'" class="form-group">
        <div class="title">{{ discountAmountLabel }}</div>
        <input type="number" step="0.00000001" min="0.00000001" name="discount_code[discount_amount]" class="form-control" v-model="discount_amount" required>
    </div>

    <div v-if="discount_by == 'PERCENT'" class="form-group">
        <div class="title">{{  discountPercentLabel }}</div>
        <input type="number" min="1" name="discount_code[discount_percentage]" class="form-control" v-model="discount_percentage" required>
    </div>
  </div>
</template>
    

<script>
  export default {
    props: ['discountByLabel', 'discountAmountLabel', 'discountPercentLabel', 'discountCode', 'locale'],
    data() {
      return {
        discount_by: 'PERCENT',
        discount_amount: 0,
        discount_percentage: 0
      }
    },
    computed: {},
    mounted() {
      if(typeof this.discountCode != 'undefined') {
        this.discount_by = this.discountCode.discount_by;
        this.discount_amount = Number(this.discountCode.discount_amount_satoshi/ 100000000).toFixed(8);
        this.discount_percentage = this.discountCode.discount_percentage;
      }
    },
    methods: {},
  }
</script>