<template>
  <div class="form-group" v-show="role != 'BOSS'">
    <div class="flex">
      <div v-show="!isModelHide(model)" v-for="(actionObj, model) in permission" :key="model" class="col">
        <h3>
          <input
            type="checkbox"
            @change="checkAll(model)"
            :checked="all[model]"
            :id="`${model}-all`"
          />{{ renameModelAction(model) }}
        </h3>
        <ol>
          <li v-show="!isActionHide(model, action)" v-for="(variable, action) in actionObj" :key="action">
            <input
              @change="check(model, action, variable)"
              type="checkbox"
              :checked="variable"
              :id="`${model}-${action}`"
            />
            <label :for="`${model}-${action}`">{{ renameModelAction(action) }}</label>
            <input
              type="hidden"
              :name="`merchant[permissions][${model}][${action}]`"
              :value="variable"
            />
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["merchantPermission", "role"],
  data() {
    return {
      all: {
        cart: false,
        order: false,
        store: false,
        branch: false,
        wallet: false,
        loyalty: false,
        product: false,
        category: false,
        merchant: false,
        discount_code: false,
        direct_payment: false,
        branch_inventory: false,
        conversion_request: false,
        withdrawal_request: false,
        inventory_transaction: false,
      },
      permission: {
        cart: {
          read: false,
          create: false,
          update: false,
        },
        order: {
          list: false,
          read: false,
          create: false,
          export: false,
          update: false,
          report: false,
        },
        store: {
          read: false,
          submit: false,
          update: false,
          item_locations: false,
          connect_loyalty: false,
          loyalty_sources: false,
          connect_inventory: false,
        },
        branch: {
          read: false,
          create: false,
          update: false,
          destroy: false,
          sync_branch_inventories: false,
        },
        wallet: {
          read: false,
          send_coin: false,
        },
        loyalty: {
          read: false,
        },
        product: {
          read: false,
          create: false,
          update: false,
          destroy: false,
          sync_products: false,
        },
        category: {
          read: false,
          create: false,
          update: false,
          destroy: false,
          sync_categories: false,
        },
        merchant: {
          read: false,
          create: false,
          update: false,
          destroy: false,
        },
        discount_code: {
          read: false,
          create: false,
          update: false,
          destroy: false,
        },
        direct_payment: {
          list: false,
          read: false,
          create: false,
          update: false,
          destroy: false,
          report: false,
        },
        branch_inventory: {
          read: false,
          create: false,
          delete: false,
          update: false,
        },
        conversion_request: {
          read: false,
          create: false,
        },
        withdrawal_request: {
          read: false,
          create: false,
          setting: false,
        },
        inventory_transaction: {
          read: false,
          create: false,
          update: false,
          destroy: false,
        },
      },
    };
  },
  mounted() {
    if (
      typeof this.merchantPermission != "undefined" &&
      this.merchantPermission != ""
    ) {
      this.permission = this.merchantPermission;
    }
  },
  methods: {
    check: function (model, action, ischecked) {
      this.permission[model][action] = !ischecked;
    },
    checkAll: function (model) {
      this.all[model] = !this.all[model];
      for (var key in this.permission[model]) {
        this.permission[model][key] = this.all[model];
      }
    },
    isActionHide: function (model, action) {
      if (
        (model == "order" || model == "direct_payment") &&
        (action == "update" || action == "destroy")
      ) {
        return true;
      }

      if (
        action == 'list' ||
        action == "sync_branch_inventories" ||
        action == "sync_products" ||
        action == "sync_categories" ||
        action == "item_locations" ||
        action == "connect_loyalty" ||
        action == "loyalty_sources" ||
        action == "connect_inventory"
      ) {
        return true;
      }

      return false;
    },
    isModelHide: function (model) {
      if (model == "loyalty") {
        return true;
      }

      return false;
    },
    renameModelAction: function (name) {
      if(name == 'merchant') {
        return 'Staff'
      }

      if(name == 'destroy') {
        return 'Delete'
      }

      if(name == 'store') {
        return 'Profile'
      }

      let sentence = name.replace(/_/g," ");
      let words = sentence.split(" ")
      for (let i = 0; i < words.length; i++) {
          words[i] = words[i][0].toUpperCase() + words[i].substr(1);
      }
      return words.join(" ");
    }
  },
};
</script>

<style scoped>
h3 {
  margin-bottom: 0px;
}
ol {
  margin-top: 5px;
  list-style: none;
  padding-left: 0px;
}
.flex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.col {
  width: 30%;
}
input[type="checkbox"] {
  margin-right: 10px;
}
</style>