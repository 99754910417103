<template>
  <div style="padding-top: 3rem">
    <div class="form-group">
      <div class="title">{{ selectWalletLabel }}</div>
      <select class="form-control" v-model="selected_wallet_index">
        <option v-for="(wallet, index) in wallets" :key="index" :value="index">
          {{ wallet.currency }}
        </option>
      </select>
      <p
        class="withdraw-balance"
        v-if="
          (wallet_currency == 'PPX' && ppxAction == 'withdrawal') ||
          wallet_currency == 'AMYR'
        "
      >
        <span>
          {{ wallet_currency }} {{ feeLabel }}: {{ ppxFee }}%
          <br />
          <!-- {{ availableWithdrawLabel }}: {{ available_withdraw_amount }} -->
          {{ minWithdrawalLabel }}: {{ min_withdraw_amount }}
          {{ wallet_currency }}
        </span>
        <b v-if="!call_api_balance">{{ balanceLabel }}: {{ wallet_balance }}</b>
      </p>
      <p
        class="withdraw-balance"
        v-else-if="wallet_currency && wallet_currency != 'PPX'"
      >
        <span>
          {{ wallet_currency }} {{ blockchainFeeLabel }}: {{ blockchain_fee }}
          <br />
          {{ availableWithdrawLabel }}: {{ available_withdraw_amount }}
        </span>
        <b v-if="!call_api_balance">{{ balanceLabel }}: {{ wallet_balance }}</b>
      </p>
    </div>
    <div
      v-if="wallets[selected_wallet_index].currency == 'PPX' && type != 'admin'"
    >
      <div class="tag-wrapper mb-2">
        <div
          v-show="currentMerchant && (currentMerchant.role == 'BOSS' || currentMerchant.permissions.conversion_request.create)"
          @click="ppxAction = 'convert'"
          :class="`tag ${ppxAction == 'convert' ? 'primary' : ''}`"
        >
          {{ conversionLabel }}
        </div>
        <div
          v-show="currentMerchant && (currentMerchant.role == 'BOSS' || currentMerchant.permissions.wallet.send_coin)"
          @click="ppxAction = 'withdrawal'"
          :class="`tag ${ppxAction == 'withdrawal' ? 'primary' : ''}`"
        >
          {{ withdrawalLabel }}
        </div>
      </div>
    </div>
    <div
      v-if="
        ppxAction == 'convert' &&
        wallets[selected_wallet_index].currency == 'PPX'
      "
    >
      <p>{{ convertPromptLabel }}</p>
      <p class="title" style="margin-bottom: 1rem">
        {{ amountToConvertLabel }}
      </p>
      <div class="two-col-input">
        <div class="form-group relative">
          <input
            type="number"
            min="0.00000001"
            step="0.00000001"
            class="form-control"
            v-model="amountToCovert"
          />
          <span class="absolute-currency">PPX</span>
        </div>
        <div class="form-group relative">
          <input
            type="text"
            class="form-control"
            :value="(amountToCovert * ppxToMyrRate).toFixed(2)"
            disabled="true"
          />
          <span class="absolute-currency">AMYR</span>
        </div>
      </div>
      <p style="margin: 1rem 0">
        {{ conversionLabel }}: 1PPX = {{ 1 * ppxToMyrRate }} AMYR
      </p>
      <p v-if="!call_api_balance">{{ balanceLabel }}: {{ wallet_balance }}</p>
      <p v-if="error_message" class="text-danger" style="margin-bottom: 1.5rem">
        {{ error_message }}
      </p>
      <div
        v-if="converting"
        class="btn btn-primary btn-block"
        style="padding: 18px"
      >
        <span class="loader loading"></span>
      </div>
      <div
        v-else
        @click="convert"
        class="btn btn-primary btn-block"
        style="padding: 1rem"
      >
        {{ convertLabel }}
      </div>
    </div>
    <div
      v-if="
        (ppxAction == 'withdrawal' &&
          wallets[selected_wallet_index].currency == 'PPX') ||
        wallets[selected_wallet_index].currency != 'PPX'
      "
    >
      <div class="form-group">
        <div class="title">{{ recipientAddressLabel }}</div>
        <input type="text" class="form-control" v-model="recipient_address" />
      </div>
      <div class="form-group" style="margin-bottom: 1rem">
        <div class="title">{{ amountLabel }}</div>
        <input
          type="number"
          class="form-control"
          v-model="amount"
          step="0.00000001"
          min="0.00000001"
        />
      </div>
      <p v-if="error_message" class="text-danger" style="margin-bottom: 1.5rem">
        {{ error_message }}
      </p>
      <div
        v-if="withdrawing"
        class="btn btn-primary btn-block"
        style="padding: 18px"
      >
        <span class="loader loading"></span>
      </div>
      <div
        v-else
        @click="withdraw"
        class="btn btn-primary btn-block"
        style="padding: 1rem"
      >
        {{ withdrawLabel }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "currentMerchant",
    "wallets",
    "token",
    "type",
    "selectWalletLabel",
    "blockchainFeeLabel",
    "availableWithdrawLabel",
    "balanceLabel",
    "recipientAddressLabel",
    "amountLabel",
    "withdrawLabel",
    "conversionLabel",
    "withdrawalLabel",
    "amountToConvertLabel",
    "convertLabel",
    "conversationMessage",
    "ppxToMyrRate",
    "locale",
    "convertPromptLabel",
    "blockchainFees",
    "feeLabel",
    "ppxFee",
    "amyrFee",
    "minWithdrawalLabel",
    "walletApi",
  ],
  data() {
    return {
      balance: null,
      withdrawing: false,
      converting: false,
      call_api_balance: true,
      selected_wallet_index: 0,
      wallet_id: null,
      wallet_balance: null,
      wallet_aaddress: null,
      wallet_currency: null,
      blockchain_fee: null,
      recipient_address: null,
      error_message: "",
      amount: null,
      available_withdraw_amount: 0,
      ppxAction: "withdrawal",
      amountToCovert: 0,
      apiBlockchain_fee: {},
      min_withdraw_amount: 10,
    };
  },
  computed: {},
  watch: {
    selected_wallet_index: function (val) {
      this.wallet_id = this.wallets[val].id;
      this.wallet_address = this.wallets[val].address;
      this.wallet_currency = this.wallets[val].currency;
      this.getBlockChainFee();
      this.getWalletDetail();
    },
    wallet_balance: function (val) {
      this.getBlockChainFee();
      this.verify_balance_is_enough(val);
    },
    amount: function (val) {
      this.getBlockChainFee();
      this.verify_balance_is_enough(val);
    },
    amountToCovert: function (val) {
      this.getBlockChainFee();
      this.verify_balance_is_enough(val);
    },
  },
  mounted() {
    if (typeof this.wallets != "undefined") {
      this.wallet_id = this.wallets[0].id;
      this.wallet_address = this.wallets[0].address;
      this.wallet_currency = this.wallets[0].currency;
      this.getBlockChainFee();
      this.getWalletDetail();

      if(this.currentMerchant && this.currentMerchant.role != 'BOSS' && !this.currentMerchant.permissions.wallet.send_coin ) {
        this.wallets = this.wallets.filter(wallet => wallet.currency == 'PPX')
      }
    }
  },
  methods: {
    getBlockChainFees() {
      let vm = this;
      this.wallets.forEach(function (wallet) {
        vm.$http
          .get(
            `${vm.walletApi}/transactions/fee?symbol=${wallet.currency}&amount=1`
          )
          .then((response) => {
            vm.apiBlockchain_fee[wallet.currency] = response.data.fee;

            if (wallet.currency == vm.wallet_currency) {
              vm.blockchain_fee = response.data.fee;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    getBlockChainFee() {
      let vm = this;
      if (this.wallet_currency == "AMYR" || this.wallet_currency == "PPX") {
        vm.blockchain_fee = 0;
      } else {
        this.$http
          .get(
            `${this.walletApi}/transactions/fee?symbol=${this.wallet_currency}&amount=${this.amount}`
          )
          .then((response) => {
            if (response.data && response.data.fee) {
              vm.blockchain_fee = response.data.fee;
            } else {
              vm.blockchain_fee = 0;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    getWalletDetail() {
      let vm = this;
      this.call_api_balance = true;
      this.$http
        .get(
          this.walletApi +
            "/coin/" +
            this.wallet_address +
            "?pagesize=10&pageindex=1"
        )
        .then((response) => {
          vm.wallet_balance = response.data.account.amount;
          if (vm.wallet_balance > vm.blockchain_fee) {
            vm.amount = vm.wallet_balance - vm.blockchain_fee;
            vm.available_withdraw_amount = vm.amount;
          } else {
            vm.amount = 0;
            vm.available_withdraw_amount = 0;
          }
          vm.call_api_balance = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    verify_balance_is_enough(amount) {
      if (amount) {
        if (this.wallet_balance == 0) {
          this.error_message =
            this.locale == "cn"
              ? "钱包余额不足"
              : "Wallet balance is not enought to withdraw";
          this.available_withdraw_amount = 0;
        } else {
          var balance = this.wallet_balance * 100000000;
          var amount = amount * 100000000;
          var blockchain_fee = this.blockchain_fee * 100000000;
          this.available_withdraw_amount = Number(
            (balance - blockchain_fee) / 100000000
          ).toFixed(8);
          var withdraw_amount = Number(
            (balance - (amount + blockchain_fee)) / 100000000
          ).toFixed(8);

          if (amount && withdraw_amount >= 0) {
            this.error_message = "";
          } else {
            this.error_message =
              this.locale == "cn"
                ? "钱包余额不足"
                : "Wallet balance is not enought to withdraw";
          }

          if (this.wallet_currency == "PPX" || this.wallet_currency == "AMYR") {
            if (withdraw_amount < 0) {
              this.error_message =
                this.locale == "cn"
                  ? "钱包余额不足"
                  : "Wallet balance is not enought to withdraw";
            } else if (this.amount < this.min_withdraw_amount) {
              this.error_message =
                this.locale == "cn"
                  ? `最低提款额是${this.min_withdraw_amount}${this.wallet_currency}`
                  : `Minimum withdrawal amount is ${this.min_withdraw_amount}${this.wallet_currency}`;
            } else {
              this.error_message = "";
            }
          }
        }
      }
    },
    validation() {
      var error_found = false;
      if (!this.recipient_address || this.recipient_address == "") {
        error_found = true;
        this.$toast.open({
          message:
            this.locale == "en"
              ? "Recipient address should not be empty"
              : "收件人地址不能为空",
          type: "error",
          position: "top-right",
        });
      }

      if ((!this.amount && this.amount == "") || this.amount <= 0) {
        error_found = true;
        this.$toast.open({
          message:
            this.locale == "en"
              ? "Withdraw amount should not be empty & should not be lower then 0"
              : "提款金额不能为空且不能小于0",
          type: "error",
          position: "top-right",
        });
      }

      if (this.error_message != "") {
        error_found = true;
        this.$toast.open({
          message: this.error_message,
          type: "error",
          position: "top-right",
        });
      }
      return !error_found;
    },
    convertValidation() {
      var error_found = false;
      if (
        (!this.amountToCovert && this.amountToCovert == "") ||
        this.amountToCovert <= 0
      ) {
        error_found = true;
        this.$toast.open({
          message:
            this.locale == "en"
              ? "Convert amount should not be empty & should not be lower then 0"
              : "兑换金额不能为空且不能小于0",
          type: "error",
          position: "top-right",
        });
      }

      if (this.error_message != "") {
        error_found = true;
        this.$toast.open({
          message: this.error_message,
          type: "error",
          position: "top-right",
        });
      }
      return !error_found;
    },
    withdraw() {
      if (this.validation()) {
        let vm = this;
        let formData = {
          transaction: {
            recipient_address: this.recipient_address,
            amount: this.amount,
          },
          authenticity_token: this.token,
        };
        if (!this.withdrawing && !this.converting) {
          vm.withdrawing = true;
          this.$http
            .post(
              "/api/v1/" +
                (this.type == "admin" ? "admin/" : "") +
                "wallets/" +
                this.wallet_id +
                "/send_coin",
              formData
            )
            .then((response) => {
              vm.$toast.open({
                message: vm.locale == "en" ? "Withdraw successful" : "提款成功",
                type: "success",
                position: "top-right",
              });
              window.location.reload();
            })
            .catch((error) => {
              vm.withdrawing = false;
              vm.$toast.open({
                message:
                  vm.locale == "en"
                    ? "Something wrong"
                    : "不好意思，系统出问题",
                type: "error",
                position: "top-right",
              });
              console.log(error);
            });
        }
      }
    },
    convert() {
      if (this.convertValidation()) {
        let vm = this;
        let formData = {
          conversion_request: {
            wallet_id: this.wallet_id,
            amount: this.amountToCovert,
          },
          authenticity_token: this.token,
        };
        if (!this.converting && !this.withdrawing) {
          vm.converting = true;
          // to do fix
          this.$http
            .post("/api/v1/conversion_requests", formData)
            .then((response) => {
              vm.$toast.open({
                message:
                  vm.locale == "en"
                    ? "Convert request submitted successful"
                    : "兑换申请成功",
                type: "success",
                position: "top-right",
              });
              window.location.reload();
            })
            .catch((error) => {
              vm.converting = false;
              vm.$toast.open({
                message:
                  vm.locale == "en"
                    ? "Something wrong"
                    : "不好意思，系统出问题",
                type: "error",
                position: "top-right",
              });
              console.log(error);
            });
        }
      }
    },
  },
};
</script>

<style scoped>
.text-danger {
  margin-bottom: 0.5rem;
}
.relative {
  position: relative;
}
.absolute-currency {
  position: absolute;
  right: 0.5rem;
  top: 0.75rem;
}
</style>