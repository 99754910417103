<template>
  <div class="two-column-content">
    <div class="sub-column sidebar">
      <div class="sidebar-tab overflow md-scrollbar">
        <div
          :class="`tab ${step == 0 ? 'active' : ''}`"
          @click="step = step > 0 || readonly ? 0 : step"
        >
          {{ generalInformationLabel }}
        </div>
        <div
          :class="`tab ${
            step == 1 ? 'active' : isGeneralFilled ? '' : 'disable'
          }`"
          @click="step = isGeneralFilled ? 1 : step"
        >
          {{ bankingInformationLabel }}
        </div>
        <div
          :class="`tab ${
            step == 2
              ? 'active'
              : isGeneralFilled && isBankFilled
              ? ''
              : 'disable'
          }`"
          @click="step = isGeneralFilled && isBankFilled ? 2 : step"
        >
          {{ contactInformationLabel }}
        </div>
        <div
          :class="`tab ${
            step == 3
              ? 'active'
              : isGeneralFilled && isBankFilled && isContachFilled
              ? ''
              : 'disable'
          }`"
          @click="
            step = isGeneralFilled && isBankFilled && isContachFilled ? 3 : step
          "
        >
          {{ attachmentLabel }}
        </div>
      </div>
    </div>
    <form
      :class="`main-column with-sidebar ${readonly ? 'readonly' : ''}`"
      :action="`/${locale}/store`"
      method="post"
      enctype="multipart/form-data"
      @submit.prevent="validation"
    >
      <div id="flashes">
        <div v-if="storeRejectMessage && user.store_info_status == 'rejected'" id="flashes">
          <div class="alert alert-danger alert-dismissibele fade show pr-4 mb-4" role="alert">
            <h3><b>{{rejectMessageLabel}}</b></h3>
            <p>{{storeRejectMessage}}</p>
          </div>
        </div>
      </div>
      <input type="hidden" name="authenticity_token" :value="token" />
      <input type="hidden" name="_method" value="put" />
      <div
        :class="`card ${step == 0 ? 'show' : 'hide'}`"
        style="margin-bottom: 2rem"
      >
        <div class="card-header flex-header">
          <h2 class="primary-title">{{ generalInformationLabel }}</h2>
        </div>
        <div class="card-body">
          <div class="two-col-input">
            <div class="form-group">
              <div class="title">{{ companyNameLabel }}*</div>
              <input
                type="text"
                name="store[name]"
                class="form-control"
                v-model="company_name"
                :required="step == 0"
                :disabled="readonly"
              />
            </div>
            <div class="form-group">
              <div class="title">{{ registrationNumberLabel }}*</div>
              <input
                type="text"
                name="store[registration_number]"
                class="form-control"
                v-model="registration_number"
                :required="step == 0"
                :disabled="readonly"
              />
            </div>
          </div>
          <div class="form-group">
            <div class="title">{{ businessNatureLabel }}*</div>
            <select
              name="store[business_nature]"
              class="form-control"
              v-model="business_nature"
              :required="step == 0"
              :disabled="readonly"
            >
              <option value="" disabled>
                {{
                  locale == "cn"
                    ? "选择您的业务性质"
                    : "Select your business nature"
                }}
              </option>
              <option value="47">
                {{ locale == "cn" ? "会计" : "Accounting" }}
              </option>
              <option value="94">
                {{ locale == "cn" ? "航空公司/航空" : "Airlines/Aviation" }}
              </option>
              <option value="120">
                {{
                  locale == "cn"
                    ? "替代性纠纷解决"
                    : "Alternative Dispute Resolution"
                }}
              </option>
              <option value="125">
                {{ locale == "cn" ? "替代药物" : "Alternative Medicine" }}
              </option>
              <option value="127">
                {{ locale == "cn" ? "动画片" : "Animation" }}
              </option>
              <option value="19">
                {{ locale == "cn" ? "服装与时尚" : "Apparel & Fashion" }}
              </option>
              <option value="50">
                {{ locale == "cn" ? "建筑与规划" : "Architecture & Planning" }}
              </option>
              <option value="111">
                {{ locale == "cn" ? "美术和工艺" : "Arts and Crafts" }}
              </option>
              <option value="53">
                {{ locale == "cn" ? "汽车" : "Automotive" }}
              </option>
              <option value="52">
                {{ locale == "cn" ? "航空航天" : "Aviation & Aerospace" }}
              </option>
              <option value="41">
                {{ locale == "cn" ? "银行业" : "Banking" }}
              </option>
              <option value="12">
                {{ locale == "cn" ? "生物技术" : "Biotechnology" }}
              </option>
              <option value="36">
                {{ locale == "cn" ? "广播媒体" : "Broadcast Media" }}
              </option>
              <option value="49">
                {{ locale == "cn" ? "建筑材料" : "Building Materials" }}
              </option>
              <option value="138">
                {{
                  locale == "cn"
                    ? "商业用品和设备"
                    : "Business Supplies and Equipment"
                }}
              </option>
              <option value="129">
                {{ locale == "cn" ? "资本市场" : "Capital Markets" }}
              </option>
              <option value="54">
                {{ locale == "cn" ? "化学品" : "Chemicals" }}
              </option>
              <option value="90">
                {{
                  locale == "cn"
                    ? "公民和社会组织"
                    : "Civic & Social Organization"
                }}
              </option>
              <option value="51">
                {{ locale == "cn" ? "土木工程" : "Civil Engineering" }}
              </option>
              <option value="128">
                {{ locale == "cn" ? "商业房地产" : "Commercial Real Estate" }}
              </option>
              <option value="118">
                {{
                  locale == "cn"
                    ? "计算机与网络安全"
                    : "Computer & Network Security"
                }}
              </option>
              <option value="109">
                {{ locale == "cn" ? "电脑游戏" : "Computer Games" }}
              </option>
              <option value="3">
                {{ locale == "cn" ? "电脑硬件" : "Computer Hardware" }}
              </option>
              <option value="5">
                {{ locale == "cn" ? "计算机网络" : "Computer Networking" }}
              </option>
              <option value="4">
                {{ locale == "cn" ? "计算机软件" : "Computer Software" }}
              </option>
              <option value="48">
                {{ locale == "cn" ? "建造" : "Construction" }}
              </option>
              <option value="24">
                {{ locale == "cn" ? "消费类电子产品" : "Consumer Electronics" }}
              </option>
              <option value="25">
                {{ locale == "cn" ? "消费品" : "Consumer Goods" }}
              </option>
              <option value="91">
                {{ locale == "cn" ? "消费服务" : "Consumer Services" }}
              </option>
              <option value="18">
                {{ locale == "cn" ? "化妆品" : "Cosmetics" }}
              </option>
              <option value="65">
                {{ locale == "cn" ? "乳制品" : "Dairy" }}
              </option>
              <option value="1">
                {{ locale == "cn" ? "国防与太空" : "Defense & Space" }}
              </option>
              <option value="99">
                {{ locale == "cn" ? "设计" : "Design" }}
              </option>
              <option value="69">
                {{ locale == "cn" ? "教育管理" : "Education Management" }}
              </option>
              <option value="132">
                {{ locale == "cn" ? "电子学习" : "E-Learning" }}
              </option>
              <option value="112">
                {{
                  locale == "cn"
                    ? "电气/电子制造"
                    : "Electrical/Electronic Manufacturing"
                }}
              </option>
              <option value="28">
                {{ locale == "cn" ? "娱乐" : "Entertainment" }}
              </option>
              <option value="86">
                {{ locale == "cn" ? "环境服务" : "Environmental Services" }}
              </option>
              <option value="110">
                {{ locale == "cn" ? "活动服务" : "Events Services" }}
              </option>
              <option value="76">
                {{ locale == "cn" ? "行政办公室" : "Executive Office" }}
              </option>
              <option value="122">
                {{ locale == "cn" ? "设施服务" : "Facilities Services" }}
              </option>
              <option value="63">
                {{ locale == "cn" ? "农业" : "Farming" }}
              </option>
              <option value="43">
                {{ locale == "cn" ? "金融服务" : "Financial Services" }}
              </option>
              <option value="38">
                {{ locale == "cn" ? "美术" : "Fine Art" }}
              </option>
              <option value="66">
                {{ locale == "cn" ? "渔业" : "Fishery" }}
              </option>
              <option value="34">
                {{ locale == "cn" ? "食品和饮料" : "Food & Beverages" }}
              </option>
              <option value="23">
                {{ locale == "cn" ? "食品生产" : "Food Production" }}
              </option>
              <option value="101">
                {{ locale == "cn" ? "筹款" : "Fund-Raising" }}
              </option>
              <option value="26">
                {{ locale == "cn" ? "家具" : "Furniture" }}
              </option>
              <option value="29">
                {{ locale == "cn" ? "赌博和赌场" : "Gambling & Casinos" }}
              </option>
              <option value="145">
                {{
                  locale == "cn"
                    ? "玻璃、陶瓷和混凝土"
                    : "Glass, Ceramics & Concrete"
                }}
              </option>
              <option value="75">
                {{ locale == "cn" ? "政府管理" : "Government Administration" }}
              </option>
              <option value="148">
                {{ locale == "cn" ? "政府关系" : "Government Relations" }}
              </option>
              <option value="140">
                {{ locale == "cn" ? "平面设计" : "Graphic Design" }}
              </option>
              <option value="124">
                {{
                  locale == "cn"
                    ? "健康、保健和健身"
                    : "Health, Wellness and Fitness"
                }}
              </option>
              <option value="68">
                {{ locale == "cn" ? "高等教育" : "Higher Education" }}
              </option>
              <option value="14">
                {{
                  locale == "cn" ? "医院和医疗保健" : "Hospital & Health Care"
                }}
              </option>
              <option value="31">
                {{ locale == "cn" ? "热情好客" : "Hospitality" }}
              </option>
              <option value="137">
                {{ locale == "cn" ? "人力资源" : "Human Resources" }}
              </option>
              <option value="134">
                {{ locale == "cn" ? "进出口" : "Import and Export" }}
              </option>
              <option value="88">
                {{
                  locale == "cn"
                    ? "个人及家庭服务"
                    : "Individual & Family Services"
                }}
              </option>
              <option value="147">
                {{ locale == "cn" ? "工业自动化" : "Industrial Automation" }}
              </option>
              <option value="84">
                {{ locale == "cn" ? "信息服务" : "Information Services" }}
              </option>
              <option value="96">
                {{
                  locale == "cn"
                    ? "信息技术和服务"
                    : "Information Technology and Services"
                }}
              </option>
              <option value="42">
                {{ locale == "cn" ? "保险" : "Insurance" }}
              </option>
              <option value="74">
                {{ locale == "cn" ? "国际事务" : "International Affairs" }}
              </option>
              <option value="141">
                {{
                  locale == "cn"
                    ? "国际贸易与发展"
                    : "International Trade and Development"
                }}
              </option>
              <option value="6">
                {{ locale == "cn" ? "互联网" : "Internet" }}
              </option>
              <option value="45">
                {{ locale == "cn" ? "投资银行" : "Investment Banking" }}
              </option>
              <option value="46">
                {{ locale == "cn" ? "投资管理" : "Investment Management" }}
              </option>
              <option value="73">
                {{ locale == "cn" ? "司法" : "Judiciary" }}
              </option>
              <option value="77">
                {{ locale == "cn" ? "执法" : "Law Enforcement" }}
              </option>
              <option value="9">
                {{ locale == "cn" ? "法律实践" : "Law Practice" }}
              </option>
              <option value="10">
                {{ locale == "cn" ? "法律服务" : "Legal Services" }}
              </option>
              <option value="72">
                {{ locale == "cn" ? "立法局" : "Legislative Office" }}
              </option>
              <option value="30">
                {{
                  locale == "cn"
                    ? "休闲、旅行和旅游"
                    : "Leisure, Travel & Tourism"
                }}
              </option>
              <option value="85">
                {{ locale == "cn" ? "图书馆" : "Libraries" }}
              </option>
              <option value="116">
                {{
                  locale == "cn" ? "物流与供应链" : "Logistics and Supply Chain"
                }}
              </option>
              <option value="143">
                {{ locale == "cn" ? "奢侈品和珠宝" : "Luxury Goods & Jewelry" }}
              </option>
              <option value="55">
                {{ locale == "cn" ? "机械" : "Machinery" }}
              </option>
              <option value="11">
                {{ locale == "cn" ? "管理咨询" : "Management Consulting" }}
              </option>
              <option value="95">
                {{ locale == "cn" ? "海上" : "Maritime" }}
              </option>
              <option value="80">
                {{
                  locale == "cn"
                    ? "市场营销和广告"
                    : "Marketing and Advertising"
                }}
              </option>
              <option value="97">
                {{ locale == "cn" ? "市场调查" : "Market Research" }}
              </option>
              <option value="135">
                {{
                  locale == "cn"
                    ? "机械或工业工程"
                    : "Mechanical or Industrial Engineering"
                }}
              </option>
              <option value="126">
                {{ locale == "cn" ? "媒体制作" : "Media Production" }}
              </option>
              <option value="17">
                {{ locale == "cn" ? "医疗设备" : "Medical Devices" }}
              </option>
              <option value="13">
                {{ locale == "cn" ? "医疗实践" : "Medical Practice" }}
              </option>
              <option value="139">
                {{ locale == "cn" ? "心理保健" : "Mental Health Care" }}
              </option>
              <option value="71">
                {{ locale == "cn" ? "军队" : "Military" }}
              </option>
              <option value="56">
                {{ locale == "cn" ? "采矿和金属" : "Mining & Metals" }}
              </option>
              <option value="35">
                {{ locale == "cn" ? "电影和电影" : "Motion Pictures and Film" }}
              </option>
              <option value="37">
                {{
                  locale == "cn" ? "博物馆和机构" : "Museums and Institutions"
                }}
              </option>
              <option value="115">
                {{ locale == "cn" ? "音乐" : "Music" }}
              </option>
              <option value="114">
                {{ locale == "cn" ? "纳米技术" : "Nanotechnology" }}
              </option>
              <option value="81">
                {{ locale == "cn" ? "报纸" : "Newspapers" }}
              </option>
              <option value="100">
                {{
                  locale == "cn"
                    ? "非营利组织管理"
                    : "Nonprofit Organization Management"
                }}
              </option>
              <option value="57">
                {{ locale == "cn" ? "石油与能源" : "Oil & Energy" }}
              </option>
              <option value="113">
                {{ locale == "cn" ? "网络媒体" : "Online Media" }}
              </option>
              <option value="123">
                {{ locale == "cn" ? "外包/离岸" : "Outsourcing/Offshoring" }}
              </option>
              <option value="87">
                {{ locale == "cn" ? "包裹/货运" : "Package/Freight Delivery" }}
              </option>
              <option value="146">
                {{ locale == "cn" ? "包装和容器" : "Packaging and Containers" }}
              </option>
              <option value="61">
                {{ locale == "cn" ? "纸和林产品" : "Paper & Forest Products" }}
              </option>
              <option value="39">
                {{ locale == "cn" ? "表演艺术" : "Performing Arts" }}
              </option>
              <option value="15">
                {{ locale == "cn" ? "制药" : "Pharmaceuticals" }}
              </option>
              <option value="131">
                {{ locale == "cn" ? "慈善事业" : "Philanthropy" }}
              </option>
              <option value="136">
                {{ locale == "cn" ? "摄影" : "Photography" }}
              </option>
              <option value="117">
                {{ locale == "cn" ? "塑料" : "Plastics" }}
              </option>
              <option value="107">
                {{ locale == "cn" ? "政治组织" : "Political Organization" }}
              </option>
              <option value="67">
                {{
                  locale == "cn"
                    ? "小学/中学教育"
                    : "Primary/Secondary Education"
                }}
              </option>
              <option value="83">
                {{ locale == "cn" ? "印刷" : "Printing" }}
              </option>
              <option value="105">
                {{
                  locale == "cn"
                    ? "专业培训与辅导"
                    : "Professional Training & Coaching"
                }}
              </option>
              <option value="102">
                {{ locale == "cn" ? "程序开发" : "Program Development" }}
              </option>
              <option value="79">
                {{ locale == "cn" ? "公共政策" : "Public Policy" }}
              </option>
              <option value="98">
                {{
                  locale == "cn"
                    ? "公共关系和传播"
                    : "Public Relations and Communications"
                }}
              </option>
              <option value="78">
                {{ locale == "cn" ? "公共安全" : "Public Safety" }}
              </option>
              <option value="82">
                {{ locale == "cn" ? "出版" : "Publishing" }}
              </option>
              <option value="62">
                {{ locale == "cn" ? "铁路制造" : "Railroad Manufacture" }}
              </option>
              <option value="64">
                {{ locale == "cn" ? "牧场" : "Ranching" }}
              </option>
              <option value="44">
                {{ locale == "cn" ? "房地产" : "Real Estate" }}
              </option>
              <option value="40">
                {{
                  locale == "cn"
                    ? "康乐设施和服务"
                    : "Recreational Facilities and Services"
                }}
              </option>
              <option value="89">
                {{ locale == "cn" ? "宗教机构" : "Religious Institutions" }}
              </option>
              <option value="144">
                {{
                  locale == "cn"
                    ? "可再生能源与环境"
                    : "Renewables & Environment"
                }}
              </option>
              <option value="70">
                {{ locale == "cn" ? "研究" : "Research" }}
              </option>
              <option value="32">
                {{ locale == "cn" ? "餐厅" : "Restaurants" }}
              </option>
              <option value="27">
                {{ locale == "cn" ? "零售" : "Retail" }}
              </option>
              <option value="121">
                {{
                  locale == "cn" ? "安全和调查" : "Security and Investigations"
                }}
              </option>
              <option value="7">
                {{ locale == "cn" ? "半导体" : "Semiconductors" }}
              </option>
              <option value="58">
                {{ locale == "cn" ? "造船" : "Shipbuilding" }}
              </option>
              <option value="20">
                {{ locale == "cn" ? "体育用品" : "Sporting Goods" }}
              </option>
              <option value="33">
                {{ locale == "cn" ? "运动" : "Sports" }}
              </option>
              <option value="104">
                {{
                  locale == "cn" ? "人员配备和招聘" : "Staffing and Recruiting"
                }}
              </option>
              <option value="22">
                {{ locale == "cn" ? "超级市场" : "Supermarkets" }}
              </option>
              <option value="8">
                {{ locale == "cn" ? "电信" : "Telecommunications" }}
              </option>
              <option value="60">
                {{ locale == "cn" ? "纺织品" : "Textiles" }}
              </option>
              <option value="130">
                {{ locale == "cn" ? "智库" : "Think Tanks" }}
              </option>
              <option value="21">
                {{ locale == "cn" ? "烟草" : "Tobacco" }}
              </option>
              <option value="108">
                {{
                  locale == "cn"
                    ? "翻译和本地化"
                    : "Translation and Localization"
                }}
              </option>
              <option value="92">
                {{
                  locale == "cn"
                    ? "运输/货运/铁路"
                    : "Transportation/Trucking/Railroad"
                }}
              </option>
              <option value="59">
                {{ locale == "cn" ? "公用事业" : "Utilities" }}
              </option>
              <option value="106">
                {{
                  locale == "cn"
                    ? "风险投资和私募股权"
                    : "Venture Capital & Private Equity"
                }}
              </option>
              <option value="16">
                {{ locale == "cn" ? "兽医" : "Veterinary" }}
              </option>
              <option value="93">
                {{ locale == "cn" ? "仓储" : "Warehousing" }}
              </option>
              <option value="133">
                {{ locale == "cn" ? "批发" : "Wholesale" }}
              </option>
              <option value="142">
                {{ locale == "cn" ? "葡萄酒和烈酒" : "Wine and Spirits" }}
              </option>
              <option value="119">
                {{ locale == "cn" ? "无线的" : "Wireless" }}
              </option>
              <option value="103">
                {{ locale == "cn" ? "写作和编辑" : "Writing and Editing" }}
              </option>
            </select>
          </div>
          <input
            v-if="!readonly"
            type="submit"
            :value="nextLabel"
            class="btn btn-primary btn-large"
          />
        </div>
      </div>
      <div
        :class="`card ${step == 1 ? 'show' : 'hide'}`"
        style="margin-bottom: 2rem"
      >
        <div class="card-header flex-header">
          <h2 class="primary-title">{{ bankingInformationLabel }}</h2>
        </div>
        <div class="card-body">
          <div class="two-col-input">
            <div class="form-group">
              <div class="title">{{ bankNameLabel }}*</div>
              <input
                type="text"
                name="store[bank_name]"
                class="form-control"
                v-model="bank_name"
                :required="step == 1"
                :disabled="readonly"
              />
            </div>
            <div class="form-group">
              <div class="title">{{ bankAccountNumberLabel }}*</div>
              <input
                type="text"
                name="store[bank_account_number]"
                class="form-control"
                v-model="bank_account_number"
                v-on:keypress="numbersOnly"
                :required="step == 1"
                :disabled="readonly"
              />
            </div>
          </div>
          <div class="form-group">
            <div class="title">{{ bankAccountHolderLabel }}*</div>
            <input
              type="text"
              name="store[bank_account_holder]"
              class="form-control"
              v-model="bank_account_holder"
              :required="step == 1"
              :disabled="readonly"
            />
          </div>
          <input
            v-if="!readonly"
            type="submit"
            :value="nextLabel"
            class="btn btn-primary btn-large"
          />
        </div>
      </div>
      <div
        :class="`card ${step == 2 ? 'show' : 'hide'}`"
        style="margin-bottom: 2rem"
      >
        <div class="card-header flex-header">
          <h2 class="primary-title">{{ contactInformationLabel }}</h2>
        </div>
        <div class="card-body">
          <div class="two-col-input">
            <div class="form-group">
              <div class="title">{{ phoneNumberLabel }}*</div>
              <input
                type="text"
                name="store[phone_number]"
                class="form-control"
                v-model="phone_number"
                v-on:keypress="numbersOnly"
                :required="step == 2"
                :disabled="readonly"
              />
            </div>
            <div class="form-group">
              <div class="title">
                {{ emailLabel }}*
                <small class="text-danger">{{ email_error }}</small>
              </div>
              <input
                type="text"
                name="store[email]"
                class="form-control"
                v-model="email"
                :required="step == 2"
                :disabled="readonly"
              />
            </div>
          </div>
          <div class="form-group">
            <div class="title">{{ addressLabel }}*</div>
            <input
              type="text"
              name="store[address]"
              class="form-control"
              v-model="address"
              :required="step == 2"
              :disabled="readonly"
            />
          </div>
          <div class="two-col-input">
            <div class="form-group">
              <div class="title">{{ stateLabel }}*</div>
              <input
                type="text"
                name="store[state]"
                class="form-control"
                v-model="state"
                :required="step == 2"
                :disabled="readonly"
              />
            </div>
            <div class="form-group">
              <div class="title">{{ poskodLabel }}*</div>
              <input
                type="text"
                name="store[postcode]"
                class="form-control"
                v-model="postcode"
                v-on:keypress="numbersOnly"
                :required="step == 2"
                :disabled="readonly"
              />
            </div>
          </div>
          <input
            v-if="!readonly"
            type="submit"
            :value="nextLabel"
            class="btn btn-primary btn-large"
          />
        </div>
      </div>
      <div
        :class="`card ${step == 3 ? 'show' : 'hide'}`"
        style="margin-bottom: 2rem"
      >
        <div class="card-header flex-header">
          <h2 class="primary-title">{{ attachmentLabel }}</h2>
        </div>
        <div class="card-body">
          <div class="form-group">
            <div class="title">
              {{ ssmDocumentLabel }}*
              <small class="text-danger">{{ ssm_error }}</small>
            </div>
            <multiple-document-input
              :image-placeholder-label="uploadSsmPlaceholder"
              :add-image-label="addLabel"
              :delete-label="deleteLabel"
              v-on:documentFound="ssmFoundValidation"
              v-on:documentUpdate="ssm_document = $event"
              :store-documents="ssm"
              :view-file-label="viewFileLabel"
              :disabled="readonly"
              :max-file-length="10"
              input-name="registration_documents_attributes"
            >
            </multiple-document-input>
          </div>
          <div class="form-group">
            <div class="title">
              {{ bankStatementLabel }}*
              <small class="text-danger">{{ statement_error }}</small>
            </div>
            <multiple-document-input
              :image-placeholder-label="uploadStatementPlaceholder"
              :add-image-label="addLabel"
              :delete-label="deleteLabel"
              :store-documents="statements"
              :view-file-label="viewFileLabel"
              v-on:documentFound="statementFoundValidation"
              v-on:documentUpdate="bank_statement = $event"
              :disabled="readonly"
              :min-file-length="3"
              :max-file-length="3"
              input-name="bank_statements_attributes"
            >
            </multiple-document-input>
          </div>
          <div class="form-group">
            <div class="title">{{ businessOperationLicenseLabel }}</div>
            <multiple-document-input
              :image-placeholder-label="uploadLicensePlaceholder"
              :add-image-label="addLabel"
              :delete-label="deleteLabel"
              :store-documents="licenses"
              v-on:documentUpdate="business_operation_license = $event"
              :view-file-label="viewFileLabel"
              :disabled="readonly"
              :max-file-length="3"
              input-name="business_operating_licenses_attributes"
            >
            </multiple-document-input>
          </div>
          <div class="form-group">
            <div class="title">
              {{ shopFrontLabel }}*
              <small class="text-danger">{{ shop_front_error }}</small>
            </div>
            <image-input
              id-key="shop_front_photo"
              name="store[shop_front_photo]"
              :value="user.shop_front_photo.url"
              :change-label="changeLabel"
              :image-placeholder="uploadStoreFrontPlaceholder"
              v-on:documentFound="shopFrontFound = $event"
              v-on:documentUpdate="shop_front = $event"
              :disabled="readonly"
            ></image-input>
          </div>
          <input
            v-if="!readonly"
            type="submit"
            :value="nextLabel"
            class="btn btn-primary btn-large"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  props: [
    "role",
    "token",
    "user",
    "ssm",
    "statements",
    "licenses",
    "generalInformationLabel",
    "bankingInformationLabel",
    "contactInformationLabel",
    "attachmentLabel",
    "companyNameLabel",
    "registrationNumberLabel",
    "businessNatureLabel",
    "bankNameLabel",
    "bankAccountNumberLabel",
    "bankAccountHolderLabel",
    "phoneNumberLabel",
    "emailLabel",
    "addressLabel",
    "stateLabel",
    "poskodLabel",
    "ssmDocumentLabel",
    "bankStatementLabel",
    "businessOperationLicenseLabel",
    "shopFrontLabel",
    "uploadSsmPlaceholder",
    "uploadStatementPlaceholder",
    "uploadLicensePlaceholder",
    "uploadStoreFrontPlaceholder",
    "updateSuccessful",
    "somethingWrongMessaeg",
    "addLabel",
    "changeLabel",
    "deleteLabel",
    "nextLabel",
    "requiredLabel",
    "uploadRequiredMessage",
    "statementRequiredLabel",
    "emailErrorMessage",
    "viewFileLabel",
    "locale",
    "storeRejectMessage",
    "rejectMessageLabel",
  ],
  data() {
    return {
      step: 0,
      company_name: "",
      registration_number: "",
      business_nature: "",
      bank_name: "",
      bank_account_number: "",
      bank_account_holder: "",
      phone_number: "",
      email: "",
      address: "",
      state: "",
      postcode: "",
      ssm_document: [],
      bank_statement: [],
      business_operation_license: [],
      shop_front: "",
      ssmFound: false,
      ssm_error: "",
      statementFound: false,
      statement_error: "",
      shopFrontFound: false,
      shop_front_error: "",
      email_error: "",
      readonly: false,
      isLoading: false,
    };
  },
  computed: {
    formData: function () {
      return {
        authenticity_token: this.token,
        store: {
          name: this.company_name,
          registration_number: this.registration_number,
          business_nature: this.business_nature,
          bank_name: this.bank_name,
          bank_account_number: this.bank_account_number,
          bank_account_holder: this.bank_account_holder,
          phone_number: this.phone_number,
          email: this.email,
          address: this.address,
          state: this.state,
          postcode: this.postcode,
          shop_front_photo: this.shop_front,
          bank_statements_attributes: this.bank_statement,
          registration_documents_attributes: this.ssm_document,
          business_operating_licenses_attributes:
            this.business_operation_license,
        },
      };
    },
    isGeneralFilled: function () {
      return (
        this.company_name && this.registration_number && this.business_nature
      );
    },
    isBankFilled: function () {
      return (
        this.bank_name && this.bank_account_number && this.bank_account_holder
      );
    },
    isContachFilled: function () {
      return (
        this.phone_number &&
        this.address &&
        this.state &&
        this.postcode &&
        this.email
      );
    },
  },
  watch: {},
  mounted() {
    if (typeof this.user !== "undefined") {
      this.readonly =
        (this.user.store_info_status != "pending" &&
          this.user.store_info_status != "rejected" &&
          this.user.store_info_status != "approved") ||
        this.role == "CASHIER";
      this.company_name = this.user.name;
      this.registration_number = this.user.registration_number;
      this.business_nature = this.user.business_nature;
      this.bank_name = this.user.bank_name;
      this.bank_account_number = this.user.bank_account_number;
      this.bank_account_holder = this.user.bank_account_holder;
      this.phone_number = this.user.phone_number;
      this.address = this.user.address;
      this.state = this.user.state;
      this.postcode = this.user.postcode;
      this.email = this.user.email;
    }
  },
  methods: {
    validation: function () {
      const vm = this;
      if (
        this.step == 0 &&
        this.company_name != "" &&
        this.registration_number != "" &&
        this.business_nature != ""
      ) {
        this.step = 1;
      } else if (
        this.step == 1 &&
        this.bank_name != "" &&
        this.bank_account_number != "" &&
        this.bank_account_holder != ""
      ) {
        this.step = 2;
      } else if (
        this.step == 2 &&
        this.phone_number != "" &&
        this.email != "" &&
        this.address != "" &&
        this.postcode != "" &&
        this.state != ""
      ) {
        if (this.validateEmail(this.email)) {
          this.email_error = "";
          this.step = 3;
        } else {
          this.email_error = this.emailErrorMessage;
        }
      } else if (this.step == 3) {
        this.ssm_error = this.ssmFound ? "" : this.requiredLabel;
        this.statement_error = this.statementFound
          ? ""
          : this.statementRequiredLabel;
        this.shop_front_error = this.shopFrontFound ? "" : this.requiredLabel;

        if (
          this.ssmFound &&
          this.statementFound &&
          this.shopFrontFound &&
          !this.isLoading
        ) {
          this.isLoading = true;
          this.$http
            .put("/api/v1/stores/submit", this.formData)
            .then(() => {
              vm.$toast.open({
                message: vm.updateSuccessful,
                type: "success",
                position: "top-right",
              });

              setTimeout(function () {
                window.location.reload();
              }, 1000);
            })
            .catch((error) => {
              this.isLoading = false;
              vm.$toast.open({
                message: vm.somethingWrongMessaeg,
                type: "error",
                position: "top-right",
              });
            });
        } else {
          vm.$toast.open({
            message: vm.uploadRequiredMessage,
            type: "error",
            position: "top-right",
          });
        }
      }
    },
    numbersOnly: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    ssmFoundValidation: function (evt) {
      this.ssmFound = evt;
      this.ssm_error = this.ssmFound ? "" : this.requiredLabel;
    },
    statementFoundValidation: function (evt) {
      this.statementFound = evt;
      this.statement_error = this.statementFound
        ? ""
        : this.statementRequiredLabel;
    },
    validateEmail(mail) {
      return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail);
    },
  },
};
</script>

<style scoped>
.card.hide {
  display: none;
}
.card.show {
  display: block;
}
.tab {
  cursor: pointer;
}
.tab.disable {
  cursor: not-allowed;
}
</style>