<template>
  <div :class="'add-to-cart-control ' + page">
    <div class="availability">
      <span v-show="canUpdateCart" class="btn-cart minus" @click="minus">-</span>
      <b>{{ count }}</b>
      <span v-show="canUpdateCart" class="btn-cart plus" @click="plus">+</span>
    </div>
    <div v-if="page == 'explore'" @click="addCart" class="btn btn-primary">
      <span v-show="adding_to_cart" class="loader loading"></span>
      <span v-show="!adding_to_cart">{{ addCartBtn }}</span>
    </div>
    <div v-if="page == 'cart' && canUpdateCart" @click="removeItem" class="btn btn-primary">
      {{ removeBtn }}
    </div>
  </div>
</template>

<script>
  export default {
    props: [
      'canUpdateCart',
      'productId', 
      'cartId', 
      'token', 
      'page', 
      'quantity',
      'addCartBtn',
      'removeBtn',
      'removeConfirmMsg',
      'updateCartSuccess',
      'productQuantity',
      'productVariant',
      'locale',
      'isStockOverrideable',
      'variantId',
    ],
    data() {
      return {
        adding_to_cart: false,
        count: 1,
        outofstock_message_line_1: "",
        outofstock_message_line_2: "",
      }
    },
    watch: {},
    mounted() {
      if(typeof this.quantity != 'undefined') {
        this.count = parseInt(this.quantity);
      }
    },
    methods: {
      minus() {
        if(this.count > 1) {
          this.count -= 1;
          if(this.page == 'cart') {
            this.updateCart();
          }
        }
        else if(this.page == 'cart') {
          this.removeItem();
        }
      },
      plus(){
        if(this.page == 'cart') {
          if(this.validation()) {
            this.updateCart();
          }
          else {
            $('#fail_tic').addClass('show');
            $('#fail_tic h3').text(this.outofstock_message_line_1)
            $('#fail_tic p').text(this.outofstock_message_line_2)
            setTimeout(function(){ 
              $('#fail_tic').removeClass('show');
            }, 2000);
          }
        }
        else {
          this.count += 1;
        }
      },
      addCart(e) {
        if(!this.adding_to_cart) {
          this.adding_to_cart = true;
          var formData = {
            authenticity_token: this.token,
            locale: this.locale,
            cart_items: {
              product_id: this.productId,
              quantity: this.count,
              // product_variant_id: '01c84e3e-e1e1-4303-9e33-5433da9d93e7'
            }
          };
          var vm = this;
          this.$http.post('/api/v1/cart_items', formData)
          .then(response => {
            vm.adding_to_cart = false;
            $('#success_tic').addClass('show');
            $('#success_tic .checkmark').addClass('draw');
            setTimeout(function(){ 
              $('#success_tic').removeClass('show');
              $('#success_tic .checkmark').removeClass('draw');
            }, 2000);
            vm.updateCartCount();
          })
          .catch(error => {
            console.log(error);
          });
        }
      },
      removeItem(e){
        var r = confirm(this.removeConfirmMsg);
        if(r == true) {
          $('#'+this.cartId).remove();
          this.count = 0;
          this.updateCart();
        }
      },
      validation() {
        if(this.isStockOverrideable == 'no' && this.productVariant.length == 0 && this.productQuantity < this.count + 1) {
          this.outofstock_message_line_1 = this.locale == 'cn' ? `库存只有${this.productQuantity}数量。` : `There is only ${this.productQuantity} quantity in stock.`
          this.outofstock_message_line_2 = this.locale == 'cn' ? "请增加库存或启用库存覆盖设置" : "Please either increase stock or enable the stock overriable setting"
          return false
        }
        else if(this.isStockOverrideable == 'no' && this.productVariant.length > 0) {
          let vm = this
          let overstock = false
          this.productVariant.forEach(function(item) {
            if(item.id == vm.variantId && item.quantity < vm.count + 1) {
              vm.outofstock_message_line_1 = vm.locale == 'cn' ? `库存只有${item.quantity}数量。` : `There is only ${item.quantity} quantity in stock.`
              vm.outofstock_message_line_2 = vm.locale == 'cn' ? "请增加库存或启用库存覆盖设置" : "Please either increase stock or enable the stock overriable setting"
              overstock = true
            }
          });
          
          if(!overstock) {
            this.count += 1
          }

          return !overstock
        }
        else {
          // check order count to prevent over booking
          this.outofstock_message_line_1 = ""
          this.outofstock_message_line_2 = ""
          this.count += 1
          return true
        }
      },
      updateCart() {
        var formData = {
          authenticity_token: this.token,
          locale: this.locale,
          cart_items: {
            product_id: this.productId,
            quantity: this.count
          }
        };
        var vm = this;
        this.$http.put('/api/v1/cart_items/'+this.cartId, formData)
        .then(response => {
          var cart = response.data.data.cart;
          var currency = cart.total_price_currency;
          var subtotal = (Number((cart.subtotal_price_satoshi) / 100000000).toFixed(8));
          var tax_amount = (Number((cart.tax_amount_satoshi) / 100000000).toFixed(8));
          var total_price = (Number((cart.total_price_satoshi) / 100000000).toFixed(8));
          const discount_price = (Number((cart.discount_amount_satoshi) / 100000000).toFixed(8));
          $('.subtotal').text(currency + subtotal);
          $('.tax_amount').text(currency + tax_amount);
          $('.total_price').text(currency + total_price);
          $('.discount_price').text(currency + discount_price);
          if($('.item-row').length == 0) {
            window.location.reload();
          }
          else {
            vm.$toast.open({
              message: vm.updateCartSuccess,
              type: 'success',
              position: 'top-right'
            });

            if(response.data.data.error_messages.length > 0) {
              vm.$toast.open({
                message: response.data.data.error_messages[0],
                type: 'error',
                position: 'top-right'
              });
            }
          }
        })
        .catch(error => {
          console.log(error);
        });
      },
      updateCartCount(){
        this.$http.get('/api/v1/cart_items/count')
        .then(response => {
          $('.cart-count').text(response.data.data);
        })
        .catch(error => {
          console.log(error);
        });
      }
    },
  }
</script>

<style scoped>
.availability {
  transform: scale(1.5);
  margin-bottom: 15px;
}
.availability .btn-cart {
  margin: 0 0.75rem;
  padding: 0 0.25rem;
  cursor: pointer;
  transition: all 0.3s;
}
.availability .btn-cart:hover {
  cursor: pointer;
  color: #f9b73e;
}
.explore .btn-primary {
  padding: 1rem;
}
</style>