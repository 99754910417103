<template>
  <div class="card">
    <div class="header wallet-transaction-header">
      <div class="title">{{titleLabel}}</div>
      <div class="tag-wrapper mb-2">
        <div @click="current_wallet = index" v-for="(wallet, index) in wallet_details" :key="index" :class="'tag '+(index == current_wallet ? 'primary' : '')">
          {{wallet.symbol}}
        </div>
        <div v-if="type != 'admin' && currentMerchant && (currentMerchant.role == 'BOSS' || currentMerchant.permissions.withdrawal_request.read)" :class="'tag '+(current_wallet == -2 ? 'primary' : '')" @click="current_wallet = -2">
          {{ fiatWithdrawLabel }}  
        </div>
        <div v-if="type != 'admin' && currentMerchant && (currentMerchant.role == 'BOSS' || currentMerchant.permissions.conversion_request.read)" :class="'tag '+(current_wallet == -1 ? 'primary' : '')" @click="current_wallet = -1">
          {{ ppxConvertLabel }}
        </div>
      </div>
    </div>
    <div v-show="current_wallet >= 0" class="body table-responsive">
      <table v-for="(wallet, index) in wallet_details" :key="'table'+index" :class="'table '+(index == current_wallet ? 'active' : '')">
        <thead>
          <tr>
            <th>{{dateLabel}}</th>
            <th>{{typeLabel}}</th>
            <th>{{amountLabel}}</th>
            <th>{{feeLabel}}</th>
            <th>{{statusLabel}}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-show="wallet.transactions.totalCount > 0" v-for="(transaction, tIndex) in wallet.transactions.transactions" :key="'tr'+tIndex">
            <td>
              <span v-if="locale == 'cn'">
                {{new Date(transaction.created).getFullYear()}}年{{new Date(transaction.created).getMonth() + 1}}月{{new Date(transaction.created).getDate()}}日 {{new Date(transaction.created).getHours()}}:{{new Date(transaction.created).getMinutes() < 10 ? `0${new Date(transaction.created).getMinutes()}` : new Date(transaction.created).getMinutes()}}
              </span>
              <span v-else>
                {{new Date(transaction.created).getDate()}} {{months[new Date(transaction.created).getMonth()]}}, {{new Date(transaction.created).getFullYear()}} {{new Date(transaction.created).getHours()}}:{{new Date(transaction.created).getMinutes() < 10 ? `0${new Date(transaction.created).getMinutes()}` : new Date(transaction.created).getMinutes()}}
              </span>
            </td>
            <td>{{locale == 'cn' ? '交易' : transaction.type}}</td>
            <td>{{transaction.amount}}</td>
            <td>{{transaction.fee}}</td>
            <td>{{locale == 'cn' ? '完成' : transaction.status}}</td>
          </tr>
          <tr v-show="wallet.transactions.totalCount == 0">
            <td colspan="5">{{zeroTransactionMessage}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-show="current_wallet == -1" class="body table-responsive">
      <table :class="'table '+(current_wallet == -1 ? 'active' : '')">
        <thead>
          <tr>
            <th>{{dateLabel}}</th>
            <th>{{typeLabel}}</th>
            <th>{{amountLabel}}</th>
            <th>{{rateLabel}}</th>
            <th>{{statusLabel}}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(transaction, tIndex) in convertTransactions" :key="'convert'+tIndex">
            <td>
              <span v-if="locale == 'cn'">
                {{new Date(transaction.created_at).getFullYear()}}年{{new Date(transaction.created_at).getMonth() + 1}}月{{new Date(transaction.created_at).getDate()}}日 {{new Date(transaction.created_at).getHours()}}:{{new Date(transaction.created_at).getMinutes() < 10 ? `0${new Date(transaction.created_at).getMinutes()}` : new Date(transaction.created_at).getMinutes()}}
              </span>
              <span v-else>
                {{new Date(transaction.created_at).getDate()}} {{months[new Date(transaction.created_at).getMonth()]}}, {{new Date(transaction.created_at).getFullYear()}} {{new Date(transaction.created_at).getHours()}}:{{new Date(transaction.created_at).getMinutes() < 10 ? `0${new Date(transaction.created_at).getMinutes()}` : new Date(transaction.created_at).getMinutes()}}
              </span>
            </td>
            <td>{{locale == 'cn' ? '兑换' : transaction.type}}</td>
            <td>
              {{(Number((transaction.amount_satoshi) / 100000000).toFixed(8))}}
              {{ transaction.amount_currency}}
              <span class="convert-label"> >>> </span>
              {{(Number((transaction.converted_amount_satoshi) / 100000000).toFixed(9))}}
              {{ transaction.converted_amount_currency}}
            </td>
            <td>
              {{ transaction.conversion_rate}} %
            </td>
            <td>
              <span v-if="locale == 'en'">{{ transaction.status }}</span>
              <span v-else-if="transaction.status == 'pending'">待批准</span>
              <span v-else-if="transaction.status == 'failed'">失败</span>
              <span v-else-if="transaction.status == 'success'">成功</span>
            </td>
          </tr>
          <tr v-show="convertTransactions.length == 0">
            <td colspan="5">{{zeroRequestMessage}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-show="current_wallet == -2" class="body table-responsive">
      <div v-if="withdrawBulkIds.length > 0" class="bulk-update-box">
        {{ withdrawBulkIds.length }} {{ locale == 'en' ? "items selected." : "记录选中"}} <a @click="bulkUpdate">{{ locale == "en" ? "Bulk update now" : "立即批量更新"}}</a>
      </div>
      <table :class="'table '+(current_wallet == -2 ? 'active' : '')">
        <thead>
          <tr>
            <th v-if="type == 'admin'">
              <input type="checkbox" v-model="isWithdrawCheckAll">
            </th>
            <th v-if="type == 'admin'">{{storeLabel}}</th>
            <th v-if="type == 'admin'">{{bankLabel}}</th>
            <th>{{dateLabel}}</th>
            <th>{{typeLabel}}</th>
            <th>{{amountLabel}}</th>
            <th>{{ feeLabel}}</th>
            <th>{{statusLabel}}</th>
            <th v-if="type == 'admin'"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(transaction, tIndex) in withdrawTransactions" :key="'withdraw'+tIndex">
            <td v-if="type == 'admin'">
              <div style="position: relative;">
                <input v-if="withdrawBulkIds.includes(transaction.id)" type="checkbox" checked>
                <input v-else type="checkbox">
                <div style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; z-index: 999;" @click="updateWithdawBulkId(transaction.id, withdrawBulkIds.includes(transaction.id))"></div>
              </div>
            </td>
            <td v-if="type == 'admin'">
              <a :href="`/${locale}/admin/stores/${transaction.store.id}?page=verification-document`" target="_blank">{{transaction.store.name}}</a>
            </td>
            <td v-if="type == 'admin'">
              {{transaction.store.bank_name}}
              <br>
              {{transaction.store.bank_account_number}}
              <br>
              {{transaction.store.bank_account_holder}}
            </td>
            <td>
              <span v-if="locale == 'cn'">
                {{new Date(transaction.created_at).getFullYear()}}年{{new Date(transaction.created_at).getMonth() + 1}}月{{new Date(transaction.created_at).getDate()}}日 {{new Date(transaction.created_at).getHours()}}:{{new Date(transaction.crecreated_atated).getMinutes() < 10 ? `0${new Date(transaction.created_at).getMinutes()}` : new Date(transaction.created_at).getMinutes()}}
              </span>
              <span v-else>
                {{new Date(transaction.created_at).getDate()}} {{months[new Date(transaction.created_at).getMonth()]}}, {{new Date(transaction.created_at).getFullYear()}} {{new Date(transaction.created_at).getHours()}}:{{new Date(transaction.creacreated_atted).getMinutes() < 10 ? `0${new Date(transaction.created_at).getMinutes()}` : new Date(transaction.created_at).getMinutes()}}
              </span>
            </td>
            <td>
              <span v-if="locale == 'en'">{{ transaction.withdrawal_type }} withdraw</span>
              <span v-else-if="transaction.withdrawal_type == 'ONE-TIME'">一次性提款</span>
              <span v-else-if="transaction.withdrawal_type == 'WEEKLY'">每星期自动提款</span>
              <span v-else-if="transaction.withdrawal_type == 'MONTHLY'">每月自动提款</span>
              {{ transaction.type }}
            </td>
            <td>
              {{(Number((transaction.crypto_amount_satoshi) / 100000000).toFixed(8))}}
              {{ transaction.crypto_amount_currency}}
              <span class="convert-label"> >>> </span>
              {{(Number((transaction.fiat_amount_satoshi) / 100).toFixed(2))}}
              {{ transaction.fiat_amount_currency}}
            </td>
            <td>
              <span v-if="['USD', 'MYR', 'CNY', 'RMB'].includes(transaction.fee_currency)">{{ (Number((transaction.fee_satoshi) / 100).toFixed(2))}}</span>
              <span v-else> {{ (Number((transaction.fee_satoshi) / 100000000).toFixed(8))}}</span>
              {{ transaction.fee_currency}}
              <br>
              ({{ transaction.fee_rate }} %)
            </td>
            <td>
              <span v-if="locale == 'en'">{{ transaction.status }}</span>
              <span v-else-if="transaction.status == 'pending'">处理中</span>
              <span v-else-if="transaction.status == 'failed'">失败</span>
              <span v-else-if="transaction.status == 'transferred'">已转帐</span>
              <span v-else-if="transaction.status == 'paid'">已付款</span>
            </td>
            <td v-if="type == 'admin'">
              <a v-if="transaction.status == 'transferred'" @click="pay(transaction)">{{ locale == 'en' ? 'Pay' : '付款'}}</a>
            </td>
          </tr>
          <tr v-show="withdrawTransactions.length == 0">
            <td colspan="5">{{zeroRequestMessage}}</td>
          </tr>
        </tbody>
      </table>

      <nav v-if="current_wallet == -1 && convertTransactions.length > 0 && convertPagination" aria-label="pager" class="pagy-bootstrap-nav">
        <ul class="pagination">
          <li v-if="convertPagination.prev" class="page-item prev">
            <a @click="convertPage = convertPagination.prev" aria-label="previous" class="page-link">
              &lt;
            </a>
          </li>
          <li
            v-for="index in convert_pagination_menu"
            :key="index"
            v-show="index != '...'"
            :class="{ active: index == convertPage }"
            class="page-item"
          >
            <a @click="convertPage = index" class="page-link">{{index}}</a>
          </li>
          <li v-if="convertPagination.next" class="page-item next">
            <a @click="convertPage = convertPagination.next" rel="next" aria-label="next" class="page-link">
              &gt;
            </a>
          </li>
        </ul>
      </nav>
      <nav v-if="current_wallet == -2 && withdrawTransactions.length > 0 && withdrawPagination" aria-label="pager" class="pagy-bootstrap-nav">
        <ul class="pagination">
          <li v-if="withdrawPagination.prev" class="page-item prev">
            <a @click="withdrawPage = withdrawPagination.prev" aria-label="previous" class="page-link">
              &lt;
            </a>
          </li>
          <li
            v-for="index in withdraw_pagination_menu"
            :key="index"
            v-show="index != '...'"
            :class="{ active: index == withdrawPage }"
            class="page-item"
          >
            <a @click="withdrawPage = index" class="page-link">{{index}}</a>
          </li>
          <li v-if="withdrawPagination.next" class="page-item next">
            <a @click="withdrawPage = withdrawPagination.next" rel="next" aria-label="next" class="page-link">
              &gt;
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
  export default {
    props: [
      'currentMerchant',
      'wallets',
      'type',
      'token',
      'storeLabel',
      'bankLabel',
      'titleLabel',
      'typeLabel',
      'amountLabel',
      'feeLabel',
      'rateLabel',
      'dateLabel',
      'statusLabel',
      'zeroTransactionMessage',
      'zeroRequestMessage',
      'fiatWithdrawLabel',
      'ppxConvertLabel',
      'locale',
      'walletApi',
    ],
    data() {
      return {
        withdrawTransactions: [],
        withdrawPage: 1,
        withdrawPagination: {},
        withdraw_pagination_menu: [],
        convertTransactions: [],
        convertPage: 1,
        convertPagination: {},
        convert_pagination_menu: [],
        wallet_details: [],
        current_wallet: 0,
        months: [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December'
        ],
        isWithdrawCheckAll: false,
        withdrawBulkIds: []
      }
    },
    computed: {},
    watch: {
      isWithdrawCheckAll: function(val) {
        if(val) {
          let vm = this
          this.withdrawBulkIds = []
          this.withdrawTransactions.forEach(function(trans) {
            vm.withdrawBulkIds.push(trans.id)
          })
        }
        else {
          this.withdrawBulkIds = []
        }
      }
    },
    mounted() {
      if(typeof this.wallets != 'undefined') {
        this.wallets.forEach(function(wallet){
          this.getWalletDetail(wallet.address);
        }, this);
      }
      this.getWithdrawRequest()
      this.getConversationRequest()
    },
    methods: {
      getWalletDetail(address) {
        let vm = this;
        this.$http.get(this.walletApi+'/coin/'+address+'?pagesize=10&pageindex=1')
        .then(response => {
          vm.wallet_details.push(response.data.account);
        })
        .catch(error => {
          console.log(error);
        });
      },
      generatePagination(c, m) {
        var current = c,
          last = m,
          delta = 1,
          left = current - delta,
          right = current + delta + 1,
          range = [],
          rangeWithDots = [],
          l;

        for (let i = 1; i <= last; i++) {
          if (i == 1 || i == last || (i >= left && i < right)) {
            range.push(i);
          }
        }

        for (let i of range) {
          if (l) {
            if (i - l === 2) {
              rangeWithDots.push(l + 1);
            } else if (i - l !== 1) {
              rangeWithDots.push("...");
            }
          }
          rangeWithDots.push(i);
          l = i;
        }

        return rangeWithDots;
      },
      getWithdrawRequest() {
        let vm = this;
        let api = '/api/v1/'+( this.type == 'admin' ? 'admin/' : '')+'withdrawal_requests?';
        api += '&page='+this.withdrawPage
        this.$http.get(api)
        .then(response => {
          vm.withdrawTransactions = response.data.data;
          vm.withdrawPagination = response.data.pagy;
          vm.withdraw_pagination_menu = vm.generatePagination(
            vm.withdrawPagination.page,
            vm.withdrawPagination.pages
          );
        })
        .catch(error => {
          console.log(error);
        });
      },
      getConversationRequest() {
        let vm = this;
        let api = '/api/v1/'+( this.type == 'admin' ? 'admin/' : '')+'conversion_requests?';
        api += '&page='+this.convertPage
        this.$http.get(api)
        .then(response => {
          vm.convertTransactions = response.data.data;

          vm.convertPagination = response.data.pagy;
          vm.convert_pagination_menu = vm.generatePagination(
            vm.convertPagination.page,
            vm.convertPagination.pages
          );
        })
        .catch(error => {
          console.log(error);
        });
      },
      pay(transaction) {
        let vm = this;
        let api = `/api/v1/admin/withdrawal_requests/${transaction.id}/pay`;
        this.$http.put(api, {authenticity_token: this.token})
        .then(response => {
          vm.withdrawTransactions.forEach(function(trans) {
            if(trans.id == transaction.id) {
              trans.status = response.status
            }
          }) 
        })
        .catch(error => {
          console.log(error);
        });
      },
      updateWithdawBulkId(id, isExist) {
        if(isExist) {
          this.withdrawBulkIds = this.withdrawBulkIds.filter((n) => {return n != id});
        }
        else {
          this.withdrawBulkIds.push(id)
        }
      },
      bulkUpdate() {
        let vm = this;
        let api = `/api/v1/admin/withdrawal_requests/bulk_pay`;
        this.$http.put(api, {
          authenticity_token: this.token, 
          withdrawal_request: {
            ids: this.withdrawBulkIds
          }
        })
        .then(() => {
          vm.$toast.open({
            message: vm.locale == 'en' ? "Bulk pay successfully" : "批量支付成功", 
            type: "success",
            position: "top-right",
          });
          vm.withdrawBulkIds = []
          vm.isWithdrawCheckAll = false
          vm.getWithdrawRequest()
        })
        .catch(error => {
          console.log(error);
        });
      }
    },
  }
</script>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
}
.table {
  display: none;
}
.table.active {
  display: table;
  box-shadow: none;
  width: calc(100% + 2rem);
  margin-left: -1rem;
  border-top: 1px solid #E5E5E5;
}
.convert-label {
  background: #d3d3d3;
  color: #000;
  padding: 0 .25rem;
  border-radius: 5px;
  display: inline-block;
  margin: 0 10px;
}
.bulk-update-box {
  background: #f9b73e52; 
  padding: 1rem; 
  border-radius: 5px; 
  margin-bottom: 10px; 
  font-size: 14px; 
  font-weight: bold;
}
.bulk-update-box a {
  display: inline-block; 
  margin-left: 10px;
}
</style>