<template>
  <div class="card">
      <div class="heading">{{symbol}} {{balanceLabel}}</div>
      <div class="title">{{Number(balance).toFixed(8)}}</div>
      <div v-if="showTopupAddress" class="mt-2 topup-address">{{topupLabel}}: {{public_key}}</div>
      <div v-if="walletPublicKey" class="mt-2 topup-address">
        {{topupLabel}}: {{walletPublicKey}}
      </div>
    </div>
</template>

<script>
  export default {
    props: ['apiEndpoint', 'address', 'symbol', 'balanceLabel', 'showTopupAddress', 'topupLabel', 'walletPublicKey', "walletApi",],
    data() {
      return {
        balance: null,
        wallet_address: '',
        public_key: ''
      }
    },
    computed: {},
    watch: {
      wallet_address: function() {
        this.getWalletDetail()
      }
    },
    mounted() {
      if(typeof this.address != 'undefined') {
        this.wallet_address = this.address;
      }

      if(typeof this.apiEndpoint != 'undefined') {
        this.getWallet();
      }
    },
    methods: {
      getWallet() {
        let vm = this;
        this.$http.get(this.apiEndpoint)
        .then(response => {
          vm.wallet_address = response.data.data.address
        })
        .catch(error => {
          console.log(error);
        });
      },
      getWalletDetail() {
        let vm = this;
        this.$http.get(this.walletApi+'/coin/'+this.wallet_address+'?pagesize=10&pageindex=1')
        .then(response => {
          vm.balance = response.data.account.amount;
          vm.public_key = response.data.account.publickey;
        })
        .catch(error => {
          console.log(error);
        });
      }
    },
  }
</script>

<style scoped>
.topup-address {
  word-break: break-word;
}
</style>