<template>
<div>
  <textarea v-model="reject_message" class="form-control"></textarea>
  <div v-if="err != ''" style="color: red;">{{err}}</div>
  <div class="modal-control" style="margin-top: 1.5rem;">
    <a class="btn btn-primary-outline modal-toggle" data-target="#modal-reject">{{ discardLabel}}</a>
    <a class="modal-toggle btn btn-primary" @click="reject">{{ yesLabel }}</a>
  </div>
</div>
  
</template>

<script>
export default {
  props: [
    "token",
    "storeId",
    "yesLabel",
    "discardLabel",
    "rejectedSuccessful",
    "somethingWrongMessaeg",
    "locale",
  ],
  data() {
    return {
      reject_message: '',
      err: "",
    }
  },
  methods: {
    reject() {
      let vm = this;
      if( this.reject_message == "") {
        this.err = this.locale == 'cn' ? "请告知商家拒收原因" : "Please tell merchant the reason of reject"
      }
      else {
        this.err = ""
        this.$http
        .put(`/api/v1/admin/stores/${this.storeId}/reject`, {
          authenticity_token: this.token,
          store: {
            reject_message: vm.reject_message,
          },
        })
        .then(() => {
          vm.$toast.open({
            message: vm.rejectedSuccessful,
            type: "success",
            position: "top-right",
          });
          setTimeout(function(){ window.location.reload(); }, 1000);
        })
        .catch((error) => {
          vm.$toast.open({
            message: vm.somethingWrongMessaeg,
            type: "error",
            position: "top-right",
          });
          console.log(error);
        }); 
      }
    },
  },
};
</script>