<template>
  <form @submit="updateSetting" class="flex-wrapper">
    <input type="number" min="0" v-model="value" @change="isSaveable = true" class="form-control">
    <button type="submit" class="btn btn-primary btn-sm">{{ saveLabel }}</button>
  </form>
</template>

<script>
export default {
  props: ["storeId", "saveLabel", "inputValue", "token", "updateSuccessful", "somethingWrongMessaeg"],
  data() {
    return {
      value: "",
      isLoading: false,
      isSaveable: false,
    };
  },
  mounted() {
    if (typeof this.inputValue != "undefined" && this.inputValue != "") {
      this.value = Math.floor(this.inputValue);
    }
  },
  methods: {
    updateSetting(e) {
      e.preventDefault();
      if (!this.isLoading) {
        this.isLoading = true;
        const vm = this;
        this.$http
          .put(`/api/v1/stores/${this.storeId}`, {
            authenticity_token: this.token,
            store: {
              tax_percentage: this.value,
            },
          })
          .then(() => {
            vm.isLoading = false;
            vm.$toast.open({
              message: vm.updateSuccessful,
              type: "success",
              position: "top-right",
            });
          })
          .catch((error) => {
            vm.isLoading = false;
            vm.$toast.open({
              message: vm.somethingWrongMessaeg,
              type: "error",
              position: "top-right",
            });
          });
      }
    },
  },
};
</script>

<style scoped>
.flex-wrapper {
    display: flex;
}
.form-control {
    max-width: 100px;
}
.btn {
    max-width: 70px;
    margin-left: 10px;
}
</style>
