<template>
  <div v-show="canCreateCart">
    <div class="btn btn-primary cart" @click="openForm">
      <span>{{ addCartBtn }}</span>
    </div>
    <div :class="'form p' + index + '-form'">
      <div class="form-inner">
        <div class="close" @click="closeForm">X</div>
        <div
          class="product-image"
          :style="'background-image:url(' + productImage + ')'"
        ></div>
        <div class="product-summary">
          <div class="category">
            {{ categoryName }}
          </div>
          <div class="name">
            {{ productName }}
          </div>
          <div v-if="productVariants.length == 0" class="price">
            {{ product.price_currency }}
            <span
              v-if="
                product.price_currency == 'USD' ||
                product.price_currency == 'RMB'
              "
            >
              <span v-if="product.discounted_price_satoshi > 0">
                <del>{{ Number(product.price_satoshi / 100).toFixed(2) }}</del>
                <br>
                {{ product.price_currency }}
                {{ Number(product.discounted_price_satoshi / 100).toFixed(2) }}
              </span>
              <span v-else>
                {{ Number(product.price_satoshi / 100).toFixed(2) }}
              </span>
            </span>
            <span v-else>
              <span v-if="product.discounted_price_satoshi > 0">
                <del>{{
                  Number(product.price_satoshi / 100000000).toFixed(8)
                }}</del>
                {{
                  Number(product.discounted_price_satoshi / 100000000).toFixed(
                    8
                  )
                }}
              </span>
              <span v-else>
                {{ Number(product.price_satoshi / 100000000).toFixed(8) }}
              </span>
            </span>
          </div>
          <div v-else class="price">
            {{ product.price_currency }}
            {{ productPriceRange }}
          </div>
          <div
            v-if="productVariants.length == 0"
            class="quantity"
            :class="{ lowstock: productQuantity <= 0 }"
          >
            {{ availableInventoryLabel }}: <b>{{ productQuantity - getCartItemsQuantity("") }}</b>
          </div>
          <div class="description" v-html="productDescription"></div>
          <div v-if="productVariants.length > 0" class="variant-wrapper">
            <div
              v-for="(variant, key) in productVariants"
              :key="key"
              class="variant-row"
            >
              <div :class="product_variant == variant.id ? 'checked' : ''">
                <input
                  type="radio"
                  :id="'variant' + key + '-radio'"
                  :name="'variant' + index + '-radio[]'"
                  :value="variant.id"
                  :checked="product_variant == variant.id"
                  @click="setProductVariant(variant)"
                />
                <label
                  :for="'variant' + key + '-radio'"
                  @click="setProductVariant(variant)"
                  style="margin-left: 10px"
                  >{{ variant.name }}</label
                >
              </div>
              <div class="price-inventory">
                {{ variant.price_currency }}
                <span
                  v-if="
                    variant.price_currency == 'USD' ||
                    variant.price_currency == 'RMB'
                  "
                >
                  <span v-if="variant.discounted_price_satoshi > 0">
                    <del>{{
                      Number(variant.price_satoshi / 100).toFixed(2)
                    }}</del>
                    {{
                      Number(variant.discounted_price_satoshi / 100).toFixed(2)
                    }}
                  </span>
                  <span v-else>
                    {{ Number(variant.price_satoshi / 100).toFixed(2) }}
                  </span>
                </span>
                <span v-else>
                  <span v-if="variant.discounted_price_satoshi > 0">
                    <del>{{
                      Number(variant.price_satoshi / 100000000).toFixed(8)
                    }}</del>
                    {{
                      Number(
                        variant.discounted_price_satoshi / 100000000
                      ).toFixed(8)
                    }}
                  </span>
                  <span v-else>
                    {{ Number(variant.price_satoshi / 100000000).toFixed(8) }}
                  </span>
                </span>
                <div :class="{ lowstock: variant.quantity <= 0 }">
                  {{ availableInventoryLabel }}: <b>{{ variant.quantity - getCartItemsQuantity(variant.id) }}</b>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="product-footer">
          <div class="availability">
            <button class="add-to-cart-btn btn-cart minus" @click="minus">
              -
            </button>
            <b>{{ count }}</b>
            <button class="add-to-cart-btn btn-cart plus" @click="plus">
              +
            </button>
          </div>
          <button @click="addCart" class="btn btn-primary add-to-cart-btn">
            <span v-show="adding_to_cart" class="loader loading"></span>
            <span v-show="!adding_to_cart">{{ addCartBtn }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "canCreateCart",
    "isStockOverrideable",
    "placeholderImage",
    "product",
    "index",
    "token",
    "quantity",
    "addCartBtn",
    "productName",
    "categoryName",
    "productDescription",
    "productVariants",
    "productQuantity",
    "availableInventoryLabel",
    "productPriceRange",
    "locale",
  ],
  data() {
    return {
      productImage: "",
      adding_to_cart: false,
      count: 1,
      product_variant: "",
      no_stock: true,
      outofstock_message_line_1: "",
      outofstock_message_line_2: "",
      cart_items: [],
    };
  },
  watch: {},
  mounted() {
    if (typeof this.quantity != "undefined") {
      this.count = parseInt(this.quantity);
    }
    if (this.product.featured_photo.url) {
      this.productImage = this.product.featured_photo.url;
    } else {
      this.productImage = placeholderImage;
    }

    if (this.productVariants.length > 0) {
      this.product_variant = this.productVariants[0].id;
      this.no_stock = this.productVariants[0].quantity <= 0;
    }

    this.getCart();
  },
  methods: {
    closeForm() {
      $(".form").removeClass("open");
    },
    openForm() {
      this.closeForm();
      this.count = 1;
      $(".p" + this.index + "-form").addClass("open");
    },
    minus() {
      if (this.count > 1) {
        this.count -= 1;
        if (this.page == "cart") {
          this.updateCart();
        }
      } else if (this.page == "cart") {
        this.removeItem();
      }
    },
    plus() {
      this.count += 1;
      if (this.page == "cart") {
        this.updateCart();
      }
    },
    getCartItemsQuantity(variant_id) {
      let quantity = 0
      let vm = this;
      if(this.cart_items) {
        this.cart_items.forEach(function(item){
          if(item.product_id == vm.product.id) {
            if(variant_id != '') {
              if(variant_id == item.product_variant_id) {
                quantity = item.quantity
              }
            }
            else {
              quantity = item.quantity
            }
          }
        });
      }
      return quantity;
    },
    getCart() {
      const vm = this;
      this.$http
        .get("/api/v1/cart")
        .then((res) => {
          vm.cart_items = res.data.data.cart_items
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cartValidation() {
      if(this.isStockOverrideable == 'no' && this.productVariants.length == 0 && this.productQuantity < (this.count + this.getCartItemsQuantity(""))) {
        this.outofstock_message_line_1 = this.locale == 'cn' ? `库存只有${this.productQuantity}数量。` : `There is only ${this.productQuantity} quantity in stock.`
        this.outofstock_message_line_2 = this.locale == 'cn' ? "请增加库存或启用库存覆盖设置" : "Please either increase stock or enable the stock overriable setting"
        return false
      }
      else if(this.isStockOverrideable == 'no' && this.productVariants.length > 0) {
        let vm = this
        let overstock = false
        this.productVariants.forEach(function(item) {
          if(item.id == vm.product_variant && (item.quantity < (vm.count + vm.getCartItemsQuantity(item.id) ))) {
            vm.outofstock_message_line_1 = vm.locale == 'cn' ? `库存只有${item.quantity}数量。` : `There is only ${item.quantity} quantity in stock.`
            vm.outofstock_message_line_2 = vm.locale == 'cn' ? "请增加库存或启用库存覆盖设置" : "Please either increase stock or enable the stock overriable setting"
            overstock = true
          }
        });
        
        return !overstock
      }
      else {
        // check order count to prevent over booking
        this.outofstock_message_line_1 = ""
        this.outofstock_message_line_2 = ""
        return true
      }
    },
    addCart(e) {
      if (!this.adding_to_cart && this.cartValidation()) {
        this.outofstock_message_line_1 = ""
        this.outofstock_message_line_2 = ""
        this.adding_to_cart = true;
        var formData = {
          authenticity_token: this.token,
          cart_items: {
            product_id: this.product.id,
            quantity: this.count,
            // product_variant_id: '01c84e3e-e1e1-4303-9e33-5433da9d93e7'
          },
        };
        if (this.product_variant != "") {
          formData.cart_items.product_variant_id = this.product_variant;
        }
        var vm = this;
        this.$http
          .post("/api/v1/cart_items", formData)
          .then((response) => {
            vm.adding_to_cart = false;
            $("#success_tic").addClass("show");
            $("#success_tic .checkmark").addClass("draw");
            setTimeout(function () {
              $("#success_tic").removeClass("show");
              $("#success_tic .checkmark").removeClass("draw");
            }, 2000);
            vm.getCart();
            vm.updateCartCount();
            vm.closeForm();
          })
          .catch((error) => {
            console.log(error);
          });
      }
      else{
        $('#fail_tic').addClass('show');
        $('#fail_tic h3').text(this.outofstock_message_line_1)
        $('#fail_tic p').text(this.outofstock_message_line_2)
        setTimeout(function(){ 
          $('#fail_tic').removeClass('show');
        }, 4000);
      }
    },
    updateCartCount() {
      this.$http
        .get("/api/v1/cart_items/count")
        .then((response) => {
          $(".cart-count").text(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setProductVariant(variant) {
      this.product_variant = variant.id;
      this.no_stock = variant.quantity <= 0;
    },
  },
};
</script>

<style scoped>
.add-to-cart-btn:disabled {
  background: #ececec !important;
  color: #3d3d3d50 !important;
  border: 0;
}
.add-to-cart-btn:disabled:hover {
  background: #ececec;
  transform: translateY(0);
  box-shadow: unset;
}
.cart.btn-primary {
  padding: 1rem;
  margin-top: 1.5rem;
}
.form {
  position: fixed;
  width: 400px;
  background: #fff;
  top: 0;
  bottom: 0;
  right: 0;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
  transform: translateX(400px);
  transition: 0.3s transform;
  z-index: 1111;
}
.form.open {
  transform: translateX(0);
}
.product-image {
  background-size: cover;
  background-position: center;
  min-height: 250px;
}
.form-inner {
  position: relative;
  min-height: 100vh;
}

.form-inner .close {
  background: rgb(202 202 202 / 50%);
  color: #fff;
  border: 1px solid #fff;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.5;
  font-size: 14px;
  cursor: pointer;
  transition: 0.3s all;
}
.form-inner .close:hover {
  background: red;
  border-color: red;
}
.product-summary {
  padding: 1rem;
  max-height: calc(100vh - 250px);
  overflow: auto;
  padding-bottom: 100px;
}
.product-summary .name {
  color: #f9b73e;
  font-size: 20px;
  line-height: 26px;
  font-weight: bold;
}
.product-summary .category {
  background: #f9b73e;
  color: #fff;
  padding: 0.2rem 0.5rem;
  font-weight: bold;
  border-radius: 5px;
  font-size: 13px;
  display: block;
  font-size: 80%;
  margin-bottom: 1rem;
  float: right;
}
.product-summary .description {
  margin: 1rem 0;
  color: #7b7b7b;
}
.variant-wrapper {
  padding: 1rem 0;
  border-top: 2px solid #d8d8d8;
}
.checked {
  font-weight: bold;
}
.product-footer {
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
  position: absolute;
  bottom: 0;
  padding: 1rem;
  left: 0;
  right: 0;
  min-height: 100px;
  background: #fff;
  z-index: 111;
  display: flex;
  align-items: center;
}
.product-footer .btn {
  padding: 1rem;
  width: 50%;
}
.product-footer .availability {
  width: 45%;
  margin-right: 5%;
  font-size: 24px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.product-footer .availability .btn-cart {
  background: #fcdc9f;
  padding: 0.75rem;
  border-radius: 5px;
  color: #f9b73e;
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 30px;
  line-height: 0.5;
  cursor: pointer;
}
@media screen and (max-width: 400px) {
  .form {
    width: 100%;
  }
}
.price-inventory {
  text-align: right;
}
.add-to-cart-btn.plus,
.add-to-cart-btn.minus {
  padding: 0 !important;
  line-height: 0 !important;
  border: 0;
}
.lowstock {
  color: red;
}
</style>
