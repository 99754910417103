var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"two-col-input discount-date-range"},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.startLabel))]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.from_date),expression:"from_date"}],attrs:{"type":"hidden","name":"discount_code[start_at]"},domProps:{"value":(_vm.from_date)},on:{"input":function($event){if($event.target.composing){ return; }_vm.from_date=$event.target.value}}}),_vm._v(" "),_c('v-date-picker',{attrs:{"locale":_vm.locale == 'cn' ? 'zh' : 'en',"masks":{
          input: _vm.locale == 'cn' ? 'DD日 MM月 YYYY年' : 'DD-MM-YYYY',
        },"min-date":_vm.today,"required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var inputValue = ref.inputValue;
        var inputEvents = ref.inputEvents;
return [_c('input',_vm._g({staticClass:"form-control",attrs:{"placeholder":_vm.datepickerPlaceholder,"required":""},domProps:{"value":inputValue}},inputEvents))]}}]),model:{value:(_vm.from_date),callback:function ($$v) {_vm.from_date=$$v},expression:"from_date"}})],1),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.endLabel))]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.to_date),expression:"to_date"}],attrs:{"type":"hidden","name":"discount_code[end_at]"},domProps:{"value":(_vm.to_date)},on:{"input":function($event){if($event.target.composing){ return; }_vm.to_date=$event.target.value}}}),_vm._v(" "),_c('v-date-picker',{attrs:{"locale":_vm.locale == 'cn' ? 'zh' : 'en',"masks":{
          input: _vm.locale == 'cn' ? 'DD日 MM月 YYYY年' : 'DD-MM-YYYY',
        },"min-date":_vm.min_date,"required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var inputValue = ref.inputValue;
        var inputEvents = ref.inputEvents;
return [_c('input',_vm._g({staticClass:"form-control",attrs:{"placeholder":_vm.datepickerPlaceholder,"required":""},domProps:{"value":inputValue}},inputEvents))]}}]),model:{value:(_vm.to_date),callback:function ($$v) {_vm.to_date=$$v},expression:"to_date"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }