<template>
  <div>
    <select :name="inputName" class="form-control" v-model="currency" required>
      <option disabled>Select currency</option>
      <option v-for="(currency, index) in allow_currency" :value="currency" :key="index">{{currency}}</option>
      <option v-for="(p_currency, index) in platformCurrency" :value="p_currency" :key="`p${index}`">{{p_currency}}</option>
    </select>
  </div>
</template>
    

<script>
  export default {
    props: ['inputName', 'inputValue', 'platformCurrency'],
    data() {
      return {
        allow_currency: [],
        currency: null
      }
    },
    computed: {},
    watch: {
      currency: function(val) {
        this.$emit('newCurrency', val);
      },
      inputValue: function(val) {
        this.currency = val;
      }
    },
    mounted() {
      if(typeof this.currency != 'undefined') {
        this.currency = this.inputValue;
      }
      this.$http.get('/api/v1/wallets/allowed_currencies')
        .then(response => {
          this.allow_currency = response.data.data;
        })
        .catch(error => {
          console.log(error);
        });
    },
    methods: {},
  }
</script>