<template>
  <div>
      <quill-editor :class="['quill-editor', 'description-input']" ref="myQuillEditor" :options="editorOption" v-model="value"></quill-editor>
      <input type="hidden" :name="inputName" v-model="value">
  </div>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";

export default {
  props: [
    "inputLabel",
    "inputName",
    "inputValue",
  ],
  components: {
    quillEditor
  },
  data() {
    return {
      value: "",
      editorOption: { theme: "snow" },
    };
  },
  mounted() {
    if (typeof this.inputValue != "undefined" && this.inputValue != "") {
      this.value = this.inputValue;
    }
  },
};
</script>
