<template>
  <form @submit.prevent="onSubmit">
    <div class="form-group">
      <div class="title">{{ usernameLabel }}</div>
      <input class="form-control" :placeholder="usernameLabel" v-model="username" required>
    </div>
    <div class="form-group">
      <div class="title">{{ passwordLabel }}</div>
      <input type="password" class="form-control" :placeholder="passwordLabel" v-model="password" required>
    </div>
    <input
      type="submit"
      class="btn btn-large btn-block btn-primary"
      :value="saveLabel"
    />
  </form>
</template>

<script>
export default {
  props: [
    "token",
    "usernameLabel",
    "passwordLabel",
    "usernameValue",
    "saveLabel",
    "saveSuccessMessage",
    "saveFailMessage",
    "locale",
  ],
  data() {
    return {
      username: '',
      password: '',
      isLaoding: false,
    };
  },
  mounted() {
    if (typeof this.usernameValue != "undefined") {
      this.username = this.usernameValue;
    }
  },
  methods: {
    onSubmit() {
      if (!this.isLaoding) {
        this.isLaoding = true;
        const vm = this;
        this.$http
          .put("/api/v1/admin/settings", {
            authenticity_token: this.token,
            setting: {
              ping_admin_xpay_username: this.username,
              ping_admin_xpay_password: this.password
            }
          })
          .then(() => {
            vm.$toast.open({
              message: vm.saveSuccessMessage,
              type: "success",
              position: "top-right",
            });
            vm.isLaoding = false;
            vm.password = ''
          })
          .catch((error) => {
            vm.$toast.open({
              message: vm.saveFailMessage,
              type: "error",
              position: "top-right",
            });
            console.log(error);
            vm.isLaoding = false;
          });
      }
    },
  },
};
</script>
