<template>
    <div class="two-col-input discount-date-range">
        <div class="form-group">
            <div class="title">{{ startLabel }}</div>
            <input type="hidden" name="discount_code[start_at]" v-model="from_date">
            <v-date-picker
            v-model="from_date"
            :locale="locale == 'cn' ? 'zh' : 'en'"
            :masks="{
              input: locale == 'cn' ? 'DD日 MM月 YYYY年' : 'DD-MM-YYYY',
            }"
            :min-date='today'
            :required="true"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                class="form-control"
                :placeholder="datepickerPlaceholder"
                :value="inputValue"
                v-on="inputEvents"
                required
              />
            </template>
          </v-date-picker>
        </div>
        <div class="form-group">
            <div class="title">{{ endLabel }}</div>
            <input type="hidden" name="discount_code[end_at]" v-model="to_date">
            <v-date-picker
            v-model="to_date"
            :locale="locale == 'cn' ? 'zh' : 'en'"
            :masks="{
              input: locale == 'cn' ? 'DD日 MM月 YYYY年' : 'DD-MM-YYYY',
            }"
            :min-date='min_date'
            :required="true"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                class="form-control"
                :placeholder="datepickerPlaceholder"
                :value="inputValue"
                v-on="inputEvents"
                required
              />
            </template>
          </v-date-picker>
        </div>
    </div>
</template>

<script>
import {en, zh} from 'vuejs-datepicker/dist/locale'
import Datepicker from 'vuejs-datepicker';
export default {
  props: [
    "startLabel",
    "endLabel",
    "datepickerPlaceholder",
    "startAt",
    "endAt",
    "locale"
  ],
  components: {
    Datepicker
  },
  data() {
    return {
      from_date: "",
      to_date: "",
      today: "",
      min_date: "",
      en: en,
      zh: zh, 
    };
  },
  mounted() {
    this.from_date = this.startAt
    this.to_date = this.endAt
    this.today = new Date()
    this.min_date = this.today
  },
  watch: {
    from_date: function(val) {
      console.log(val)
      console.log(this.today)
      if(val > this.min_date) {
        this.min_date = val
      }
      if(val > this.to_date) {
        this.to_date = val
      }
    }
  }
};
</script>

<style>
.discount-date-range .vdp-datepicker input[type=text] {
    width: 100% !important;
    padding: 13px !important;
    border: 1px solid #e5e5e5 !important;
    border-radius: 5px !important;
    font-size: 14px !important;
    line-height: 1 !important;
    height: auto !important;
    max-width: unset;
    color: #000;
}
.discount-date-range .vdp-datepicker {
  float: unset;
}
.discount-date-range .vdp-datepicker__calendar {
  position: relative !important;
}
</style>