<template>
  <div style="width: 100%">
    <div v-if="productId" class="inventory-header">
      <div class="title">{{ branchLabel }}</div>
      <branch-picker
        :current-merchant="currentMerchant"
        :branches-data="branches"
        :product-id="productId"
        :branch-id="branch_id"
        :locale="locale"
        :picker-type="pickerType"
      ></branch-picker>
    </div>
    <div v-if="inventories.length == 0" class="empty-message">
      {{ emptyMessage }}
    </div>
    <table v-else>
      <tr>
        <th>{{ productLabel }}</th>
        <th>{{ skuLabel }}</th>
        <th>{{ inventoryLabel }}</th>
        <th>
          {{ statusLabel }}
        </th>
        <th v-if="showControl == true"></th>
      </tr>
      <tr
        v-for="(inventory, index) in inventories"
        :key="`inventory${index}`"
        class="inventory-row"
      >
        <td>
          <img
            :src="
              inventory.product.featured_photo.url
                ? inventory.product.featured_photo.url
                : placeholderImage
            "
            class="thumb"
          />
          {{ inventory.product.name }}
          {{
            inventory.product_variant_id
              ? ` (${inventory.product_variant.name})`
              : ""
          }}
        </td>
        <td>
          {{
            inventory.product_variant_id
              ? inventory.product_variant.sku
              : inventory.product.sku
          }}
        </td>
        <td>
          {{ inventory.quantity }}
          <span v-if="inventory.edit_mode">
            <span class="move-to"> >> </span>
            <input
              class="new_inventory"
              type="number"
              v-model="inventory.new_inventory"
            />
          </span>
          <div>
            <small
              v-if="inventory.quantity != inventory.new_inventory"
              :class="{
                add: inventory.new_inventory > inventory.quantity,
                minus: inventory.new_inventory < inventory.quantity,
              }"
            >
              {{ inventory.new_inventory - inventory.quantity > 0 ? "+" : "" }}
              {{ inventory.new_inventory - inventory.quantity }}
              {{ quantityLabel }}</small
            >
          </div>
        </td>
        <td>
          {{ inventory.active ? enabledLabel : disabledLabel }}
        </td>
        <td v-if="showControl == true" style="text-align: right">
          <a v-if="!inventory.edit_mode" @click="inventory.edit_mode = true">{{
            editLabel
          }}</a>
          <a
            v-if="inventory.edit_mode"
            @click="addTransaction(index)"
            class="btn btn-primary"
            >{{ saveLabel }}</a
          >
        </td>
      </tr>
    </table>

    <div class="flex-pagination-wrapper">
      <div
        v-if="inventories.length != 0 && locale == 'en'"
        class="dataTables_info"
      >
        Showing {{ paginate.from }} to {{ paginate.to }} of
        {{ paginate.count }} entries
      </div>
      <div
        v-if="inventories.length != 0 && locale == 'cn'"
        class="dataTables_info"
      >
        总共有 {{ paginate.count }} 个产品库存
      </div>

      <!-- table pagination -->
      <nav
        v-if="inventories.length != 0"
        aria-label="pager"
        role="navigation"
        class="pagy-bootstrap-nav"
      >
        <ul class="pagination">
          <li class="page-item prev">
            <a
              @click="getBranchInventory(paginate.prev)"
              aria-label="previous"
              class="page-link"
            >
              <
            </a>
          </li>
          <li
            v-for="index in paginate.menu"
            :key="index"
            v-show="index != '...'"
            :class="{ active: index == paginate.page }"
            class="page-item"
          >
            <a @click="getBranchInventory(index)" class="page-link">{{
              index
            }}</a>
          </li>
          <li class="page-item next">
            <a
              @click="getBranchInventory(paginate.next)"
              rel="next"
              aria-label="next"
              class="page-link"
            >
              >
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "currentMerchant",
    "placeholderImage",
    "pickerType",
    "showControl",
    "branches",
    "branchId",
    "productId",
    "token",
    "locale",
    "productLabel",
    "quantityLabel",
    "skuLabel",
    "statusLabel",
    "inventoryLabel",
    "editLabel",
    "saveLabel",
    "emptyMessage",
    "updateSuccessMessage",
  ],
  data() {
    return {
      branch_id: "",
      branchLabel: "",
      enabledLabel: "",
      disabledLabel: "",
      inventories: [],
      paginate: {},
    };
  },
  mounted() {
    this.branchLabel =
      this.locale == "en"
        ? "Select branch to view inventory"
        : "选择分店来查看库存";
    this.enabledLabel = this.locale == "en" ? "Active" : "可购买";
    this.disabledLabel = this.locale == "en" ? "Disabled" : "不可购买";
    if (this.currentMerchant.role == "BOSS") {
      this.branch_id = this.branchId;
    } else {
      this.branch_id = this.currentMerchant.branch_id;
    }
    this.getBranchInventory(1);
  },
  methods: {
    getBranchInventory(page) {
      const vm = this;
      let api = `/api/v1/branches/${this.branch_id}/branch_inventories?page=${page}`;
      api = api + (this.productId ? `&product_id=${this.productId}` : "");
      this.$http
        .get(api)
        .then((response) => {
          console.log(response);
          let addEditMode = response.data.data.map((inventory) => {
            return {
              ...inventory,
              ...{
                edit_mode: false,
                new_inventory: inventory.quantity,
              },
            };
          });
          vm.inventories = _.dropWhile(addEditMode, (inv) => !inv.active);
          const pagy = response.data.pagy;
          vm.paginate = {
            per_page: pagy.vars.items,
            page: pagy.page,
            prev: pagy.prev,
            next: pagy.next,
            pages: pagy.pages,
            from: pagy.from,
            to: pagy.to,
            count: pagy.count,
            menu: vm.pagination(pagy.page, pagy.pages),
          };
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addTransaction(index) {
      if (this.currentMerchant.role == 'BOSS' || this.currentMerchant.permissions.inventory_transaction.create) {
        const transaction = this.inventories[index];
        const vm = this;
        this.$http
          .post(`/api/v1/branches/${this.branch_id}/inventory_transactions`, {
            authenticity_token: this.token,
            inventory_transaction: {
              product_id: transaction.product_id,
              product_variant_id: transaction.product_variant_id,
              quantity: transaction.new_inventory - transaction.quantity,
            },
          })
          .then((response) => {
            vm.inventories[index].quantity = transaction.new_inventory;
            vm.inventories[index].edit_mode = false;
            vm.$toast.open({
              message: vm.updateSuccessMessage,
              type: "success",
              position: "top-right",
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
      else {
        this.$toast.open({
          message: this.locale == 'en' ? "You didn't have permission to update inventory transactions" : "您无权更新库存交易",
          type: "error",
          position: "top-right",
        });
      }
    },
    pagination(c, m) {
      var current = c,
        last = m,
        delta = 1,
        left = current - delta,
        right = current + delta + 1,
        range = [],
        rangeWithDots = [],
        l;

      for (let i = 1; i <= last; i++) {
        if (i == 1 || i == last || (i >= left && i < right)) {
          range.push(i);
        }
      }

      for (let i of range) {
        if (l) {
          if (i - l === 2) {
            rangeWithDots.push(l + 1);
          } else if (i - l !== 1) {
            rangeWithDots.push("...");
          }
        }
        rangeWithDots.push(i);
        l = i;
      }

      return rangeWithDots;
    },
  },
};
</script>

<style scoped>
.empty-message {
  margin: 1.5rem 0;
}
table {
  width: calc(100% + 4rem);
  transform: translateX(-2rem);
  box-shadow: none;
  border-bottom: 1px solid #e5e5e5;
}
th {
  padding: 1rem !important;
  background: #e5e5e5;
}
td {
  padding: 1rem !important;
  border-bottom: 0;
}
tr th:first-child,
tr td:first-child {
  padding-left: 2rem !important;
}
tr th:last-child,
tr td:last-child {
  padding-right: 2rem !important;
}
tr:hover td {
  background: #f5f5f5;
}
.thumb {
  width: 40px !important;
  margin-right: 1rem;
  display: inline-block;
}
.move-to {
  font-weight: bold;
  display: inline-block;
  margin: 0 0.5rem;
}
.new_inventory {
  width: 50px;
}
.minus {
  color: red;
}
.add {
  color: rgb(26, 185, 26);
}
.inventory-header {
  display: flex;
  margin-bottom: 15px;
  align-items: center;
}
.inventory-header .title {
  margin-bottom: 0;
  margin-right: 15px;
}
</style>
