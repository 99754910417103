<template>
  <div class="w-150p">
    <select v-model="selectedBranch" class="form-control">
      <option
        v-show="
          currentMerchant.role == 'BOSS' ||
          currentMerchant.branch_id == branch.id
        "
        v-for="(branch, index) in branchesData"
        :key="`branch_${index}`"
        :value="branch.id"
      >
        {{ branch.name }}
      </option>
    </select>
  </div>
</template>
<script>
export default {
  props: [
    "currentMerchant",
    "branchesData",
    "productId",
    "categoryId",
    "branchId",
    "locale",
    "pickerType",
  ],
  data() {
    return {
      branches: [],
      selectedBranch: "",
    };
  },
  computed: {},
  watch: {
    branchId: function (val) {
      this.selectedBranch = val;
    },
    selectedBranch: function (val) {
      if (this.pickerType == "dashboard" && val != this.branchId) {
        window.location = `/${this.locale}/?branch_id=${val}${
          this.categoryId ? `&category_id=${this.categoryId}` : ""
        }`;
      }
      if (this.pickerType == "product" && val != this.branchId) {
        window.location = `/${this.locale}/products/${this.productId}/edit?page=product-availability&branch_id=${val}`;
      }
      if (this.pickerType == "productshow" && val != this.branchId) {
        window.location = `/${this.locale}/products/${this.productId}?branch_id=${val}`;
      }
    },
  },
  mounted() {
    this.selectedBranch = this.branchId;
  },
};
</script>

<style lang="scss">
.w-150p {
  min-width: 150px;
}
</style>
