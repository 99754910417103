<template>
  <div class="card admin-table overflow-hidden">
    <form @submit.prevent="search" class="table-header">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="25pt"
        version="1.1"
        viewBox="-1 0 136 136.21852"
        width="25pt"
      >
        <g id="surface1">
          <path
            d="M 93.148438 80.832031 C 109.5 57.742188 104.03125 25.769531 80.941406 9.421875 C 57.851562 -6.925781 25.878906 -1.460938 9.53125 21.632812 C -6.816406 44.722656 -1.351562 76.691406 21.742188 93.039062 C 38.222656 104.707031 60.011719 105.605469 77.394531 95.339844 L 115.164062 132.882812 C 119.242188 137.175781 126.027344 137.347656 130.320312 133.269531 C 134.613281 129.195312 134.785156 122.410156 130.710938 118.117188 C 130.582031 117.980469 130.457031 117.855469 130.320312 117.726562 Z M 51.308594 84.332031 C 33.0625 84.335938 18.269531 69.554688 18.257812 51.308594 C 18.253906 33.0625 33.035156 18.269531 51.285156 18.261719 C 69.507812 18.253906 84.292969 33.011719 84.328125 51.234375 C 84.359375 69.484375 69.585938 84.300781 51.332031 84.332031 C 51.324219 84.332031 51.320312 84.332031 51.308594 84.332031 Z M 51.308594 84.332031 "
            style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;"
          />
        </g>
      </svg>
      <input
        type="text"
        :placeholder="searchStoreLabel"
        name="query"
        v-model="query"
      />
      <input type="submit" :value="searchLabel" class="btn btn-primary" />
    </form>
    <div class="overflow md-scrollbar">
      <table>
        <thead>
          <tr>
            <th>{{ storeLabel }}</th>
            <th>{{ emailLabel }}</th>
            <!-- <th>{{ connectInventoryLabel }}</th>
            <th>{{ loyaltyLabel }}</th> -->
            <th>{{ branchLimitLabel }}</th>
            <th>{{ walletAssignmentLabel }}</th>
            <th v-if="adminRole == 'SUPERADMIN' || adminRole == 'ADMIN'">{{ commissionLabel }}</th>
            <th>{{ storeLabel }}{{locale === 'en' ? ' ': ''}}{{ statusLabel }}</th>
            <th>{{ ownerLabel }}{{ statusLabel }}</th>
            <th>{{ documentVerificationRequestLabel }}</th>
            <th>{{ joinSinceLabel }}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(store, index) in store_list" :key="index">
            <td>
              <a :href="'/' + locale + '/admin/stores/' + store.id">
                {{ store.name }}
              </a>
            </td>
            <td>
              {{ getBossEmail(store) }}
            </td>
            <!-- <td>
              <div
                v-if="store.edit_mode"
                @click="updateCentralizedInventoryStatus(index)"
                :class="'toggle ' + (store.centralized_inventory ? 'checked' : '')"
              >
                <div class="dot"></div>
                <div class="label">
                  {{ store.centralized_inventory ? activeStatus : noActiveStatus }}
                </div>
              </div>
              <div v-else>{{ store.centralized_inventory ? activeStatus : noActiveStatus }}</div>
            </td>
            <td>
              <div
                v-if="store.edit_mode"
                @click="updateLoyalty(index)"
                :class="'toggle ' + (store.loyalty ? 'checked' : '')"
              >
                <div class="dot"></div>
                <div class="label">
                  {{ store.loyalty ? activeStatus : noActiveStatus }}
                </div>
              </div>
              <div v-else>{{ store.loyalty ? activeStatus : noActiveStatus }}</div>
            </td> -->
            <td class="branch-limit-input">
              <div v-if="store.edit_mode">
                <input
                  type="number"
                  min="1"
                  max="100"
                  v-model="store.branch_limit"
                  @change="updateBranchLimit(index)"
                />
              </div>
              <div v-else> {{ store.branch_limit }} </div>
            </td>
            <td>
              <div v-if="store.edit_mode" class="flex-row wallet-toggle-wrapper">
                <div
                  @click="updateWallet(index, wIndex)"
                  :class="'toggle ' + (wallet.is_allowed ? 'checked' : '')"
                  v-for="(wallet, wIndex) in store.merchant_wallets"
                  :key="'w' + wIndex"
                >
                  <div class="dot"></div>
                  <div class="label">
                    {{ wallet.currency }}
                  </div>
                </div>
              </div>
              <div v-else>
                <div
                  v-for="(wallet, wIndex) in store.merchant_wallets"
                  :key="'w' + wIndex"
                >
                  <div v-if="wallet.is_allowed">
                    {{ wallet.currency }}
                  </div>
                </div>
              </div>
            </td>
            <td v-if="adminRole == 'SUPERADMIN' || adminRole == 'ADMIN'" class="commission-input">
              <div v-if="store.edit_mode">
                <!-- <input type="number" min="1" max="100" :value="Number(store.commission_rate) * 100">% -->
                <input
                  type="number"
                  min="1"
                  max="100"
                  v-model="store.commission_rate"
                  @change="updateCommission(index)"
                />%
              </div>
              <div v-else>
                {{ store.commission_rate }}%
              </div>
            </td>
            <td>
              <div
                v-if="store.edit_mode"
                @click="updateStatus(index)"
                :class="'toggle ' + (store.active ? 'checked' : '')"
              >
                <div class="dot"></div>
                <div class="label">
                  {{ store.active ? activeStatus : noActiveStatus }}
                </div>
              </div>
              <div v-else>
                {{ store.active ? activeStatus : noActiveStatus }}
              </div>
            </td>
            <td>
              <div
                v-if="store.edit_mode"
                @click="updateBossStatus(index)"
                :class="'toggle ' + (getBoss(store,'active') ? 'checked' : '')"
              >
                <div class="dot"></div>
                <div class="label">
                  {{ getBoss(store,'active') ? activeStatus : noActiveStatus }}
                </div>
              </div>
              <div v-else>
                {{ getBoss(store,'active') ? activeStatus : noActiveStatus }}
              </div>
            </td>
            <td>
              <span v-if="locale == 'en'">
                {{ store.store_info_status }}
              </span>
              <span v-else-if="store.store_info_status == 'pending'">
                等待提交
              </span>
              <span v-else-if="store.store_info_status == 'submitted'">
                待批准
              </span>
              <span v-else-if="store.store_info_status == 'approved'">
                已验证
              </span>
              <span v-else-if="store.store_info_status == 'rejected'">
                已拒绝
              </span>
            </td>
            <td>
              <span v-if="locale == 'cn'">
                {{ new Date(store.created_at).getFullYear() }}年{{
                  new Date(store.created_at).getMonth() + 1
                }}月{{ new Date(store.created_at).getDate() }}日
                {{ new Date(store.created_at).getHours() }}:{{
                  new Date(store.created_at).getMinutes()
                }}
              </span>
              <span v-else>
                {{ new Date(store.created_at).getDate() }}
                {{ months[new Date(store.created_at).getMonth()] }},
                {{ new Date(store.created_at).getFullYear() }}
                {{ new Date(store.updated_at).getHours() }}:{{
                  new Date(store.created_at).getMinutes()
                }}
              </span>
            </td>
            <td>
              <a class="btn-hover no-word-break" v-if="!store.edit_mode" @click="store.edit_mode = true">{{
                editLabel
              }}</a>
              <a 
                v-if="store.edit_mode"
                class="btn btn-primary no-word-break"
                @click="updateStore(index)"
              >
                {{ saveLabel }}
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <nav
      v-if="store_list.length > 0 && pagination"
      aria-label="pager"
      class="pagy-bootstrap-nav"
    >
      <ul class="pagination">
        <li v-if="pagination.prev" class="page-item prev">
          <a
            @click="page = pagination.prev"
            aria-label="previous"
            class="page-link"
          >
            &lt;
          </a>
        </li>
        <li
          v-for="index in pagination_menu"
          :key="index"
          v-show="index != '...'"
          :class="{ active: index == page }"
          class="page-item"
        >
          <a @click="page = index" class="page-link">{{ index }}</a>
        </li>
        <li v-if="pagination.next" class="page-item next">
          <a
            @click="page = pagination.next"
            rel="next"
            aria-label="next"
            class="page-link"
          >
            &gt;
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  props: [
    "adminRole",
    "stores",
    "token",
    "searchStoreLabel",
    "searchLabel",
    "storeLabel",
    "emailLabel",
    "connectInventoryLabel",
    "branchLimitLabel",
    "walletAssignmentLabel",
    "commissionLabel",
    "activeLabel",
    "loyaltyLabel",
    "joinSinceLabel",
    "ownerLabel",
    "statusLabel",
    "updateLabel",
    "editLabel",
    "saveLabel",
    "updateConfirmMessage",
    "storeUpdatedMessage",
    "somethingWrongMessaeg",
    "commissionBiggerError",
    "commissionSmallerError",
    "activeStatus",
    "noActiveStatus",
    "branchLimitSmallerError",
    "locale",
    "documentVerificationRequestLabel",
  ],
  data() {
    return {
      store_list: [],
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      query: null,
      pagination: {},
      pagination_menu: [],
      page: 1,
    };
  },
  computed: {},
  watch: {
    page: function(p) {
      this.getStore();
    },
  },
  mounted() {
    this.getStore();
  },
  methods: {
    search() {
      this.getStore();
    },
    getStore() {
      let vm = this;
      let api = "/api/v1/admin/stores?";
      api += this.query ? "&query=" + this.query : "";
      api += this.page ? "&page=" + this.page : "";
      this.$http
        .get(api)
        .then((response) => {
          vm.store_list = [];
          let store_list = response.data.data.map((store)=>{
            return {
              ...store,
              ...{
                edit_mode: false,
              }
            }
          });
          vm.pagination = response.data.pagy;
          vm.pagination_menu = vm.generatePagination(
            vm.pagination?.page,
            vm.pagination.pages
          );
          store_list.forEach(function(store) {
            if (!store.commission_rate) {
              store.commission_rate = 0;
            } else {
              store.commission_rate = store.commission_rate * 100;
            }

            if (!store.branch_limit) {
              store.branch_limit = 0;
            } else {
              store.branch_limit = store.branch_limit;
            }

            vm.store_list.push(store);
          });
          console.log(vm.store_list)
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateWallet(index, wIndex) {
      var r = confirm(this.updateConfirmMessage);
      if (r == true) {
        var vm = this;
        var wallet = this.store_list[index].merchant_wallets[wIndex];
        var store_id = this.store_list[index].id;
        var wallet_id = wallet.id;
        var is_allowed = !wallet.is_allowed;
        var formData = {
          authenticity_token: this.token,
          store: {
            merchant_wallets_attributes: {
              id: wallet_id,
              is_allowed: is_allowed,
            },
          },
        };
        this.$http
          .put("/api/v1/admin/stores/" + store_id, formData)
          .then((response) => {
            vm.$toast.open({
              message: vm.storeUpdatedMessage,
              type: "success",
              position: "top-right",
            });
            vm.store_list[index].merchant_wallets[
              wIndex
            ].is_allowed = is_allowed;
          })
          .catch((error) => {
            vm.$toast.open({
              message: vm.somethingWrongMessaeg,
              type: "error",
              position: "top-right",
            });
            console.log(error);
          });
      }
    },
    updateCentralizedInventoryStatus(index) {
      this.store_list[index].centralized_inventory = !this.store_list[index].centralized_inventory;
      // var r = confirm(this.updateConfirmMessage);
      // if (r == true) {
      //   var vm = this;
      //   var is_active = !this.store_list[index].centralized_inventory;
      //   var store_id = this.store_list[index].id;
      //   var formData = {
      //     authenticity_token: this.token,
      //     store: {
      //       centralized_inventory: is_active,
      //     },
      //   };
      //   this.$http
      //     .put("/api/v1/admin/stores/" + store_id, formData)
      //     .then((response) => {
      //       vm.$toast.open({
      //         message: vm.storeUpdatedMessage,
      //         type: "success",
      //         position: "top-right",
      //       });
      //       vm.store_list[index].centralized_inventory = is_active;
      //     })
      //     .catch((error) => {
      //       vm.$toast.open({
      //         message: vm.somethingWrongMessaeg,
      //         type: "error",
      //         position: "top-right",
      //       });
      //       console.log(error);
      //     });
      // }
    },
    updateLoyalty(index) {
      this.store_list[index].loyalty = !this.store_list[index].loyalty;
    },
    updateStatus(index) {
      this.store_list[index].active = !this.store_list[index].active;
      // var r = confirm(this.updateConfirmMessage);
      // if (r == true) {
      //   var vm = this;
      //   var is_active = !this.store_list[index].active;
      //   var store_id = this.store_list[index].id;
      //   var formData = {
      //     authenticity_token: this.token,
      //     store: {
      //       active: is_active,
      //     },
      //   };
      //   this.$http
      //     .put("/api/v1/admin/stores/" + store_id, formData)
      //     .then((response) => {
      //       vm.$toast.open({
      //         message: vm.storeUpdatedMessage,
      //         type: "success",
      //         position: "top-right",
      //       });
      //       vm.store_list[index].active = is_active;
      //     })
      //     .catch((error) => {
      //       vm.$toast.open({
      //         message: vm.somethingWrongMessaeg,
      //         type: "error",
      //         position: "top-right",
      //       });
      //       console.log(error);
      //     });
      // }
    },
    getBoss(store,attr) {
      let boss = "-";
      if (store.merchants) {
        store.merchants.forEach(function(merchant) {
          if (merchant.role == "BOSS") {
            boss = merchant;
          }
        });
      }
      return boss?.[attr];
    },
    updateBossStatus(index) {
      const vm = this;
      const currentStore = vm.store_list[index]
      const bossId = this.getBoss(currentStore, 'id');
      const bossActive = this.getBoss(currentStore, 'active');
      currentStore.merchants.find(x=>x.id === bossId).active = !currentStore.merchants.find(x=>x.id === bossId).active
      let formData = {
        authenticity_token: vm.token,
        merchant:{
          active: !bossActive,
        }
      }
      this.$http
        .put(`/api/v1/admin/merchants/${bossId}`, formData)
        .then(res => {
          vm.$toast.open({
            message: vm.storeUpdatedMessage,
            type: "success",
            position: "top-right",
          });
        })
        .catch((error) => {
          vm.$toast.open({
            message: vm.somethingWrongMessaeg,
            type: "error",
            position: "top-right",
          });
          console.log(error);
        })
    },
    updateCommission(index) {
      // const vm = this;
      // console.log(this.store_list[index].commission_rate, index)
      // if (this.store_list[index].commission_rate < 0) {
      //   this.$toast.open({
      //     message: vm.commissionSmallerError,
      //     type: "error",
      //     position: "top-right",
      //   });
      // } else if (this.store_list[index].commission_rate > 100) {
      //   this.$toast.open({
      //     message: vm.commissionBiggerError,
      //     type: "error",
      //     position: "top-right",
      //   });
      // } 
      // else {
      //   var vm = this;
      //   var commission_rate = this.store_list[index].commission_rate / 100;
      //   var store_id = this.store_list[index].id;
      //   var formData = {
      //     authenticity_token: this.token,
      //     store: {
      //       commission_rate: commission_rate,
      //     },
      //   };
      //   this.$http
      //     .put("/api/v1/admin/stores/" + store_id, formData)
      //     .then((response) => {
      //       vm.$toast.open({
      //         message: vm.storeUpdatedMessage,
      //         type: "success",
      //         position: "top-right",
      //       });
      //     })
      //     .catch((error) => {
      //       vm.$toast.open({
      //         message: vm.somethingWrongMessaeg,
      //         type: "error",
      //         position: "top-right",
      //       });
      //       console.log(error);
      //     });
      // }
    },
    updateBranchLimit(index) {
      // const vm = this;
      // const store = vm.store_list[index];

      // if (store.branch_limit <= 0) {
      //   vm.$toast.open({
      //     message: vm.branchLimitSmallerError,
      //     type: "error",
      //     position: "top-right",
      //   });
      // } 
      // else if (store.branch_limit) {
      //   this.$http
      //     .put("/api/v1/admin/stores/" + store_id, formData)
      //     .then((response) => {
      //       vm.$toast.open({
      //         message: vm.storeUpdatedMessage,
      //         type: "success",
      //         position: "top-right",
      //       });
      //     })
      //     .catch((error) => {
      //       vm.$toast.open({
      //         message: vm.somethingWrongMessaeg,
      //         type: "error",
      //         position: "top-right",
      //       });
      //       console.log(formData);
      //     });
      // }
    },
    generatePagination(c, m) {
      var current = c,
        last = m,
        delta = 1,
        left = current - delta,
        right = current + delta + 1,
        range = [],
        rangeWithDots = [],
        l;

      for (let i = 1; i <= last; i++) {
        if (i == 1 || i == last || (i >= left && i < right)) {
          range.push(i);
        }
      }

      for (let i of range) {
        if (l) {
          if (i - l === 2) {
            rangeWithDots.push(l + 1);
          } else if (i - l !== 1) {
            rangeWithDots.push("...");
          }
        }
        rangeWithDots.push(i);
        l = i;
      }

      return rangeWithDots;
    },
    getBossEmail(store) {
      let email = "-";
      if (store.merchants) {
        store.merchants.forEach(function(merchant) {
          if (merchant.role == "BOSS") {
            email = merchant.email;
          }
        });
      }
      return email;
    },
    updateStore(storeIdx){
      const vm = this;
      const current_store = vm.store_list[storeIdx]
      let store_id = current_store.id;

      if (current_store.commission_rate < 0) {
        this.$toast.open({
          message: vm.commissionSmallerError,
          type: "error",
          position: "top-right",
        });
      } else if (current_store.commission_rate > 100) {
        this.$toast.open({
          message: vm.commissionBiggerError,
          type: "error",
          position: "top-right",
        });
      } else if (current_store.branch_limit <= 0) {
        vm.$toast.open({
          message: vm.branchLimitSmallerError,
          type: "error",
          position: "top-right",
        });
      } else {
        let commission_rate = current_store.commission_rate / 100;

        let formData = {
          authenticity_token: vm.token,
          store: {
            centralized_inventory: current_store.centralized_inventory,
            active: current_store.active,
            commission_rate: commission_rate,
            branch_limit: current_store.branch_limit,
            loyalty: current_store.loyalty,
            // merchant_wallets_attributes: {
            //   id: wallet_id,
            //   is_allowed: wallet_is_allowed,
            // },
          },
        };
  
        this.$http
          .put("/api/v1/admin/stores/" + store_id, formData)
          .then((response) => {
            vm.$toast.open({
              message: vm.storeUpdatedMessage,
              type: "success",
              position: "top-right",
            });
          })
          .catch((error) => {
            vm.$toast.open({
              message: vm.somethingWrongMessaeg,
              type: "error",
              position: "top-right",
            });
            console.log(error);
          })
          .finally(()=>{
            current_store.edit_mode = false;
          });
      }
    }
  },
};
</script>

<style lang="scss" scoped >
  td{
   min-width: 115px; 
  }
  .no-word-break{
    word-break: keep-all;
  }
  .btn-hover{
    background: unset !important;
    text-decoration: none !important;
    padding: .5rem;
    border-radius: 5px;
    &:hover{
      background: rgba(254,198,24,0.2) !important;
    }
  }
</style>