<template>
  <div style="min-width: 100%">
    <div class="flex-row">
      <div class="menu third-child">
        <a
          v-show="currentMerchant.role == 'BOSS' || currentMerchant.permissions.order.report"
          :href="'/' + locale + '/reports'"
          class="menu-link"
          >{{ reportLabel }}</a
        >
        <a :href="'/' + locale + '/orders'" class="menu-link active">{{
          orderLabel
        }}</a>
        <a
          v-show="currentMerchant.role == 'BOSS' || currentMerchant.permissions.direct_payment.report"
          :href="'/' + locale + '/direct_pay_report'"
          class="menu-link"
          >{{ directpayReportLabel }}</a
        >
      </div>
    </div>
    <div class="flex-row">
      <div class="export-wrapper">
        <a
          v-if="from_date != null || to_date != null"
          @click="clearDate"
          class="btn btn-primary clear-btn"
        >
          {{ clearLabel }}
        </a>
        <div class="datepicker-wrapper">
          <label>{{ fromLabel }}</label>
          <v-date-picker
            v-model="from_date"
            :locale="locale == 'cn' ? 'zh' : 'en'"
            :masks="{
              input: locale == 'cn' ? 'DD日 MM月 YYYY年' : 'DD-MM-YYYY',
            }"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                class="v-datepicker"
                :placeholder="datepickerPlaceholder"
                :value="inputValue"
                v-on="inputEvents"
              />
            </template>
          </v-date-picker>
        </div>
        <div class="datepicker-wrapper">
          <label>{{ toLabel }}</label>
          <v-date-picker
            v-model="to_date"
            :locale="locale == 'cn' ? 'zh' : 'en'"
            :masks="{
              input: locale == 'cn' ? 'DD日 MM月 YYYY年' : 'DD-MM-YYYY',
            }"
            :min-date="from_date"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                class="v-datepicker"
                :placeholder="datepickerPlaceholder"
                :value="inputValue"
                v-on="inputEvents"
              />
            </template>
          </v-date-picker>
        </div>
        <a v-show="currentMerchant.role == 'BOSS' || currentMerchant.permissions.order.export" @click="exportCsv"
          ><strong>{{ exportLabel }}</strong></a
        >
      </div>
      <div class="dropdown-wrapper mr-3">
        <div
          v-if="currentMerchant.role == 'BOSS'"
          class="currency-picker-wrapper card currency-card"
        >
          <div class="heading">{{ locale == "cn" ? "分行" : "Branch" }}</div>
          <select v-model="branch_id">
            <option value="">{{ allLabel }}</option>
            <option
              v-for="(branch, index) in currentStoreBranch"
              :value="branch.id"
              :key="'br' + index"
            >
              {{ branch.name }}
            </option>
          </select>
        </div>
        <div class="currency-picker-wrapper card currency-card">
          <div class="heading">{{ currencyLabel }}</div>
          <select v-model="currency">
            <option value="">{{ allLabel }}</option>
            <option
              v-for="(a_currency, index) in allow_currency"
              :value="a_currency"
              :key="index"
            >
              {{ a_currency }}
            </option>
            <option
              v-for="(p_currency, index) in platformCurrency"
              :value="p_currency"
              :key="index"
            >
              {{ p_currency }}
            </option>
          </select>
        </div>
        <div class="currency-picker-wrapper card">
          <div class="heading">{{ sortByLabel }}</div>
          <select v-model="sort_by">
            <option value="">{{ newestLabel }}</option>
            <option value="oldest">{{ oldestLabel }}</option>
          </select>
        </div>
      </div>
    </div>

    <div class="card admin-table">
      <form @submit.prevent="search" class="table-header">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="25pt"
          version="1.1"
          viewBox="-1 0 136 136.21852"
          width="25pt"
        >
          <g id="surface1">
            <path
              d="M 93.148438 80.832031 C 109.5 57.742188 104.03125 25.769531 80.941406 9.421875 C 57.851562 -6.925781 25.878906 -1.460938 9.53125 21.632812 C -6.816406 44.722656 -1.351562 76.691406 21.742188 93.039062 C 38.222656 104.707031 60.011719 105.605469 77.394531 95.339844 L 115.164062 132.882812 C 119.242188 137.175781 126.027344 137.347656 130.320312 133.269531 C 134.613281 129.195312 134.785156 122.410156 130.710938 118.117188 C 130.582031 117.980469 130.457031 117.855469 130.320312 117.726562 Z M 51.308594 84.332031 C 33.0625 84.335938 18.269531 69.554688 18.257812 51.308594 C 18.253906 33.0625 33.035156 18.269531 51.285156 18.261719 C 69.507812 18.253906 84.292969 33.011719 84.328125 51.234375 C 84.359375 69.484375 69.585938 84.300781 51.332031 84.332031 C 51.324219 84.332031 51.320312 84.332031 51.308594 84.332031 Z M 51.308594 84.332031 "
              style="
                stroke: none;
                fill-rule: nonzero;
                fill: rgb(0%, 0%, 0%);
                fill-opacity: 1;
              "
            />
          </g>
        </svg>
        <input
          type="text"
          :placeholder="searchInputLabel"
          name="query"
          v-model="query"
        />
        <input type="submit" :value="searchLabel" class="btn btn-primary" />
      </form>
      <table>
        <thead>
          <tr>
            <th>{{ idLabel }}</th>
            <th>{{ dateLabel }}</th>
            <!-- <th>{{itemsCountLabel}}</th> -->
            <th>{{ currencyLabel }}</th>
            <th>{{ discountLabel }}</th>
            <th>{{ amountLabel }}</th>
            <th>{{ statusLabel }}</th>
            <th>{{ locale == "cn" ? "付款交易#" : "payme transaction #" }}</th>
            <th v-if="currentMerchant.role != 'CASHIER'">
              {{ createdbyLabel }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="order_list.length == 0">
            <td colspan="6">{{ searchOrderFail }}</td>
          </tr>
          <tr v-for="(order, index) in order_list" :key="index">
            <td>
              <div v-if="currentMerchant.role == 'BOSS' || currentMerchant.permissions.order.read">
                <a :href="'/' + locale + '/orders/' + order.id">
                  {{ order.order_number }}
                </a>
                <a
                  v-if="order.status != 'COMPLETED' && order.status != 'FAILED'"
                  :href="'/' + locale + '/orders/' + order.id"
                  class="btn btn-primary pay-btn"
                  >{{ payNowLabel }}</a
                >
              </div>
              <div v-else>{{ order.order_number }}</div>
            </td>
            <td>
              <span v-if="locale == 'cn'">
                {{ new Date(order.created_at).getFullYear() }}年{{
                  new Date(order.created_at).getMonth() + 1
                }}月{{ new Date(order.created_at).getDate() }}日
                {{ new Date(order.created_at).getHours() }}:{{
                  new Date(order.created_at).getMinutes()
                }}
              </span>
              <span v-else>
                {{ new Date(order.created_at).getDate() }}
                {{ months[new Date(order.created_at).getMonth()] }},
                {{ new Date(order.created_at).getFullYear() }}
                {{ new Date(order.created_at).getHours() }}:{{
                  new Date(order.created_at).getMinutes()
                }}
              </span>
            </td>
            <!-- <td>
            {{order.order_items_count}}
          </td> -->
            <td>
              {{ order.total_price_currency }}
            </td>
            <td>
              {{
                order.discount_amount_satoshi > 0
                  ? Number(order.discount_amount_satoshi / 100000000).toFixed(8)
                  : "-"
              }}
            </td>
            <td>
              {{ Number(order.total_price_satoshi / 100000000).toFixed(8) }}
            </td>
            <td>
              <span v-if="locale == 'cn'">
                {{ order.status == "PENDING" ? "待定" : "" }}
                {{ order.status == "COMPLETED" ? "完成" : "" }}
                {{ order.status == "FAILED" ? "失败" : "" }}
              </span>
              <span v-else>{{ order.status }}</span>
            </td>
            <td>
              <a
                v-if="
                  order.payme_transaction &&
                  order.payme_transaction.recipient_txn
                "
                :href="`${scannerUrl}${order.payme_transaction.recipient_txn}`"
                target="_blank"
                >{{ locale == "cn" ? "查看交易" : "View transaction" }}</a
              >
              <span v-else>-</span>
            </td>
            <td v-if="currentMerchant.role != 'CASHIER'">
              {{ order.cashier_first_name }} {{ order.cashier_last_name }}
            </td>
          </tr>
        </tbody>
      </table>
      <nav
        v-if="order_list.length > 0 && pagination"
        aria-label="pager"
        class="pagy-bootstrap-nav"
      >
        <ul class="pagination">
          <li v-if="pagination.prev" class="page-item prev">
            <a
              @click="page = pagination.prev"
              aria-label="previous"
              class="page-link"
            >
              &lt;
            </a>
          </li>
          <li
            v-for="index in pagination_menu"
            :key="index"
            v-show="index != '...'"
            :class="{ active: index == page }"
            class="page-item"
          >
            <a @click="page = index" class="page-link">{{ index }}</a>
          </li>
          <li v-if="pagination.next" class="page-item next">
            <a
              @click="page = pagination.next"
              rel="next"
              aria-label="next"
              class="page-link"
            >
              &gt;
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import { en, zh } from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";
export default {
  props: [
    "currentMerchant",
    "currentStoreBranch",
    "reportLabel",
    "orderLabel",
    "directpayReportLabel",
    "exportLabel",
    "fromLabel",
    "toLabel",
    "clearLabel",
    "currencyLabel",
    "allLabel",
    "sortByLabel",
    "newestLabel",
    "oldestLabel",
    "searchInputLabel",
    "searchLabel",
    "idLabel",
    "dateLabel",
    "itemsCountLabel",
    "amountLabel",
    "createdbyLabel",
    "statusLabel",
    "payNowLabel",
    "searchOrderFail",
    "exportError",
    "locale",
    "datepickerPlaceholder",
    "platformCurrency",
    "discountLabel",
    "scannerUrl",
  ],
  components: {
    Datepicker,
  },
  data() {
    return {
      currency: "",
      allow_currency: [],
      sort_by: "",
      from_date: null,
      to_date: null,
      query: null,
      order_list: [],
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      pagination: {},
      pagination_menu: [],
      page: 1,
      en: en,
      zh: zh,
      branch_id: "",
    };
  },
  watch: {
    currency: function (val) {
      this.getOrder();
    },
    sort_by: function (val) {
      this.getOrder();
    },
    page: function (p) {
      this.getOrder();
    },
    from_date: function () {
      this.getOrder();
    },
    to_date: function () {
      this.getOrder();
    },
    branch_id: function () {
      this.getOrder();
    },
  },
  mounted() {
    this.getCurrency();
    this.getOrder();

    console.log(this.currentMerchant);
  },
  methods: {
    getCurrency() {
      this.$http
        .get("/api/v1/wallets/allowed_currencies")
        .then((response) => {
          this.allow_currency = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setDefaultBranch(branch_id) {
      if (branch_id) return "&branch_id=" + branch_id;
      if (!branch_id && this.currentMerchant.role !== "BOSS")
        return "&branch_id=" + this.currentMerchant.branch_id;
      return "";
    },
    getOrder() {
      let vm = this;
      let api = "/api/v1/orders?";
      api += this.currency ? "currency=" + this.currency : "";
      api += this.query ? "&query=" + this.query : "";
      api += this.from_date
        ? "&from_date=" + this.getDateString(this.from_date)
        : "";
      api += this.to_date ? "&to_date=" + this.getDateString(this.to_date) : "";
      api += this.sort_by ? "&sort_by=" + this.sort_by : "";
      api += this.page ? "&page=" + this.page : "";
      api +=
        this.currentMerchant.role == "CASHIER"
          ? "&merchant_id=" + this.currentMerchant.id
          : "";
      api += this.setDefaultBranch(this.branch_id);
      this.$http
        .get(api)
        .then((response) => {
          vm.order_list = response.data.data;
          vm.pagination = response.data.pagy;
          vm.pagination_menu = vm.generatePagination(
            vm.pagination.page,
            vm.pagination.pages
          );
        })
        .catch((error) => {
          console.log(error);
        });
    },
    search() {
      this.getOrder();
    },
    filter() {
      this.getOrder();
    },
    exportCsv() {
      if (this.from_date && this.to_date) {
        let vm = this;
        let api = "/api/v1/orders/export?";
        api += this.currency ? "currency=" + this.currency : "";
        api += this.query ? "&query=" + this.query : "";
        api += this.from_date
          ? "&from_date=" + this.getDateString(this.from_date)
          : "";
        api += this.to_date
          ? "&to_date=" + this.getDateString(this.to_date)
          : "";
        api += this.sort_by ? "&sort_by=" + this.sort_by : "";
        api +=
          this.currentMerchant.role == "CASHIER"
            ? "&merchant_id=" + this.currentMerchant.id
            : "";
        api += this.setDefaultBranch(this.branch_id);
        window.open(api);
      } else {
        alert(this.exportError);
      }
    },
    clearDate() {
      this.from_date = null;
      this.to_date = null;
      this.filter();
    },
    getDateString(date) {
      var dd = String(date.getDate()).padStart(2, "0");
      var mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = date.getFullYear();
      return yyyy + "-" + mm + "-" + dd;
    },
    generatePagination(c, m) {
      var current = c,
        last = m,
        delta = 1,
        left = current - delta,
        right = current + delta + 1,
        range = [],
        rangeWithDots = [],
        l;

      for (let i = 1; i <= last; i++) {
        if (i == 1 || i == last || (i >= left && i < right)) {
          range.push(i);
        }
      }

      for (let i of range) {
        if (l) {
          if (i - l === 2) {
            rangeWithDots.push(l + 1);
          } else if (i - l !== 1) {
            rangeWithDots.push("...");
          }
        }
        rangeWithDots.push(i);
        l = i;
      }

      return rangeWithDots;
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown-wrapper {
  display: flex;
  select {
    width: 100%;
  }
}
.dropdown-wrapper .currency-card {
  margin-right: 1rem;
}
.export-wrapper {
  padding: 2rem 1.5rem 1rem 1.5rem;
}
.pay-btn {
  margin-top: 10px;
  display: inline-block;
}
select {
  outline: none;
}
.currency-picker-wrapper {
  width: 100%;
  min-width: 170px;
}
@media screen and (max-width: 1149px) {
  .export-wrapper {
    width: 100%;
    margin-bottom: 1rem;
    padding: 1rem !important;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .dropdown-wrapper {
    width: 100%;

    .currency-picker-wrapper {
      padding: 1rem;
      width: calc(50% - 0.5rem);
      min-width: unset;
    }
  }
  .flex-row {
    flex-wrap: wrap;
  }
}
</style>